import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {getPartsAction} from "../../redux/parts/partsReducer";

const Parts = () => {

    const dispatch = useDispatch()
    const {parts} = useSelector(state => state.parts)
    const filtered_parts = parts?.results?.filter(el => el.status === true)

  console.log('hhh_parts', parts)

    useEffect(() => {
      dispatch(getPartsAction())
    }, [])

    return (
        <section className='bg-dark p-4'>
            <div className='container m-auto text-center pt-28 pb-24'>
                <h5 className='text-[#ECECEC] lg:text-[26px] text-[22px] mb-10'>Parts</h5>
                <ul className='flex flex-wrap justify-start'>
                    {
                      filtered_parts?.map(part => (
                            <li key={part.id} className='lg:p-4 p-3 w-1/2 lg:w-[25%] block lg:block my-class'>
                                <Link to={`/parts/${part.id}`} className='flex items-center h-[187px] lg:h-[217px] justify-between flex-col gap-2 bg-[#333436] rounded-md px-1.5 py-5 lg:px-11 lg:py-5 cursor-pointer'>
                                    <div className='flex justify-center flex-auto h-2/3 items-center p-5'>
                                        <img className='object-cover w-full max-h-full' src={part.main_image} alt="/"/>
                                    </div>
                                    <p className='text-sm lg:text-lg text-white font-medium'>{part.title}</p>
                                </Link>
                            </li>
                        ))
                    }
                </ul>
                <Link to='/parts'><p className='inline-block border-b text-white font-medium text-lg py-1.5 px-0.5 mt-5'>See all parts</p></Link>
            </div>
        </section>
    );
};

export default Parts;