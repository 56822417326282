import {API, fileAPI,} from "./axiosConfig";

export const request = {
  getParts: () => API.get('parts/'),
  getSearchedParts: (value) => API.get("parts/", { params: {search: value} }),
  getPaginatedParts: (number) => API.get('parts/', { params: {page: number}}),
  getPartById: (id) => API.get(`parts/${id}/`),
  getFilteredParts: (params) => API.get("parts/", { params }),
  getServices: () => API.get('services/'),
  getServiceById: (id) => API.get(`services/${id}/`),
  getServiceByType: (type) => API.get('services/', { params: {type} }),

  // options
  getPartCategories: () => API.get('parts/category/'),
  getPartBrands: () => API.get('parts/brand/'),

  // requests
  getConsultationApi: (data) => API.post('pride/consultation/', data),
  partRequestApi: (data) => API.post('parts/order/', data),
  serviceRequestApi: (data) => fileAPI.post('services/order/', data),
}