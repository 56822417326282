import React, {useEffect} from 'react';
import PartsList from "./PartsList";
import Header from "../../components/Header";
import Map from "../../components/main_page/Map";
import Footer from "../../components/Footer";
import Breadcrumbs from "../../components/Breadcrumbs";
import {useSelector} from "react-redux";
import Filter from "../../components/Filter";
import CardsSkeleton from "../../components/CardsSkeleton";

const Parts = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const {parts, loading} = useSelector(state => state.parts)

  return (
    <>
      <Header/>
      <div className='bg-dark'>
        <div className='container md:m-auto p-2'>
          <Breadcrumbs/>
          <p className='text-lg text-[#FFF]'>All parts</p>
          <Filter count={parts.count}/>
          {/*{*/}
          {/*  loading ? <CardsSkeleton/> : <PartsList parts={parts}/>*/}
          {/*}*/}
          <PartsList parts={parts}/>
        </div>
      </div>
      <Map/>
      <Footer/>
    </>

  );
};

export default Parts;