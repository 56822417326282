import React, {useState} from 'react';
import Navbar from "../../components/Sidebar";
import Button from "../../components/Button";
import {EmployeesList} from "./EmployeesList";
import InviteEmployeeModal from "../../widgets/InviteEmployeeModal";

const Employees = () => {

  const [openModal, setOpenModal] = useState(false)
  const toggleOpenModal = () => setOpenModal(!openModal)

  return (
    <>
      <div className='md:flex h-screen'>
        <Navbar/>
        <div className='w-full h-screen bg-background'>
          <div className='container m-auto pt-7 px-6'>
            <div className='lg:flex flex-col lg:flex-row justify-between w-full mb-5'>
              <p className='text-xl font-medium mb-2 lg:mb-0'>
                Employees
              </p>
              <Button onClick={toggleOpenModal} padding='4' type='button' text='Invite employee'/>
            </div>
            <EmployeesList/>
          </div>
        </div>
      </div>
      {
        openModal && <InviteEmployeeModal open={openModal} handleOpen={toggleOpenModal}/>
      }
    </>
  );
};

export default Employees;