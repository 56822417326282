import * as Yup from 'yup';

export const getConsultationSchema = Yup.object().shape({
  name: Yup.string()
    .required('This field is required!'),
  phone_number: Yup.number()
    .typeError("That doesn't look like a phone number")
    .positive("A phone number can't start with a minus")
    .integer("A phone number can't include a decimal point")
    .required('This field is required!'),
  company_name: Yup.string()
    .required('This field is required!'),
  service: Yup.string()
    .required('This field is required!'),
  date: Yup.string().required('This field is required!'),
  time: Yup.string().required('This field is required!'),
});

export const partRequestSchema = Yup.object().shape({
  name: Yup.string()
    .required('This field is required!'),
  phone_number: Yup.number()
    .typeError("That doesn't look like a phone number")
    .positive("A phone number can't start with a minus")
    .integer("A phone number can't include a decimal point")
    .required('This field is required!'),
  message: Yup.string().required('This field is required!'),
});

export const serviceRequestSchema = Yup.object().shape({
  name: Yup.string()
    .required('This field is required!'),
  phone_number: Yup.number()
    .typeError("That doesn't look like a phone number")
    .positive("A phone number can't start with a minus")
    .integer("A phone number can't include a decimal point")
    .required('This field is required!'),
  email: Yup.string().email('Invalid email').required('This field is required!'),
  message: Yup.string().required('This field is required!'),
});