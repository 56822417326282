import React, {useState} from 'react';
import Header from "../components/Header";
import OurPartners from "../components/main_page/OurPartners";
import Banner from "../components/main_page/Banner";
import Services from "../components/main_page/Services";
import MobileAndTowing from "../components/main_page/MobileAndTowing";
import Parts from "../components/main_page/Parts";
import OurAdvantages from "../components/main_page/OurAdvantages";
import ModalForGetAConsultation from "../widgets/ModalForGetAConsultation";
import OurTeam from "../components/main_page/OurTeam";
import Map from "../components/main_page/Map";
import Footer from "../components/Footer";

const MainPage = () => {

  const [open, setOpen] = useState(false);

  const handleOpenModal = () => setOpen(!open);

  return (
    <>
      <div className='relative'>
        <Header openModal={handleOpenModal}/>
        <Banner/>
        <Services/>
        <MobileAndTowing/>
        <Parts/>
        <div className='bg-[#F5F5F5]'>
          <OurAdvantages/>
          <OurPartners/>
          <OurTeam/>
          <Map/>
          <Footer/>
        </div>

      </div>
      {
        open && <ModalForGetAConsultation open={open} handleOpen={handleOpenModal}/>
      }
    </>
  );
};

export default MainPage;
