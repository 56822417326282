import React from 'react';
import ReactCompareImage from "react-compare-image";
import slider_img1 from '../../assets/slider_imgs/img1.jpg'
import slider_img2 from '../../assets/slider_imgs/img2.jpg'
import slider_img3 from '../../assets/slider_imgs/img3.jpg'
import slider_img4 from '../../assets/slider_imgs/img4.jpg'
import slider_img5 from '../../assets/slider_imgs/img5.jpg'
import slider_img6 from '../../assets/slider_imgs/img6.jpg'
import slider_img7 from '../../assets/slider_imgs/img7.jpg'
import slider_img8 from '../../assets/slider_imgs/img8.jpg'
import Slider from "react-slick";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/20/solid";

const CompareImageSlider = () => {

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <ChevronRightIcon style={{...style, color: 'black', zIndex: 10 }} onClick={onClick} className={className} />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <ChevronLeftIcon style={{...style, color: 'black', zIndex: 10}} onClick={onClick} className={className} />
    );
  }


  const settings = {
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    swipeToSlide: false,
    touchMove: false,
  };

  return (
      <div className="w-full m-auto rounded-md p-6">
        <Slider {...settings}>
          <div className='w-full'>
            <ReactCompareImage hover={true} leftImage={slider_img1} rightImage={slider_img2} />
          </div>

          <div className='h-full'>
            <ReactCompareImage hover={true} leftImage={slider_img3} rightImage={slider_img4} />
          </div>

          <div className='h-full'>
            <ReactCompareImage hover={true} leftImage={slider_img5} rightImage={slider_img6} />
          </div>

          <div className='h-full'>
            <ReactCompareImage hover={true} leftImage={slider_img7} rightImage={slider_img8} />
          </div>
        </Slider>
      </div>
  )
};

export default CompareImageSlider;