import React, {useEffect, useState} from 'react';
import axios from "axios";
import {MapPinIcon, PaperAirplaneIcon} from "@heroicons/react/20/solid";

const SearchLocationInput = ({setCoordinates, setCoor, address, setAddress, handleCloseMap}) => {

  async function getAddress(latitude, longitude) {
    console.log('lolo: ', latitude, longitude)
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
      );
      console.log(response.data.display_name)
      return response.data.display_name
    } catch (error) {
      console.error('Error fetching geocoding data:', error);
      return 'Error fetching address';
    }
  }

  const getCurrentLocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCoor({ lat: latitude, lng: longitude });
          getAddress(latitude, longitude).then((address) => {
            console.log('address: ', address)
            setAddress(address)
          })
        },
        (error) => {
          console.error('Error getting current location:', error.message);
        }
      );
    } else {
      console.error('Geolocation is not available in this browser.');
    }
  }

  useEffect(() => {
    const handleFormSubmit = async (event) => {

      try {
        const response = await axios.get(
          `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
            address
          )}`
        );

        if (response.data.length > 0) {
          const { lat, lon } = response.data[0];
          setCoordinates({ latitude: parseFloat(lat), longitude: parseFloat(lon) });
        } else {
          console.log('No results found.');
        }
      } catch (error) {
        console.error('Error fetching geocoding data:', error);
      }
    };
    handleFormSubmit()
  }, [address])

  return (
    <div className='absolute bottom-0 left-0 z-50 flex items-center gap-2 m-1 rounded-t-lg w-full'>
      <div className='lg:w-1/3 w-full z-50 px-3 bg-white rounded-t-lg relative'>
        <p className='text-xs mt-0.5 text-[#8F8F8F]'>Your geolocation</p>
        <input value={address} className='mb-2 w-full text-sm text-dark' onChange={(e) => setAddress(e.target.value)} type="text"/>
        <PaperAirplaneIcon onClick={handleCloseMap} className="h-11 w-11 absolute right-1 top-[5%] text-[#A80301] p-2 bg-white rounded-full cursor-pointer" />
      </div>
      <MapPinIcon onClick={getCurrentLocation} className="h-11 w-11 text-[#A80301] bg-white p-2 rounded-full cursor-pointer" />
    </div>
  );
};

export default SearchLocationInput