import * as Yup from 'yup';

export const authSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('This field is required!'),
  password: Yup.string()
    .required('This field is required!'),
});

export const resetPasswordSchema = Yup.object().shape({
  new_password: Yup.string()
    .required('This field is required!')
    .min(8, 'It must contain at least 8 characters.'),
  repeat_password: Yup.string()
    .required('This field is required!')
    .oneOf([Yup.ref('new_password'), null], 'Passwords must match'),
});

export const registerSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('This field is required!'),
  password: Yup.string()
    .required('This field is required!'),
  repeat_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('This field is required!'),
});

export const serviceSchema = Yup.object().shape({
  name: Yup.string()
    .required('This field is required!'),
  description: Yup.string()
    .required('This field is required!'),
});

export const employeeSchema = Yup.object().shape({
  full_name: Yup.string()
    .required('This field is required!'),
  email: Yup.string()
    .email('Invalid email')
    .required('This field is required!'),
  role: Yup.string()
    .required('This field is required!'),
});

export const partInfoSchema = Yup.object().shape({
  description: Yup.string()
    .required('This field is required!'),
  category: Yup.string()
    .required('This field is required!'),
  subcategory: Yup.string()
    .required('This field is required!'),
  brand: Yup.string()
    .required('This field is required!'),
  title: Yup.string()
    .required('This field is required!'),
  height: Yup.string()
    .required('This field is required!'),
  width: Yup.string()
    .required('This field is required!'),
  length: Yup.string()
    .required('This field is required!'),
  weight: Yup.string()
    .required('This field is required!'),
  price: Yup.string()
    .required('This field is required!'),
  discount: Yup.string()
    .required('This field is required!'),
  quantity: Yup.string()
    .required('This field is required!'),
  location: Yup.string()
    .required('This field is required!'),
  link: Yup.string()
    .required('This field is required!'),
});

export const partUpdateInfoSchema = Yup.object().shape({
  description: Yup.string()
    .required('This field is required!'),
  title: Yup.string()
    .required('This field is required!'),
  height: Yup.string()
    .required('This field is required!'),
  width: Yup.string()
    .required('This field is required!'),
  length: Yup.string()
    .required('This field is required!'),
  weight: Yup.string()
    .required('This field is required!'),
  price: Yup.string()
    .required('This field is required!'),
  discount: Yup.string()
    .required('This field is required!'),
  quantity: Yup.string()
    .required('This field is required!'),
  location: Yup.string()
    .required('This field is required!'),
  link: Yup.string()
    .required('This field is required!'),
});