import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Card, Typography} from "@material-tailwind/react";
import {PencilIcon} from "@heroicons/react/20/solid";
import {
  deletePartAction,
  getAdminPaginatedPartsAction,
} from "../../redux/parts/PartsActions";
import {Link} from "react-router-dom";
import delete_icon from "../../assets/delete.svg";
import useWindowDimensions from "../../utils/GetWindowDimantions";
import {Pagination} from "../../../landing_page/components/Pagination";

const PartsList = ({onChooseExportData}) => {

  const [openDeleteModal, setOpenDeleteModel] = useState({id: null, opened: false})

  const handleOpenDeleteModal = (id) => {
    setOpenDeleteModel({id, opened: !openDeleteModal.opened})
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModel({id: openDeleteModal.id, opened: !openDeleteModal.opened})
  }

  const handleDeleteService = (id) => {
    dispatch(deletePartAction({id, handleCloseDeleteModal}))
  }

  const {windowWidth} = useWindowDimensions()

  const TABLE_HEAD = ["", "Photo", "Name", "Category", "Brand", "Status", "Price ($)", "%", "Q-ty", "Condition", "Actions" ];

    const dispatch = useDispatch()
    const {parts, loading} = useSelector(state => state.admin_parts)

    return (
      <div className='h-[50vh] md:h-[64vh]'>
        <Card className="w-full h-[95%] overflow-scroll">
          <table className="w-full min-w-max table-auto text-left">
            {
              windowWidth > 1000 &&
              <thead>
              <tr>
                {TABLE_HEAD.map((head) => (
                  <th key={head} className="border-b border-blue-gray-100 p-4">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-medium leading-none text-dark"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
              </thead>
            }

            <tbody>
            {parts?.results?.map((part, index) => {
              const {main_image, title, category, brand, status, price, discount, condition, quantity, id } = part
              const part_status = status.toString()
              const isLast = index === parts.length - 1;
              const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

              return (
                <tr className={windowWidth < 1000 ? 'flex flex-col' : 'w-full'} key={title+index}>
                  <td className={classes}>
                    <input onClick={() => onChooseExportData(part)} className={windowWidth < 1000 ? 'flex m- p-8 accent-red' : 'flex m-auto p-8 accent-red'} type="checkbox"/>
                  </td>
                  <td className={classes} style={windowWidth < 1000 ? {display: 'flex', justifyContent: 'space-between', height: '78px', width: "100%", border: 'none'} : {height: '78px', width: "80px"}}>
                    {
                      windowWidth < 1000 &&
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-medium leading-none text-dark"
                      >
                        Photo
                      </Typography>
                    }
                    <img className='object-contain h-full' src={main_image} alt="/"/>
                  </td>
                  <td className={classes} style={windowWidth < 1000 ? {display: 'flex', justifyContent: 'space-between', border: 'none'} : null}>
                    {
                      windowWidth < 1000 &&
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-medium leading-none text-dark"
                      >
                        Name
                      </Typography>
                    }
                    <Typography variant="small" color="blue-gray" className="font-normal">
                      {title}
                    </Typography>
                  </td>
                  <td className={classes} style={windowWidth < 1000 ? {display: 'flex', justifyContent: 'space-between', border: 'none'} : null}>
                    {
                      windowWidth < 1000 &&
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-medium leading-none text-dark"
                      >
                        Category
                      </Typography>
                    }
                    <Typography variant="small" color="blue-gray" className="font-normal">
                      {category.title}
                    </Typography>
                  </td>
                  <td className={classes} style={windowWidth < 1000 ? {display: 'flex', justifyContent: 'space-between', border: 'none'} : null}>
                    {
                      windowWidth < 1000 &&
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-medium leading-none text-dark"
                      >
                        Brand
                      </Typography>
                    }
                    <Typography variant="small" color="blue-gray" className="font-normal">
                      {brand.title}
                    </Typography>
                  </td>
                  <td className={classes} style={windowWidth < 1000 ? {display: 'flex', justifyContent: 'space-between', border: 'none'} : null}>
                    {
                      windowWidth < 1000 &&
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-medium leading-none text-dark"
                      >
                        Status
                      </Typography>
                    }
                    <Typography variant="small" color="blue-gray" className="font-normal">
                      {part_status}
                    </Typography>
                  </td>
                  <td className={classes} style={windowWidth < 1000 ? {display: 'flex', justifyContent: 'space-between', border: 'none'} : null}>
                    {
                      windowWidth < 1000 &&
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-medium leading-none text-dark"
                      >
                        Price
                      </Typography>
                    }
                    <Typography variant="small" color="blue-gray" className="font-normal">
                      {price}
                    </Typography>
                  </td>
                  <td className={classes} style={windowWidth < 1000 ? {display: 'flex', justifyContent: 'space-between', border: 'none'} : null}>
                    {
                      windowWidth < 1000 &&
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-medium leading-none text-dark"
                      >
                        Discount
                      </Typography>
                    }
                    <Typography variant="small" color="blue-gray" className="font-normal">
                      {discount}
                    </Typography>
                  </td>
                  <td className={classes} style={windowWidth < 1000 ? {display: 'flex', justifyContent: 'space-between', border: 'none'} : null}>
                    {
                      windowWidth < 1000 &&
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-medium leading-none text-dark"
                      >
                        Quantity
                      </Typography>
                    }
                    <Typography variant="small" color="blue-gray" className="font-normal">
                      {quantity}
                    </Typography>
                  </td>
                  <td className={classes} style={windowWidth < 1000 ? {display: 'flex', justifyContent: 'space-between', border: 'none'} : null}>
                    {
                      windowWidth < 1000 &&
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-medium leading-none text-dark"
                      >
                        Condition
                      </Typography>
                    }
                    <Typography variant="small" color="blue-gray" className="font-normal">
                      {condition}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <Typography as="a" href="#" variant="small" className="font-medium flex gap-3">
                      <Link to={`/admin/parts/${id}`}><PencilIcon className="h-6 w-6 text-[#C5C5C5]" /></Link>
                      <div className='relative'>
                        <img onClick={() => handleOpenDeleteModal(id)} className="h-6 w-6 text-gray-400 cursor-pointer" src={delete_icon} alt="/"/>
                        {
                          openDeleteModal.id === id && openDeleteModal.opened && <div className='p-2 w-96 rounded-lg bg-white z-10 border border-[#C5C5C5] absolute top-0 right-6'>
                            <p className='w-fit text-base'>
                              Are you sure you want to delete this message?
                            </p>
                            <div className='flex text-white gap-1.5 mt-2 float-right'>
                              <button onClick={() => handleDeleteService(id)} className='bg-[#C5C5C5] px-5 py-1.5 rounded-md'>Yes</button>
                              <button onClick={handleCloseDeleteModal} className='bg-[#C5C5C5] px-5 py-1.5 rounded-md'>No</button>
                            </div>
                          </div>
                        }
                      </div>
                    </Typography>
                  </td>
                </tr>
              );
            })}
            </tbody>
          </table>
        </Card>
        <Pagination count={parts.count} dispatchFunction={getAdminPaginatedPartsAction}/>
      </div>
    );
  }

export default PartsList;