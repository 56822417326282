import {createSlice} from "@reduxjs/toolkit";
import {createServiceAction, getServiceByIdAction, getServicesAction} from "./ServicesActions";

const initialState = {
  loading: false,
  error: '',
  services: [],
  serviceById: {},
}

const serviceSlice = createSlice({
  name: 'admin_services',
  initialState,
  reducers: {

  },
  extraReducers: {
    [getServicesAction.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getServicesAction.fulfilled]: (state, action) => {
      state.loading = false
      state.error = false
      state.services = action.payload
    },
    [getServicesAction.rejected]: (state) => {
      state.loading = false
      state.error = true
    },

    [getServiceByIdAction.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getServiceByIdAction.fulfilled]: (state, action) => {
      state.loading = false
      state.error = false
      state.serviceById = action.payload
    },
    [getServiceByIdAction.rejected]: (state) => {
      state.loading = false
      state.error = true
    },

    [createServiceAction.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [createServiceAction.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const adminServicesSlice = serviceSlice.reducer;


