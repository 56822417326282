import React, {useState} from 'react';
import Sidebar from "../../components/Sidebar";
import CreatePartInfoForm from "./CreatePartInfoForm";
import CreateCategoriesBrandsInfoForm from "./CreateCategoriesBrandsInfoForm";
import Breadcrumbs from "../../components/Breadcrumbs";

const CreatePart = () => {

  const [tab, setTab] = useState(false)

  const openInfoTab = () => setTab(false)
  const openCategoriesBrandsTab = () => setTab(true)

  return (
    <div className='h-screen md:flex'>
      <Sidebar/>
      <div className='w-full h-[91vh] md:h-full bg-background'>
        <div className='container m-auto pt-7 px-6 flex flex-col gap-3'>
          <Breadcrumbs/>
          <p className='text-xl font-medium'>
            Create part
          </p>
          <div className='flex gap-5 text-base'>
            <button onClick={openInfoTab} className={`${tab ? 'text-[#8A8A8A]' : 'text-red'} underline`}>Info</button>
            <button onClick={openCategoriesBrandsTab} className={`${tab ? 'text-red' : 'text-[#8A8A8A]'} underline`}>Categories & Brands</button>
          </div>
          {
            tab ? <CreateCategoriesBrandsInfoForm /> : <CreatePartInfoForm/>
          }
          {/*<Button padding='4' text='Create part'/>*/}
        </div>
      </div>
    </div>
  );
};

export default CreatePart;