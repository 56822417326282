import React, {useEffect, useState} from 'react';
import Header from "../../components/Header";
import {MapPinIcon, ShareIcon} from "@heroicons/react/20/solid";
import print_icon from '../../assets/icons/print.svg'
import Map from "../../components/main_page/Map";
import Footer from "../../components/Footer";
import MyAccordion from "./Accordion";
import PartRequestModal from "../../widgets/PartRequestModal";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getPartByIdAction} from "../../redux/parts/partsReducer";
import PageLinkCopyModal from "../../widgets/PageLinkCopyModal";
import Breadcrumbs from "../../components/Breadcrumbs";
import PartImagesModal from "../../widgets/PartImagesModal";


const PartDetails = () => {
  const dispatch = useDispatch()
  const {part} = useSelector(state => state.parts)
  const {id} = useParams()

  useEffect(() => {
    dispatch(getPartByIdAction(id))
  }, [])

  const [open, setOpen] = useState(false)
  const [openCopyModal, setOpenCopyModal] = useState(false)
  const [openImages, setOpenImages] = useState(false)

  const handleModal = () => setOpen(!open)
  const handleCopyModal = () => setOpenCopyModal(!openCopyModal)
  const handleOpenImagesModal = () => setOpenImages(!openImages)
  const images = part?.part_image?.slice(0, 2)
  console.log(images)
  return (
    <div>
      <Header/>
      <div className='bg-dark'>
        <div className='container md:m-auto p-2'>

          <Breadcrumbs name={part.title || ''}/>

          <div className='flex gap-16 lg:flex-nowrap flex-wrap justify-between'>
            <div className='pb-7 lg:pb-12 md:w-full lg:w-2/3 w-full text-center'>
              <ul className=''>
                {
                  images?.map(img => (
                    <li key={part.id} className="lg:p-4 p-3 w-full my-class">
                      <div className='flex justify-center gap-4 text-start bg-white rounded-md group'>
                        <img className='h-80 w-full object-contain' src={img.image} alt="/"/>
                      </div>
                    </li>
                  ))
                }
              </ul>
              {part?.part_image?.length > 1 && <button onClick={handleOpenImagesModal} className='underline text-lg font-medium text-white m-auto mt-6'>View all</button>}
            </div>


            <div className='lg:w-1/3 w-full mt-8 lg:m-0 m-4'>
              <div className='flex justify-between mb-7'>
                <div className='flex gap-2.5'>
                  <MapPinIcon className="h-5 w-5 text-[#B4B4B4] cursor-pointer" />
                  <p className='text-white text-sm'>{part.location}</p>
                </div>
                <div className='flex gap-5'>
                  <img onClick={() => window.print()} className='h-5 w-5 cursor-pointer' src={print_icon} alt="/"/>
                  <ShareIcon onClick={handleCopyModal} className="h-5 w-5 text-[#B4B4B4] cursor-pointer" />
                </div>

              </div>
              <div className='flex flex-col gap-4'>
                <p className='text-white text-lg'>{part.title}</p>
                <p className='text-[#87DD4C] text-lg'>$ 34.567</p>
                <div className='flex flex-col gap-2.5 text-sm text-white'>
                  <div className='flex justify-between'>
                    <p className='text-[#9E9E9E]'>Brand</p>
                    <p>{part.brand?.title}</p>
                  </div>
                  <div  className='flex justify-between'>
                    <p className='text-[#9E9E9E]'>Subcategory</p>
                    <p>{part.subcategory?.title}</p>
                  </div>
                  <div  className='flex justify-between'>
                    <p className='text-[#9E9E9E]'>Condition</p>
                    <p>{part.condition}</p>
                  </div>
                  <div  className='flex justify-between'>
                    <p className='text-[#9E9E9E]'>Year</p>
                    <p>1980</p>
                  </div>
                  <div  className='flex justify-between'>
                    <p className='text-[#9E9E9E]'>Quantity</p>
                    <p>{part.quantity}</p>
                  </div>
                </div>

                <button onClick={handleModal} className='bg-[#A80301] py-2 mb-8 mt-6 px-5 lg:w-fit w-full text-center text-white rounded-lg'>
                  Send a request
                </button>

              </div>

              <MyAccordion part={part}/>

              <div></div>
            </div>
          </div>

        </div>
      </div>
      <Map/>
      <Footer/>

      {
        open && <PartRequestModal part={part} handleOpen={handleModal} open={open}/>
      }

      {
        openCopyModal && <PageLinkCopyModal handleOpen={handleCopyModal} open={openCopyModal}/>
      }

      {
        openImages && <PartImagesModal part={part} handleOpen={handleOpenImagesModal} open={openImages}/>
      }

    </div>
  );
};

export default PartDetails;