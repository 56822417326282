import {request} from "../api";
import {createAsyncThunk} from "@reduxjs/toolkit";

export const getServicesAction = createAsyncThunk(
  'admin_services/getServicesAction',
  async () => {
    const response = await request.getServiceApi()
    return response.data
  }
)

export const getServiceByIdAction = createAsyncThunk(
  'admin_services/getServiceByIdAction',
  async ({id}, rejectWithValue) => {
    try {
      const response = await request.getServiceByIdApi(id)
      return response.data
    } catch (error) {
      console.log('Error: ', error.message)
      return rejectWithValue(error.message)
    }
  }
)

export const createServiceAction = createAsyncThunk(
  "admin_services/createServiceAction",
  async function({fData, handleSuccessModal, handleErrorModal, handleOpen},{ rejectWithValue, dispatch}){
    try {
      await request.createServiceApi(fData)
      await handleSuccessModal()
      dispatch(getServicesAction())
    } catch (error) {
      handleErrorModal()
      console.log('Error: ', error.response.data.name[0])
      return rejectWithValue(error.response.data.name[0])
    }
  })

export const deleteServiceAction = createAsyncThunk(
  "admin_services/deleteServiceAction",
  async function({id, handleCloseDeleteModal},{ rejectWithValue, dispatch}){
    try {
      await request.deleteServiceApi(id)
      handleCloseDeleteModal()
      dispatch(getServicesAction())
    } catch (error) {
      console.log('Error: ', error.message)
      return rejectWithValue(error.message)
    }
  })

export const updateServiceAction = createAsyncThunk(
  "admin_services/updateServiceAction",
  async function({id, data, withFile, handleSuccessModal, handleErrorModal, handleOpen},{ rejectWithValue, dispatch}){
    try {
      withFile ? await request.updateServiceWithFileApi({id, data}) : await request.updateServiceApi({id, data})
      handleSuccessModal()
      handleOpen()
      dispatch(getServicesAction())
    } catch (error) {
      handleErrorModal()
      console.log('Error: ', error.message)
      return rejectWithValue(error.message)
    }
  })