import React from 'react';
import our_team from '../../assets/our_team.jpg'

const OurTeam = () => {
  return (
    <>
      <section className='p-4'>
        <div className='container m-auto text-center lg:pt-26 pt-2'>
          <h5 className='lg:text-[26px] text-[22px] mb-8'>Our Team</h5>
          <p className='text-lg xl:w-1/2 md:w-2/3 sm:w-4/5 m-auto mb-6'>
            With over 60 years of combined experience our team at Pride Truck Center
            is at the forefront of ensuring our guests needs are met accordingly.
            We take great Pride in our pillars of service excellence
          </p>
          <img className='lg:m-auto w-full object-cover' src={our_team} alt="/"/>
        </div>
      </section>
    </>
  );
};

export default OurTeam;