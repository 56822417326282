import React, {useState} from 'react';
import pride_truck_bg_video from "../../assets/pride_truck_bg_video.MOV";
import Button from "../Button";
import ModalForGetAConsultation from "../../widgets/ModalForGetAConsultation";

const Banner = () => {

    const [open, setOpen] = useState(false);
    const handleOpenModal = () => setOpen(!open);

    return (
        <>
            <section className='relative w-full h-screen bg-cover bg-center flex justify-center items-center pt-24'>
                <video className='absolute top-0 right-0 object-cover w-full h-full' src={pride_truck_bg_video} autoPlay loop muted/>
                <div className='z-10 m-auto flex flex-col w-4/5 gap-2.5 lg:w-4/5 2xl:w-2/3 text-center'>
                    <h1 className='font-bold text-4xl leading-[120%] md:text-5xl lg:text-[65px] lg:leading-[120%] text-[#F7F7F7] font-play'>Keep Your Fleet Rolling with Pride Truck Center's Expert Services</h1>
                    <p className='text-lg lg:text-2xl px-5 text-white mb-10 font-open_sans'>From repairs to maintenance, we do it with pride</p>
                    <Button onClick={handleOpenModal} visibility={true} text='Schedule for service'/>
                </div>
            </section>
            {
                open && <ModalForGetAConsultation open={open} handleOpen={handleOpenModal}/>

            }
        </>

    );
};

export default Banner;