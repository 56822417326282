import {request} from "../api";
import {createAsyncThunk} from "@reduxjs/toolkit";

export const getPartsAction = createAsyncThunk(
  'admin_parts/getParts',
  async (userId) => {
    const response = await request.getPartsApi()
    return response.data
  }
)

export const getSearchedPartsAction = createAsyncThunk(
  'admin_parts/getSearchedPartsAction',
  async ({searchValue}) => {
    const response = await request.getSearchedParts(searchValue)
    return response.data
  }
)

export const getPartByIdAction = createAsyncThunk(
  'admin_parts/getPartByIdAction',
  async ({id}) => {
    const response = await request.getPartByIdApi(id)
    return response.data
  }
)

export const deletePartAction = createAsyncThunk(
  "admin_parts/deletePartAction",
  async function({id, handleCloseDeleteModal},{ rejectWithValue, dispatch}){
    try {
      await request.deletePartApi(id)
      handleCloseDeleteModal()
      dispatch(getPartsAction())
    } catch (error) {
      return rejectWithValue(error.message)
    }
  })

export const getPartCategoriesAction = createAsyncThunk(
  'admin_parts/getPartCategoriesAction',
  async () => {
    const response = await request.getPartCategories()
    return response.data
  }
)

export const getPartBrandsAction = createAsyncThunk(
  'admin_parts/getPartBrandsAction',
  async () => {
    const response = await request.getPartBrands()
    return response.data
  }
)

export const deleteBrandAction = createAsyncThunk(
  "admin_parts/deleteBrandAction",
  async function({id, handleCloseDeleteModal},{ rejectWithValue, dispatch}){
    try {
      await request.deleteBrandApi(id)
      handleCloseDeleteModal()
      dispatch(getPartBrandsAction())
    } catch (error) {
      return rejectWithValue(error.message)
    }
  })

export const deleteCategoryAction = createAsyncThunk(
  "admin_parts/deleteCategoryAction",
  async function({id, handleCloseDeleteModal},{ rejectWithValue, dispatch}){
    try {
      await request.deleteCategoryApi(id)
      handleCloseDeleteModal()
      dispatch(getPartCategoriesAction())
    } catch (error) {
      return rejectWithValue(error.message)
    }
  })

export const createBrandAction = createAsyncThunk(
  "admin_parts/createBrandAction",
  async function({name},{ rejectWithValue, dispatch}){
    try {
      await request.createBrandApi(name)
      dispatch(getPartBrandsAction())
    } catch (error) {
      console.log('Error: ', error.message)
      return rejectWithValue(error.message)
    }
  })

export const getPartSubcategoriesAction = createAsyncThunk(
  'admin_parts/getPartSubcategoriesAction',
  async () => {
    const response = await request.getPartSubcategories()
    return response.data
  }
)

export const createCategoryAction = createAsyncThunk(
  "admin_parts/createCategoryAction",
  async function({name},{ rejectWithValue, dispatch}){
    try {
      const response = await request.createCategory(name)
      // console.log(response)
      dispatch(getPartCategoriesAction())
    } catch (error) {
      console.log('Error: ', error.message)
      return rejectWithValue(error.message)
    }
  })

export const createPartAction = createAsyncThunk(
  "admin_parts/createPartAction",
  async function({data, handleSuccessModal, handleErrorModal, navigate},{ rejectWithValue, dispatch}){
    try {
      const response = await request.createPartApi(data)
      // console.log(response)
      await handleSuccessModal()
      setTimeout(() => handleSuccessModal, 3000)
      navigate('/admin/parts')
    } catch (error) {
      handleErrorModal()
      console.log('Errorh: ', error.response.data.title[0])
      return rejectWithValue(error.response.data.title[0])
    }
  })


export const updatePartAction = createAsyncThunk(
  "admin_parts/updatePartAction",
  async function({id, data, withFile, handleSuccessModal, handleErrorModal, navigate},{ rejectWithValue, dispatch}){
    try {
      withFile ? await request.updatePartWithFileApi({id, data}) :  await request.updatePartApi({id, data})
      await handleSuccessModal()
      setTimeout(() => handleSuccessModal, 3000)
      navigate('/admin/parts')
    } catch (error) {
      handleErrorModal()
      console.log('Error: ', error.message)
      return rejectWithValue(error.message)
    }
  })

export const deleteSubcategoryAction = createAsyncThunk(
  "admin_parts/deleteSubcategoryAction",
  async function({id, handleCloseSubDeleteModal},{ rejectWithValue, dispatch}){
    try {
      const response = await request.deleteSubcategoryApi(id)
      // console.log(response)
      handleCloseSubDeleteModal()
      dispatch(getPartSubcategoriesAction())
    } catch (error) {
      console.log('Error: ', error.message)
      return rejectWithValue(error.message)
    }
  })

export const createSubcategoryAction = createAsyncThunk(
  "admin_parts/createSubcategoryAction",
  async function(data,{ rejectWithValue, dispatch}){
    try {
      const response = await request.createSubcategoryApi(data)
      // console.log(response)
      dispatch(getPartSubcategoriesAction())
    } catch (error) {
      console.log('Error: ', error.message)
      return rejectWithValue(error.message)
    }
  })


export const getFilteredPartsAction = createAsyncThunk(
  'admin_parts/getFilteredPartsAction',
  async ({params, setPriceFilter}) => {
    const response = await request.getFilteredParts(params)
    return response.data
  }
)

export const updateCategoryAction = createAsyncThunk(
  "admin_parts/updateCategoryAction",
  async function({id, title},{ rejectWithValue, dispatch}){
    try {
      let response = await request.updateCategoryApi({id, title})
      // console.log(response)
      dispatch(getPartCategoriesAction())
    } catch (error) {
      console.log('Error: ', error.message)
      return rejectWithValue(error.message)
    }
  })

export const updateSubcategoryAction = createAsyncThunk(
  "admin_parts/updateSubcategoryAction",
  async function({id, title},{ rejectWithValue, dispatch}){
    try {
      let response = await request.updateSubcategoryApi({id, title})
      console.log(response)
      dispatch(getPartSubcategoriesAction())
    } catch (error) {
      console.log('Error: ', error.message)
      return rejectWithValue(error.message)
    }
  })

export const updateBrandAction = createAsyncThunk(
  "admin_parts/updateBrandAction",
  async function({id, title},{ rejectWithValue, dispatch}){
    try {
      let response = await request.updateBrandApi({id, title})
      console.log(response)
      dispatch(getPartBrandsAction())
    } catch (error) {
      console.log('Error: ', error.message)
      return rejectWithValue(error.message)
    }
  })

export const updateCrossReferenceNumbers = createAsyncThunk(
  "admin_parts/updateCrossReferenceNumbers",
  async function({id, data},{ rejectWithValue, dispatch}){
    try {
      let response = await request.updateCrossReferenceNumbersApi({id, data})
      console.log(response)
    } catch (error) {
      console.log('Error: ', error.message)
      return rejectWithValue(error.message)
    }
  })

export const ImportDataToPartsAction = createAsyncThunk(
  "admin_parts/ImportDataToPartsAction",
  async function({fData, handleErrorModal, setImportedData},{ rejectWithValue, dispatch}){
    try {
      const response = await request.importDataToParts(fData)
      console.log(response)
      dispatch(getPartsAction())
      window.location.reload()
      setImportedData(null)
    } catch (error) {
      console.log('Error: ', error.response)
      handleErrorModal()
      setImportedData(null)
      return rejectWithValue(error.response.data.error)
    }
  })

export const getAdminPaginatedPartsAction = createAsyncThunk(
  'admin_parts/getPaginatedPartsAction',
  async ({number}) => {
    const response = await request.getPaginatedParts(number)
    console.log('parts: ', response)
    return response.data
  }
)

export const deleteMultiplePartsAction = createAsyncThunk(
  'admin_parts/deleteMultiplePartsAction',
  async ({data}, {dispatch}) => {
    await request.deleteMultiplePartsApi(data)
    dispatch(getPartsAction())
  }
)