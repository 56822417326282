import React, {useState} from 'react';

// icons
import OnSiteServiceMobile from '../../assets/mobile-towing-icons/OnSiteService.svg'
import RoadsideServiceMobile from '../../assets/mobile-towing-icons/RoadsideService.svg'
import TowingMobile from '../../assets/mobile-towing-icons/Towing.svg'
import Button from "../Button";
import ModalForGetAConsultation from "../../widgets/ModalForGetAConsultation";

const mobileTowing = [
    {id: 1, icon: OnSiteServiceMobile, title: 'On-Site Service', subtitle: 'Our operators use the latest techniques and securement systems to protect your vehicle during transportation.'},
    {id: 2, icon: RoadsideServiceMobile, title: 'Roadside Service', subtitle: 'We understand that unexpected breakdowns and accidents can happen at any time, which is why we offer 24/7 emergency roadside assistance and towing services.'},
    {id: 3, icon: TowingMobile, title: 'Towing', subtitle: 'Whether you\'ve encountered a mechanical failure, or a flat tire our team is ready to lend a helping hand. We prioritize prompt response times to ensure minimal downtime.'},
]


const Services = () => {

    const [open, setOpen] = useState(false);
    const handleOpenModal = () => setOpen(!open);

    return (
        <>
            <section className='bg-dark p-4'>
                <div className='container m-auto text-center pt-28'>
                    <h5 className='text-[#ECECEC] lg:text-[26px] text-[22px] mb-10'>Mobile & Towing</h5>

                    <ul className='flex flex-wrap justify-center gap-14 mb-12'>
                        {
                            mobileTowing.map(item => (
                                <li className='flex flex-col gap-2 text-start sm:w-[46%] lg:flex-1' key={item.id}>
                                    <img className='w-12 mb-6' src={item.icon} alt="/"/>
                                    <p className='text-lg text-white font-medium'>{item.title}</p>
                                    <p className='text-base text-[#999]'>{item.subtitle}</p>
                                </li>
                            ))
                        }
                    </ul>
                    <Button onClick={handleOpenModal} text='Schedule for service' visibility={true}/>
                </div>
            </section>
            {
                open && <ModalForGetAConsultation open={open} handleOpen={handleOpenModal}/>
            }
        </>

    );
};

export default Services;