import React, {useEffect, useState} from 'react';
import PartRequestModal from "../../widgets/PartRequestModal";
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {getPaginatedPartsAction, getPartsAction} from "../../redux/parts/partsReducer";
import {Pagination} from "../../components/Pagination";

const PartsList = ({parts}) => {

  const [requestModal, setRequestModal] = useState(false);
  const [part, setPart] = useState(false);
  const dispatch = useDispatch()

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getPartsAction())
  }, [dispatch])

  const handleRequestModal = (part) => {
    setRequestModal(!requestModal)
    setPart(part)
  };

  return (
    <>
      <ul className='flex flex-wrap justify-start'>
        {
          parts?.results?.map(part => (
            <li key={part.id} className='lg:p-4 p-3 w-1/2 lg:w-[25%] block lg:block my-class'>
                <div className='flex group justify-between flex-col h-full gap-1.5 bg-[#ECECEC] text-dark rounded-md lg:p-2.5 p-1 cursor-pointer'>
                  <Link className='flex h-full justify-between flex-col' to={`/parts/${part.id}`}>
                    <img className='w-full h-60 object-contain rounded group-hover:transition group-hover:ease-in group-hover:duration-300 group-hover:h-48 group-hover:w-full' src={part.main_image} alt="/"/>
                    <div>
                      <p className='text-sm lg:text-base mt-0.5'>{part.title}</p>
                      <p className='text-sm lg:text-base font-semibold'>{part.price}</p>
                    </div>
                  </Link>
                  <button onClick={() => handleRequestModal(part)} className='z-50 mt-2 bg-[#559F23] hidden w-2/3 text-sm transition duration-600 group-hover:block rounded-full px-7 py-1 text-white'>Send a request</button>
                </div>
            </li>
          ))
        }
      </ul>
      <Pagination count={parts.count} dispatchFunction={getPaginatedPartsAction}/>
      {
        requestModal && <PartRequestModal part={part} handleOpen={handleRequestModal} open={requestModal}/>
      }
    </>

  );
};

export default PartsList;