import React, {useEffect, useState} from 'react';
import Input from "../../components/Input";
import {useFormik} from "formik";
import {partInfoSchema} from "../../utils/schema";
import SelectInput from "../../components/SelectInput";
import {useDispatch, useSelector} from "react-redux";
import {
  createPartAction,
  getPartBrandsAction,
  getPartCategoriesAction,
  getPartSubcategoriesAction
} from "../../redux/parts/PartsActions";
import Button from "../../components/Button";
import {XMarkIcon} from "@heroicons/react/20/solid";
import SuccessModal from "../../widgets/SuccessModal";
import ErrorModal from "../../widgets/ErrorModal";
import {useNavigate} from "react-router-dom";
import Compressor from "compressorjs";
import {createServiceAction} from "../../redux/services/ServicesActions";

const CreatePartInfoForm = () => {

  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const handleSuccessModal = () => setSuccessModal(!successModal)
  const handleErrorModal = () => setErrorModal(!errorModal)

  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedMainImage, setSelectedMainImage] = useState();
  const [emptyImagesNumber, setEmptyImagesNumber] = useState(8);
  const [selectedOption, setSelectedOption] = useState('Used');

  const navigate = useNavigate()

  const onSelectImages = (e) => {
    const files = Array.from(e.target.files);
    setSelectedImages([...selectedImages, ...files]);
  };

  const onSelectMainImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedMainImage(event.target.files[0]);
    }
  };

  const handleFileChange = (event, index) => {
    const file = event.target.files[0];
    const updatedImages = [...selectedImages];
    updatedImages[index] = file;
    setSelectedImages(updatedImages);
  };

  const handleRemoveFileInput = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
  };

  const handleRemoveMainImage = () => {
    setSelectedMainImage(null)
  };

  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  const dispatch = useDispatch()
  const {brands, categories, subcategories, error} = useSelector(state => state.admin_parts)

  useEffect(() => {
    setEmptyImagesNumber(8 - selectedImages.length);
  }, [selectedImages]);

  useEffect(() => {
    dispatch(getPartBrandsAction())
    dispatch(getPartCategoriesAction())
    dispatch(getPartSubcategoriesAction())
  }, [])


  const fields = [
    {id: 1, title: 'Category', name: 'category', arr: categories, placeholder: "Select one"},
    {id: 2, title: 'Subcategory', name: 'subcategory', arr: subcategories, placeholder: "Select one"},
    {id: 3, title: 'Brand', name: 'brand', arr: brands, placeholder: "Select one"},
  ]

  const nameField = [
    {id: 1, title: 'Name', name: 'title'}
  ]

  const heightFields = [
    {id: 1, title: 'Height', name: 'height'},
    {id: 2, title: 'Width', name: 'width'},
    {id: 3, title: 'Length', name: 'length'},
    {id: 4, title: 'Weight', name: 'weight'},
  ]

  const priceFields = [
    {id: 1, title: 'Price ($)', name: 'price'},
    {id: 2, title: 'Discount', name: 'discount'},
    {id: 3, title: 'Quantity', name: 'quantity'},
  ]

  const priceFields2 = [
    {id: 1, title: 'Location', name: 'location'},
    {id: 2, title: 'Link', name: 'link'},
  ]

  const [crossReferenceNumbersFields, setCrossReferenceNumbersFields] = useState([{title: 'Title', value: 'title2'}])

  const DescriptionField = [
    {id: 1, title: 'Description', name: 'description'}
  ]

  const options = [ 'Used', 'New'];

  const handleAddCrossReferenceNumbersField = () => {
    setCrossReferenceNumbersFields([...crossReferenceNumbersFields, {title: 'Title', value: 'title2'}])
  }

  const handleAddCrossReferenceNumbers = (e, index, name) => {
    if(name === 'title') {
       crossReferenceNumbersFields[index].title = e.target.value
    }else{
      crossReferenceNumbersFields[index].value = e.target.value
    }
  }

  const formik = useFormik({
    initialValues: {
      category: "",
      subcategory: "",
      brand: "",
      title: "",
      height: "",
      width: "",
      length: "",
      weight: "",
      price: "",
      discount: "",
      quantity: "",
      location: "",
      link: "",
      description: ""
    },
    validationSchema: partInfoSchema,
    onSubmit: (data) => {
      const fData = new FormData();
      selectedImages.forEach((file, index) => {
        new Compressor(file, {
          quality: 0.6,
          success(result) {
            fData.append(`images`, file);
          },
          error(err) {
            console.log(err.message);
          },
        });
      });
      setTimeout(() => {
        fData.append("main_image", selectedMainImage);
        fData.append("category", data.category);
        fData.append("subcategory", data.subcategory);
        fData.append("brand", data.brand);
        fData.append("title", data.title);
        fData.append("height", data.height);
        fData.append("width", data.width);
        fData.append("length", data.length);
        fData.append("weight", data.weight);
        fData.append("price", data.price);
        fData.append("discount", data.discount);
        fData.append("quantity", data.quantity);
        fData.append("location", data.location);
        fData.append("link", data.link);
        fData.append("condition", selectedOption);
        fData.append("description", data.description);
        fData.append("cross_reference_numbers", JSON.stringify(crossReferenceNumbersFields));
        dispatch(createPartAction({data: fData, handleErrorModal, handleSuccessModal, navigate}))
      }, 1000)
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit} className='overflow-y-auto h-[77vh]'>
      <div className='p-4 bg-white rounded-lg flex gap-4 md:flex-nowrap flex-wrap'>
        <div className='flex flex-col gap-7 md:w-2/5'>
          <div className='flex flex-col gap-2.5'>
            <p className='text-base font-medium'>
              General
            </p>
            {
              fields.map(field => (
                <SelectInput key={field.id} field={field} formik={formik} arr={field.arr}/>
              ))
            }
            {
              nameField.map(field => (
                <Input key={field.id} field={field} formik={formik}/>
              ))
            }
            <div className='flex gap-2'>
              {
                heightFields.map(field => (
                  <Input key={field.id} field={field} formik={formik}/>
                ))
              }
            </div>

            <div>
              <label className='text-[#7B7B7B] text-xs px-3' htmlFor="">Condition</label>
              <div className='flex gap-5 text-[#959595] mt-2.5'>
                {options.map((option) => (
                  <div key={option.id} className='flex justify-center items-center'>
                    <input
                      className='accent-[#A80301] w-5 h-5'
                      id={option}
                      type="radio"
                      value={option}
                      checked={selectedOption === option}
                      onChange={() => handleOptionChange(option)}
                    />
                    <label htmlFor={option} className='p-2' key={option}>
                      {option}
                    </label>
                  </div>
                ))}
              </div>

            </div>
          </div>
          <div className='flex flex-col gap-2.5'>
            <p className='text-base font-medium'>
              Price
            </p>
            <div className='flex gap-2'>
              {
                priceFields.map(field => (
                  <Input key={field.id} field={field} formik={formik}/>
                ))
              }
            </div>
            <div className='flex gap-2'>
              {
                priceFields2.map(field => (
                  <Input key={field.id} field={field} formik={formik}/>
                ))
              }
            </div>
          </div>

          <div className='flex flex-col gap-2.5 items-start'>
            <p className='text-base font-medium'>
              Cross Reference Numbers
            </p>
            <div className='flex flex-col gap-2 w-full'>
              {
                crossReferenceNumbersFields.map((field, index) => (
                  <div key={field.id} className='flex gap-2 w-full'>
                    <div className='flex flex-col w-full text-start'>
                      <div className='bg-[#F9F9F9] border-[#E3E3E3] border-b rounded-t-md'>
                        <label className='text-[#7B7B7B] text-xs px-3 py-1' htmlFor="">Title</label>
                        <div className='flex justify-between'>
                          <input onChange={(e) => handleAddCrossReferenceNumbers(e, index, 'title')} className='border-none w-full text-dark px-3 pb-2 text-base outline-none bg-transparent focus:ring-0'/>
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-col w-full text-start'>
                      <div className='bg-[#F9F9F9] border-[#E3E3E3] border-b rounded-t-md'>
                        <label className='text-[#7B7B7B] text-xs px-3 py-1' htmlFor="">Value</label>
                        <div className='flex justify-between'>
                          <input onChange={(e) => handleAddCrossReferenceNumbers(e, index, 'value')} className='border-none w-full text-dark px-3 pb-2 text-base outline-none bg-transparent focus:ring-0'/>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
            <button type='button' onClick={handleAddCrossReferenceNumbersField} className='text-[#1594F0]'>+ Add cross reference</button>
          </div>

          <div className='flex flex-col gap-2.5 items-start'>
            <p className='text-base font-medium'>
              Description
            </p>
            {
              DescriptionField.map(field => <Input key={field.id} field={field} formik={formik}/>)
            }
          </div>
        </div>

        <div className='md:w-3/5'>
          <p className='text-[#7B7B7B] text-xs mt-4'>
            Photo
          </p>
          <div className='flex flex-wrap gap-1.5 py-2.5 pt-1 rounded-md'>
            {
              selectedImages?.map((img, index) => (
                <div key={img} className='relative md:w-[32.7%] w-[49%]'>
                  <img className='h-40 object-contain bg-gray-400 rounded-md w-full' src={URL.createObjectURL(img)} alt="/"/>
                  <XMarkIcon onClick={() => handleRemoveFileInput(index)} className="h-6 w-6 text-red cursor-pointer absolute top-2 right-2 z-50" />
                </div>
              ))
            }

            {Array.from({ length: emptyImagesNumber }).map((_, index) => (
              <div key={`empty-${index}`} className='bg-gray-400 md:w-[32.7%] h-44 w-[49%] rounded-md'>
              </div>
            ))}
            <div className='flex flex-col gap-2.5 relative md:w-[32.7%] w-[49%]'>
              <div className='p-11 border border-[#E3E3E3] rounded-md text-[#7B7B7B] text-center text-sm relative'>
                <div>
                  <p>JPG, PNG</p>
                  <p>You can also upload files by</p>
                  <label className='text-[#3989B3] px-3 py-1 underline cursor-pointer z-50' htmlFor="images">Clicking here</label>
                </div>
                <input accept="image/png,image/jpg,image/jpeg" multiple onChange={onSelectImages} className='hidden' type="file" id='images'/>
              </div>
            </div>
          </div>
          <p className='text-[#7B7B7B] text-xs my-2'>
            Main photo
          </p>
          <div>
            {
              selectedMainImage ? <div className='relative md:w-[32.7%] w-[49%]'>
                <img className='h-40 object-contain bg-gray-400 rounded-md w-full' src={URL.createObjectURL(selectedMainImage)} alt="/"/>
                <XMarkIcon onClick={handleRemoveMainImage} className="h-6 w-6 text-red cursor-pointer absolute top-2 right-2 z-50" />
              </div> : <div className='flex flex-col gap-2.5 relative md:w-[32.7%] w-[49%]'>
              <div className='p-11 border border-[#E3E3E3] rounded-md text-[#7B7B7B] text-center text-sm relative'>
                <div>
                  <p>JPG, PNG</p>
                  <p>Upload the main page image by</p>
                  <label className='text-[#3989B3] px-3 py-1 underline cursor-pointer z-50' htmlFor="main_image">Clicking here</label>
                </div>
                <input accept="image/png,image/jpg,image/jpeg" onChange={onSelectMainImage} className='hidden' type="file" id='main_image'/>
              </div>
            </div>
            }
          </div>

        </div>
      </div>
      <div className='float-right m-3 z-50'>
        <Button disabled={selectedImages === null && selectedMainImage === []} type='submit' text='Create part' padding='4'/>
      </div>
    </form>

      {
        successModal && <SuccessModal title='Part created successfully' handleOpen={handleSuccessModal} open={successModal}/>
      }

      {
        errorModal && <ErrorModal text={error} handleOpen={handleErrorModal} open={errorModal}/>
      }
    </>
  );
};

export default CreatePartInfoForm;