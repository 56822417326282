import React from 'react';
import img1 from '../../assets/partners/ridgestone.svg'
import img2 from '../../assets/partners/freightliner.svg'
import img3 from '../../assets/partners/michelin.svg'
import img4 from '../../assets/partners/mobil.svg'
import img5 from '../../assets/partners/shell.svg'
import img6 from '../../assets/partners/volvo.svg'
import img7 from '../../assets/partners/ambest.svg'
import img8 from '../../assets/partners/bendix.svg'
import img9 from '../../assets/partners/carrier.svg'
import img10 from '../../assets/partners/cummins.svg'
import img11 from '../../assets/partners/detroit.svg'
import img12 from '../../assets/partners/eaton.svg'
import img13 from '../../assets/partners/fleetguard.svg'
import img14 from '../../assets/partners/kenworth.svg'
import img15 from '../../assets/partners/meritor.svg'
import img16 from '../../assets/partners/piterbuilt.svg'
import img17 from '../../assets/partners/stemco.svg'
import img18 from '../../assets/partners/thermoking.svg'
import img19 from '../../assets/partners/international.svg'

// active icons
import img111 from '../../assets/partners-active/ridgestone.svg'
import img222 from '../../assets/partners-active/freightliner.svg'
import img333 from '../../assets/partners-active/michelin.svg'
import img444 from '../../assets/partners-active/mobil.svg'
import img555 from '../../assets/partners-active/shell.svg'
import img666 from '../../assets/partners-active/volvo.svg'
import img777 from '../../assets/partners-active/ambest.svg'
import img888 from '../../assets/partners-active/bendix.svg'
import img999 from '../../assets/partners-active/carrier.svg'
import img1010 from '../../assets/partners-active/cummins.svg'
import img1111 from '../../assets/partners-active/detroit.svg'
import img1212 from '../../assets/partners-active/eaton.svg'
import img1313 from '../../assets/partners-active/fleetguard.svg'
import img1414 from '../../assets/partners-active/kenworth.svg'
import img1515 from '../../assets/partners-active/meritor.svg'
import img1616 from '../../assets/partners-active/piterbilt.svg'
import img1717 from '../../assets/partners-active/stemco.svg'
import img1818 from '../../assets/partners-active/thermoking.svg'
import img1919 from '../../assets/partners-active/international.svg'

const partners = [
    {id: 1, icon: img1, active_icon: img111},
    {id: 2, icon: img2, active_icon: img222},
    {id: 3, icon: img3, active_icon: img333},
    {id: 4, icon: img4, active_icon: img444},
    {id: 5, icon: img5, active_icon: img555},
    {id: 6, icon: img6, active_icon: img666},
    {id: 7, icon: img7, active_icon: img777},
    {id: 8, icon: img8, active_icon: img888},
    {id: 9, icon: img9, active_icon: img999},
    {id: 10, icon: img10, active_icon: img1010},
    {id: 11, icon: img11, active_icon: img1111},
    {id: 12, icon: img12, active_icon: img1212},
    {id: 13, icon: img13, active_icon: img1313},
    {id: 14, icon: img14, active_icon: img1414},
    {id: 15, icon: img15, active_icon: img1515},
    {id: 16, icon: img16, active_icon: img1616},
    {id: 17, icon: img17, active_icon: img1717},
    {id: 18, icon: img18, active_icon: img1818},
    {id: 19, icon: img19, active_icon: img1919},
]

const OurPartners = () => {
    return (
        <section className='container m-auto px-4 lg:pt-64 md:pt-[300px] pt-[320px] text-center'>
            <div className='lg:pt-20 md:pt-[100px] pt-[100px] ' id="our_partners">
                <h5 className='text-dark lg:text-[26px] text-[22px] mb-8'>Our Partners</h5>
                <ul className='flex flex-wrap justify-center mb-12'>
                {
                    partners.map(partner => (
                        <li key={partner.id} className="lg:p-2.5 p-1.5 sm:w-1/4 w-1/3 md:w-1/5 lg:w-[13%] my-partners-class lg:block">
                            <div className='flex flex-col gap-1 h-full group text-start bg-[#ECECEC] rounded-md pt-5 pb-7 px-6'>
                                <img className='max-h-full object-cover group-hover:hidden' src={partner.icon} alt="/"/>
                                <img className='max-h-full m-auto object-cover hidden group-hover:block' src={partner.active_icon} alt="/"/>
                            </div>
                        </li>
                    ))
                }
                    <li className="lg:p-2.5 p-1.5 sm:w-1/4 w-1/3 md:w-1/5 lg:w-[13%] my-partners-class block lg:block">
                        <div className='flex flex-col h-full gap-1 text-start bg-[#ECECEC] rounded-md pt-5 pb-7 px-6'>
                        </div>
                    </li>
                    <li className="lg:p-2.5 p-1.5 sm:w-1/4 sm:hidden w-1/3 md:w-1/5 block lg:w-[13%] my-partners-class lg:block">
                        <div className='flex flex-col gap-1 text-start bg-[#ECECEC] h-full rounded-md pt-5 pb-7 px-6'>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    );
};

export default OurPartners;