import {createAsyncThunk} from "@reduxjs/toolkit";
import {request} from "../api";

export const getPartsAction = createAsyncThunk(
  'parts/getParts',
  async () => {
    const response = await request.getParts()
    console.log('parts: ', response)
    return response.data
  }
)

export const getPaginatedPartsAction = createAsyncThunk(
  'parts/getPaginatedPartsAction',
  async ({number}) => {
    const response = await request.getPaginatedParts(number)
    console.log('parts: ', response)
    return response.data
  }
)


export const getLandingSearchedPartsAction = createAsyncThunk(
  'parts/getLandingSearchedPartsAction',
  async ({searchValue}) => {
    const response = await request.getSearchedParts(searchValue)
    return response.data
  }
)

export const getPartByIdAction = createAsyncThunk(
  'parts/getPartById',
  async (id) => {
    const response = await request.getPartById(id)
    return response.data
  }
)

export const getPartCategoriesAction = createAsyncThunk(
  'parts/getPartCategoriesAction',
  async () => {
    const response = await request.getPartCategories()
    return response.data
  }
)

export const getPartBrandsAction = createAsyncThunk(
  'parts/getPartBrandsAction',
  async () => {
    const response = await request.getPartBrands()
    return response.data
  }
)

export const getFilteredPartsAction = createAsyncThunk(
  'parts/getFilteredPartsAction',
  async ({params}) => {
    const response = await request.getFilteredParts(params)
    return response.data
  }
)

export const partRequestAction = async ({data, handleSuccessModal, handleErrorModal}) => {
  try {
    const response = await request.partRequestApi(data)
    console.log(response)
    handleSuccessModal()
    setInterval(() => handleSuccessModal, 3000)
  } catch (error){
    handleErrorModal()
    console.log('Error: ', error.message)
  }
}
