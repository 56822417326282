import {API, fileAPI} from "./axiosConfig";

export const request = {
  authApi: (data) => API.post('auth/jwt/create/', data),
  refreshTokenApi: (refreshToken) => API.post('auth/jwt/refresh/', {refresh : refreshToken}),
  registerApi: (data) => API.post('auth/jwt/create/', data),

  getUserApi: () => API.get('auth/users/'),
  getUserByIdApi: (id) => API.get(`auth/users/${id}/`),
  deleteUserApi: (id) => API.delete(`auth/users/${id}/`),
  blockUserApi: (id) => API.post(`auth/users/block/`, {id}),
  inviteUserApi: (data) => API.post('auth/users/', data),
  updateUserApi: ({id, data}) => API.patch(`auth/users/${id}/`, data),
  resetUserPasswordApi: (data) => API.post('auth/users/reset_password_confirm/', data),

  createServiceApi: (data) => fileAPI.post('services/', data),
  getServiceByIdApi: (id) => id && API.get(`services/${id}/`),
  getServiceApi: () => API.get('services/'),
  deleteServiceApi: (id) => API.delete(`services/${id}/`),
  updateServiceApi: ({id, data}) => id && API.patch(`services/${id}/`, data),
  updateServiceWithFileApi: ({id, data}) => id && fileAPI.patch(`services/${id}/`, data),

  getPartsApi: () => API.get('parts/'),
  createPartApi: (data) => fileAPI.post('parts/', data),
  updatePartApi: ({id, data}) => API.patch(`parts/${id}/`, data),
  updatePartWithFileApi: ({id, data}) => fileAPI.patch(`parts/${id}/`, data),
  updateCrossReferenceNumbersApi: ({id, data}) => API.patch(`parts/cross-reference-number/${id}/`, data),
  getPartByIdApi: (id) => API.get(`parts/${id}/`),
  deletePartApi: (id) => API.delete(`parts/${id}/`),
  deleteMultiplePartsApi: (data) => API.post(`parts/delete_multiple/`, {part_ids: data}),
  getFilteredParts: (params) => API.get("parts/", { params: params }),
  getSearchedParts: (value) => API.get("parts/", { params: {search: value} }),
  importDataToParts: (fData) => fileAPI.post("parts/uploud_file/", fData),
  getPaginatedParts: (number) => API.get('parts/', { params: {page: number}}),

  deleteBrandApi: (id) => API.delete(`parts/brand/${id}/`),
  createBrandApi: (name) => API.post('parts/brand/', {title: name}),
  getPartBrands: () => API.get('parts/brand/'),
  updateBrandApi: ({id, title}) => API.patch(`parts/brand/${id}/`, {title: title}),

  deleteCategoryApi: (id) => API.delete(`parts/category/${id}/`),
  getPartCategories: () => API.get('parts/category/'),
  createCategory: (name) => API.post('parts/category/', {title: name}),
  updateCategoryApi: ({id, title}) => API.patch(`parts/category/${id}/`, {title: title}),

  createSubcategoryApi: (data) => API.post('parts/subcategory/', data),
  getPartSubcategories: () => API.get('parts/subcategory/'),
  deleteSubcategoryApi: (id) => API.delete(`parts/subcategory/${id}/`),
  updateSubcategoryApi: ({id, title}) => API.patch(`parts/subcategory/${id}/`, {title: title}),
}

