import React from 'react';
import {ExclamationCircleIcon} from "@heroicons/react/20/solid";

const SelectInput = ({formik, field, arr}) => {

  return (
    <div className='flex flex-col w-full text-start'>
      <div className={`flex flex-col ${formik.errors[field.name] && formik.touched[field.name] ? 'bg-[#FCF5F4] border-[#A80301]' : 'bg-[#F9F9F9] border-[#E3E3E3]'} border-b rounded-t-md`}>
        <label className='text-[#7B7B7B] text-xs px-3 py-1' htmlFor="">{field.title}</label>
        <select value={field.value} defaultChecked={field.value} placeholder={field.placeholder} onChange={formik.handleChange} className="text-dark px-2 mr-2 pb-2 text-base outline-none bg-transparent" name={field.name} id="service">
          <option value=''></option>
          {
            arr?.map(el => (
              <option value={el.id}>{el.title || el.id}</option>
            ))
          }
        </select>
      </div>
      {formik.errors[field.name] && formik.touched[field.name] ? <div className='text-[#A80301] text-xs flex gap-1 mt-1 ml-3'>
        <ExclamationCircleIcon className="h-4 w-4" />{formik.errors[field.name]}
      </div> : null}
    </div>
  );
};

export default SelectInput;