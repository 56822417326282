import React from 'react';

const Button = ({onClick, text, type, padding, disabled}) => {
  return (
    <button disabled={disabled} type={type} onClick={onClick} className={`lg:visible ${padding ? `!md:px-${padding} md:w-fit w-full` : 'w-full'} bg-[#A80301] py-2 px-5 text-white rounded-lg font-medium flex justify-center`}>
      {text}
    </button>
  );
};

export default Button;
