import { configureStore } from '@reduxjs/toolkit';
import {partsSlice} from "./parts/partsSlice";
import {servicesSlice} from "./services/servicesSlice";
import {adminUsersSlice} from "../../admin/redux/users/UsersSlices";
import {adminServicesSlice} from "../../admin/redux/services/ServicesSlices";
import {adminPartsSlice} from "../../admin/redux/parts/PartsSlices";

export const store = configureStore({
  reducer: {
    parts: partsSlice,
    services: servicesSlice,
    admin_services: adminServicesSlice,
    admin_parts: adminPartsSlice,
    admin_users: adminUsersSlice,
  },
});