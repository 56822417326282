import {Card, Typography} from "@material-tailwind/react";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {deleteServiceAction, getServiceByIdAction, getServicesAction} from "../../redux/services/ServicesActions";
import {PencilIcon} from "@heroicons/react/20/solid";
import UpdateServiceModal from "../../widgets/UpdateServiceModal";
import delete_icon from "../../assets/delete.svg";
import useWindowDimensions from "../../utils/GetWindowDimantions";
import {truncateString} from "../../../landing_page/utils/truncate";

const TABLE_HEAD = ["Photo", "Name", "Type of service", "Description", "Actions"];

export function ServicesList() {

  const {windowWidth, windowHeight} = useWindowDimensions()

  const [openDeleteModal, setOpenDeleteModel] = useState({id: null, opened: false})
  const [openUpdateModal, setOpenUpdateModel] = useState(false)

  const handleOpenUpdateModal = (id) => {
    dispatch(getServiceByIdAction({id}))
    setTimeout(() => setOpenUpdateModel(!openUpdateModal), 800)

  }

  const handleOpenDeleteModal = (id) => {
    setOpenDeleteModel({id, opened: !openDeleteModal.opened})
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModel({id: openDeleteModal.id, opened: !openDeleteModal.opened})
  }

  const dispatch = useDispatch()
  const {services, serviceById, loading} = useSelector(state => state.admin_services)


  const handleDeleteService = (id) => {
    dispatch(deleteServiceAction({id, handleCloseDeleteModal}))
  }

  useEffect(() => {
    !services.length && dispatch(getServicesAction())
  }, [])

  return (
    <>
      <Card className="w-full md:h-[85vh] h-[73vh] overflow-scroll">
        <table className="w-full min-w-max table-auto text-left">
          {
            windowWidth > 1000 &&
            <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th key={head} className="border-b border-blue-gray-100 p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-medium leading-none text-dark"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
            </thead>
          }
          <tbody>
          {services.map(({logo, image, name, type, description, id}, index) => {
            const isLast = index === services.length - 1;
            const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

            return (
              <tr className={windowWidth < 1000 ? 'flex flex-col' : 'w-full'} key={name}>
                <td className={classes} style={windowWidth < 1000 ? {display: 'flex', justifyContent: 'space-between', height: '78px', width: "100%", border: 'none'} : {height: '78px', width: "80px"}}>
                  {
                    windowWidth < 1000 &&
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-medium leading-none text-dark"
                    >
                      Photo
                    </Typography>
                  }
                  <img loading='lazy' className='object-contain h-full' src={logo || image} alt="/"/>
                </td>
                <td className={classes} style={windowWidth < 1000 ? {display: 'flex', justifyContent: 'space-between', border: 'none', paddingTop: '2px', paddingBottom: '2px'} : null}>
                  {
                    windowWidth < 1000 &&
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-medium leading-none text-dark"
                    >
                      Name
                    </Typography>
                  }
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {name}
                  </Typography>
                </td>
                <td className={classes} style={windowWidth < 1000 ? {display: 'flex', justifyContent: 'space-between', border: 'none', paddingTop: '2px', paddingBottom: '2px'} : null}>
                  {
                    windowWidth < 1000 &&
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-medium leading-none text-dark"
                    >
                      Type
                    </Typography>
                  }
                  <Typography variant="small" color="blue-gray" className="font-normal">
                    {type === '' ? 'All services' : type}
                  </Typography>
                </td>
                <td className={classes} style={windowWidth < 650 ? {display: 'flex', justifyContent: 'space-between', border: 'none', paddingTop: '2px', paddingBottom: '2px'} : null}>
                  {
                    windowWidth < 1000 &&
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-medium leading-none text-dark"
                    >
                      Description
                    </Typography>
                  }
                  <Typography variant="small" color="blue-gray" className="font-normal w-30 truncate">
                    {windowHeight < 650 ? truncateString(description, 30) : truncateString(description, 60)}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography as="a" variant="small" className="font-medium flex gap-2">
                    <PencilIcon onClick={() => handleOpenUpdateModal(id)} className="h-6 w-6 text-[#C5C5C5] cursor-pointer" />
                    <div className='relative'>
                      <img loading='lazy' onClick={() => handleOpenDeleteModal(id)} className="h-6 w-6 text-gray-400 cursor-pointer" src={delete_icon} alt="/"/>
                      {
                        openDeleteModal.id === id && openDeleteModal.opened && <div className='p-2 w-96 rounded-lg bg-white z-10 border border-[#C5C5C5] absolute top-0 right-6'>
                          <p className='w-fit text-base'>
                            Are you sure you want to delete this message?
                          </p>
                          <div className='flex text-white gap-1.5 mt-2 float-right'>
                            <button onClick={() => handleDeleteService(id)} className='bg-[#C5C5C5] px-5 py-1.5 rounded-md'>Yes</button>
                            <button onClick={handleCloseDeleteModal} className='bg-[#C5C5C5] px-5 py-1.5 rounded-md'>No</button>
                          </div>
                        </div>
                      }
                    </div>
                  </Typography>
                </td>
              </tr>
            );
          })}
          </tbody>
        </table>
      </Card>
      {
        openUpdateModal && <UpdateServiceModal serviceById={serviceById} handleOpen={handleOpenUpdateModal} open={openUpdateModal}/>
      }
    </>
  );
}