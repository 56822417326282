import React, {Fragment} from 'react';
import {XMarkIcon} from "@heroicons/react/20/solid";
import {Dialog} from "@material-tailwind/react";
import Slider from "react-slick";
import {ArrowLongLeftIcon, ArrowLongRightIcon} from "@heroicons/react/24/outline";

const PartImagesModal = ({handleOpen, open, part}) => {

  function SampleNextArrow(props) {
    const {onClick } = props;
    return (
      <ArrowLongRightIcon  className="h-9 w-9 text-dark bottom-1/2 absolute -right-12 z-50 cursor-pointer"
                          onClick={onClick} />
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <ArrowLongLeftIcon onClick={onClick} className="h-9 w-9 text-dark bottom-1/2 absolute -left-12 z-20 cursor-pointer" />
    );
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  return (
    <div>
      <Fragment>
        <Dialog size='md' dismiss={{outsidePress: false}} className='p-5' open={open} handler={handleOpen}>
          <button onClick={handleOpen}
                  type="button"
                  className="absolute w-7 top-4 right-4 text-base text-red bg-transparent rounded-lg ml-auto inline-flex items-center"
                  data-modal-toggle="defaultModal"
          >
            <XMarkIcon className="h-6 w-6 text-[#C5C5C5]" />
            <span className="sr-only">Close modal</span>
          </button>
          <div>
            <p>{part.title}</p>
            <div className='py-6 px-12 w-full'>
              <Slider {...settings}>
                {
                  part.part_image.map(part => (
                    <div className='border border-transparent p-3'>
                      <img className='w-full h-60 object-contain m-auto' src={part.image} alt="/"/>
                    </div>
                  ))
                }
              </Slider>
            </div>
          </div>
        </Dialog>
      </Fragment>
    </div>
  );
};

export default PartImagesModal;