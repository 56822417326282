import axios from "axios";
import {request} from "./api";


export const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const fileAPI = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

API.interceptors.request.use(config => {
  const token = localStorage.getItem('access'); // Retrieve the token from storage
  if (token) {
    config.headers['Authorization'] = `JWT ${token}`;
  }
  config.headers['Content-Type'] = 'application/json';
  return config;
});

fileAPI.interceptors.request.use(config => {
  const token = localStorage.getItem('access'); // Retrieve the token from storage
  if (token) {
      config.headers['Authorization'] = `JWT ${token}`;
  }
  config.headers['Content-Type'] = 'multipart/form-data';
  return config;
});

fileAPI.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const refreshToken = localStorage.getItem('refresh_token');
      if (refreshToken) {
        try {
          const response = await request.refreshTokenApi(refreshToken);
          const newAccessToken = response.data.access;
          localStorage.setItem('access', newAccessToken);
          return fileAPI(originalRequest);
        } catch (refreshError) {
        }
      }
    }
    return Promise.reject(error);
  }
);





