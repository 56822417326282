import {createSlice} from "@reduxjs/toolkit";
import {
  getFilteredPartsAction, getLandingSearchedPartsAction, getPaginatedPartsAction,
  getPartBrandsAction,
  getPartByIdAction,
  getPartCategoriesAction,
  getPartsAction
} from "./partsReducer";

const initialState = {
  loading: false,
  error: false,
  parts: [],
  part: {},
  //------options-----
  categories: [],
  brands: []
}

const partSlice = createSlice({
  name: 'parts',
  initialState,
  reducers: {

  },
  extraReducers: {
    [getPartsAction.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getPartsAction.fulfilled]: (state, action) => {
      state.loading = false
      state.error = false
      state.parts = action.payload
    },
    [getPartsAction.rejected]: (state) => {
      state.loading = false
      state.error = true
    },

    [getPaginatedPartsAction.pending]: (state) => {
      state.error = false;
    },
    [getPaginatedPartsAction.fulfilled]: (state, action) => {
      state.error = false
      state.parts = action.payload
    },
    [getPaginatedPartsAction.rejected]: (state) => {
      state.error = true
    },
    // part by id
    [getPartByIdAction.pending]: (state) => {
      state.error = false;
    },
    [getPartByIdAction.fulfilled]: (state, action) => {
      state.error = false
      state.part = action.payload
    },
    [getPartByIdAction.rejected]: (state) => {
      state.error = true
    },

  //   options
    [getPartCategoriesAction.pending]: (state) => {
      state.error = false;
    },
    [getPartCategoriesAction.fulfilled]: (state, action) => {
      state.error = false
      state.categories = action.payload
    },
    [getPartCategoriesAction.rejected]: (state) => {
      state.error = true
    },

    [getPartBrandsAction.pending]: (state) => {
      state.error = false;
    },
    [getPartBrandsAction.fulfilled]: (state, action) => {
      state.error = false
      state.brands = action.payload
    },
    [getPartBrandsAction.rejected]: (state) => {
      state.error = true
    },

  //   filter
    [getFilteredPartsAction.pending]: (state) => {
      state.error = false;
    },
    [getFilteredPartsAction.fulfilled]: (state, action) => {
      state.error = false
      state.parts = action.payload
    },
    [getFilteredPartsAction.rejected]: (state) => {
      state.error = true
    },

    //   sort
    [getLandingSearchedPartsAction.pending]: (state) => {
      state.error = false;
    },
    [getLandingSearchedPartsAction.fulfilled]: (state, action) => {
      state.error = false
      state.parts = action.payload
    },
    [getLandingSearchedPartsAction.rejected]: (state) => {
      state.error = true
    },
  },
})

export const partsSlice = partSlice.reducer;


