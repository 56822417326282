import React, {useEffect, useState} from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import {useDispatch, useSelector} from "react-redux";
import {
  createCategoryAction, deleteCategoryAction, deleteSubcategoryAction,
  getPartCategoriesAction,
  getPartSubcategoriesAction, updateCategoryAction
} from "../../redux/parts/PartsActions";
import Button from "../../components/Button";
import {PencilIcon, PencilSquareIcon, TrashIcon} from "@heroicons/react/24/outline";
import Subcategory from "./Subcategory";
import delete_icon from "../../assets/delete.svg";
import {PaperAirplaneIcon} from "@heroicons/react/20/solid";

function Icon({ id, open }) {
  return (

      id !== open ? <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M10 5.757v8.486M5.757 10h8.486M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
    </svg> : <div className='border rounded-full p-1 border-gray-800'>
        <svg className="w-3 h-3 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h16"/>
        </svg>
      </div>
  );
}

export default function Categories() {

  const dispatch = useDispatch()
  const {categories, subcategories} = useSelector(state => state.admin_parts)

  const [open, setOpen] = React.useState(0);
  const [addCategory, setAddCategory] = React.useState(false);
  const [categoryName, setCategoryName] = useState('')
  const [categoryUpdatedName, setCategoryUpdatedName] = useState({id: null, title: ''})
  const [editCategory, setEditCategory] = useState(false)
  const [openDeleteModal, setOpenDeleteModel] = useState({id: null, opened: false})

  const handleOpenEditCategoryInput = (category) => {
    setCategoryUpdatedName({id: category.id, title: category.title})
    setEditCategory(!editCategory)
  }

  const handleEditCategory = () => {
    dispatch(updateCategoryAction(categoryUpdatedName))
    setEditCategory(false)
  }

  const onChangeCategoryName = (e) => {
    setCategoryUpdatedName({...categoryUpdatedName, title : e.target.value})
  }
  const handleOpenDeleteModal = (id) => {
    setOpenDeleteModel({id, opened: !openDeleteModal.opened})
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModel({id: openDeleteModal.id, opened: !openDeleteModal.opened})
  }

  const handleDeleteCategory = (id) => {
    dispatch(deleteCategoryAction({id, handleCloseDeleteModal}))
  }

  const handleCreateCategory = () => {
    dispatch(createCategoryAction({name: categoryName}))
    toggleAddCategory()
  }
  const toggleAddCategory = () => setAddCategory(!addCategory)
  const handleOpen = (value) => setOpen(open === value ? 0 : value);


  useEffect(() => {
    dispatch(getPartCategoriesAction())
    dispatch(getPartSubcategoriesAction())
  }, [])

  return (
    <div className='mt-1'>
      {
        categories.map(category => (
          <Accordion className='relative mb-8 py-0' open={open === category.id} icon={<Icon id={category.id} open={open} />}>
            {
              editCategory && categoryUpdatedName.id === category.id ? <div className='absolute top-1.5 right-8 flex gap-2'>
                <PaperAirplaneIcon onClick={handleEditCategory} className="h-6 w-6 text-[#C5C5C5] cursor-pointer" />
              </div> : <div className='absolute top-1.5 right-8 flex gap-2'>
                <div className='relative'>
                  <img onClick={() => handleOpenDeleteModal(category.id)} className="h-6 w-6 text-gray-400 cursor-pointer" src={delete_icon} alt="/"/>
                  {
                    openDeleteModal.id === category.id && openDeleteModal.opened && <div className='p-2 w-96 rounded-lg bg-white z-10 border border-[#C5C5C5] absolute top-0 right-6'>
                      <p className='w-fit text-base'>
                        Are you sure you want to delete this message?
                      </p>
                      <div className='flex text-white gap-1.5 mt-2 float-right'>
                        <button onClick={() => handleDeleteCategory(category.id)} className='bg-[#C5C5C5] px-5 py-1.5 rounded-md'>Yes</button>
                        <button onClick={handleCloseDeleteModal} className='bg-[#C5C5C5] px-5 py-1.5 rounded-md'>No</button>
                      </div>
                    </div>
                  }
                </div>
                <PencilSquareIcon onClick={() => handleOpenEditCategoryInput(category)} className="h-6 w-6 text-[#C5C5C5] cursor-pointer" />
              </div>
            }
            <AccordionHeader className={`text-md font-normal pl-4 py-2 ${open === category.id ? 'border-l-2 border-[#0BA8E7]' : 'border-l-2 border-gray-300'}`} onClick={() => handleOpen(category.id)} style={{ borderBottom: 'none' }}>
              {
                editCategory && categoryUpdatedName.id === category.id ? <input className='w-11/12 border pl-2' onChange={onChangeCategoryName} value={categoryUpdatedName.title} type="text"/> : <p>{category.title}</p>
              }

            </AccordionHeader>
            <AccordionBody className='pl-4 overflow-y-auto'>
              <Subcategory subcategories={subcategories} categoryId={category.id}/>
            </AccordionBody>
          </Accordion>
        ))
      }
      { addCategory ?
        <>
          <div className='flex flex-col w-full text-start mt-5 mb-3'>
            <div className='bg-[#F9F9F9] border-[#E3E3E3] border-b rounded-t-md'>
              <label className='text-[#7B7B7B] text-xs px-3 py-1' htmlFor="">Name</label>
              <div className='flex justify-between'>
                <input onChange={(e) => setCategoryName(e.target.value)} className='border-none w-full text-dark px-3 pb-2 text-base outline-none bg-transparent focus:ring-0'/>
              </div>
            </div>
          </div>
          <div className='flex gap-2'>
            <Button onClick={handleCreateCategory} type="button" padding='2' text='Create'/>
            <button type='button' onClick={toggleAddCategory} className='bg-gray-400 py-2 px-5 text-white md:w-fit w-full rounded-lg font-medium flex justify-center'>
              Cancel
            </button>
          </div>
        </> : <button onClick={toggleAddCategory} className='text-[#1594F0] mt-5'>+ Create category</button>
      }
    </div>
  );
}