import React, {Fragment, useState} from 'react';
import Button from "../components/Button";
import {XMarkIcon} from "@heroicons/react/20/solid";
import {Dialog} from "@material-tailwind/react";
import {useFormik} from "formik";
import {serviceSchema} from "../utils/schema";
import Input from "../components/Input";
import {createServiceAction} from "../redux/services/ServicesActions";
import SuccessModal from "./SuccessModal";
import ErrorModal from "./ErrorModal";
import {useDispatch, useSelector} from "react-redux";
import Compressor from 'compressorjs';

const CreateServiceModal = ({handleOpen, open}) => {

  const [selectedOption, setSelectedOption] = useState('');
  const [img, setImg] = useState();
  const [logo, setLogo] = useState();
  const [imgURL, setImgURL] = useState();
  const [logoURL, setLogoURL] = useState();

  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const handleSuccessModal = () => setSuccessModal(!successModal)
  const handleErrorModal = () => setErrorModal(!errorModal)
  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };

  const dispatch = useDispatch()
  const {error} = useSelector(state => state.admin_services)

  const selectedImg = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImg(event.target.files[0])
      setImgURL(URL.createObjectURL(event.target.files[0]));
    }
  };

  const selectedLogo = (event) => {
    if (event.target.files && event.target.files[0]) {
      setLogo(event.target.files[0])
      setLogoURL(URL.createObjectURL(event.target.files[0]));
    }
  };


  const options = [{title: 'Truck Repair', value: 'Truck Repair'}, {title: 'Trailer Repair', value: 'Trailer Repair'}, {title: 'Mobile & Towing', value: 'Mobile & Towing'}, {title: 'All services', value: ''}];

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: serviceSchema,
    onSubmit: async (data) => {
      const fData = new FormData();
      new Compressor(logo, {
        quality: 0.2,
        success(result) {
          fData.append("logo", logo);
          new Compressor(img, {
            quality: 0.2,
            success(result) {
              fData.append("image", img);
              fData.append("name", data.name);
              fData.append("description", data.description);
              fData.append("type", selectedOption);
              dispatch(createServiceAction({fData, handleErrorModal, handleSuccessModal, handleOpen}))
            },
            error(err) {
              console.log(err.message);
            },
          });
          },
        error(err) {
          console.log(err.message);
        },
      });
      },
  });

  const fields = [
    {id: 1, title: 'Name', name: 'name'},
    {id: 2, title: 'Definition', name: 'description'},
  ]

  return (
    <>
      <Fragment>
        <Button onClick={handleOpen} variant="gradient">
          Open Dialog
        </Button>
        <Dialog className='p-4' open={open} handler={handleOpen}>
          <button onClick={handleOpen}
                  type="button"
                  className="absolute w-7 top-4 right-4 text-base text-[#C5C5C5] bg-transparent rounded-lg ml-auto inline-flex items-center"
                  data-modal-toggle="defaultModal"
          >
            <XMarkIcon className="h-6 w-6 text-[#C5C5C5]" />
            <span className="sr-only">Close modal</span>
          </button>
          <div>
            <form onSubmit={formik.handleSubmit}>
              <p className='pb-4 pl-1 text-lg text-dark'>Create service</p>
              <div className='flex flex-col gap-2.5 relative'>
                <div className='flex gap-2'>
                  <div className=' w-1/3'>
                    <label className='text-[#7B7B7B] text-xs px-2 py-1' htmlFor="">Logo</label>
                    <div className='border border-[#E3E3E3] rounded-md text-[#7B7B7B] text-center h-40 text-sm relative'>
                      {
                        logoURL ? <img className='object-cover w-full h-full' src={logoURL} alt="wrong"/> :
                          <div className='flex flex-col justify-center h-full items-center'>
                            <p>SVG, JPG, PNG</p>
                            <p>Upload logo by</p>
                            <label className='text-[#3989B3] px-3 py-1 underline cursor-pointer z-50' htmlFor="logo">Clicking here</label>
                          </div>
                      }
                      <label className='absolute w-full h-full top-0 left-0 cursor-pointer' htmlFor="file"></label>
                      <input accept='.jpg, .jpeg, .png, .svg' onChange={selectedLogo} className='hidden' type="file" id='logo'/>
                    </div>
                  </div>
                  <div className=' w-1/3'>
                    <label className='text-[#7B7B7B] text-xs px-2 py-1' htmlFor="">Image</label>
                    <div className='border border-[#E3E3E3] rounded-md text-[#7B7B7B] text-center h-40 text-sm relative'>
                      {
                        imgURL ? <img className='object-cover w-full h-full' src={imgURL} alt="wrong"/> :
                          <div className='flex flex-col justify-center h-full items-center'>
                            <p>JPG, PNG</p>
                            <p>Upload image by</p>
                            <label className='text-[#3989B3] px-3 py-1 underline cursor-pointer z-50' htmlFor="file">Clicking here</label>
                          </div>
                      }
                      <label className='absolute w-full h-full top-0 left-0 cursor-pointer' htmlFor="file"></label>
                      <input accept='.jpg, .jpeg, .png' onChange={selectedImg} className='hidden' type="file" id='file'/>
                    </div>
                  </div>
                </div>
                {
                  fields.map(field => (
                    <Input key={field.id} field={field} formik={formik}/>
                  ))
                }
              </div>
              <div className='flex justify-between mt-10 flex-wrap'>
                {options.map((option) => (
                  <div>
                    <input
                      className='accent-[#A80301]'
                      id={option.title}
                      type="radio"
                      value={option.value}
                      checked={selectedOption === option.value}
                      onChange={() => handleOptionChange(option.value)}
                    />
                    <label htmlFor={option.title} className='p-1' key={option.title}>
                      {option.title}
                    </label>
                  </div>
                ))}
              </div>

              <div className='float-right flex mt-8'>
                <Button disabled={!img && !logo} padding='16' type='submit' text='Create service' visibility={true}/>
              </div>
            </form>
          </div>
        </Dialog>
      </Fragment>

      {
        successModal && <SuccessModal title='Service created successfully' handleOpen={handleSuccessModal} open={successModal}/>
      }

      {
        errorModal && <ErrorModal text={error} handleOpen={handleErrorModal} open={errorModal}/>
      }

    </>
  );
};

export default CreateServiceModal;