import React, {useState} from 'react';
import instagram from "../assets/icons/instagram.svg";
import facebook from "../assets/icons/facebook.svg";
import telegram from "../assets/icons/telegram.svg";

const AddressTab = () => {

  const [tab, setTab] = useState(true);
  const open1Tab = () => setTab(true)
  const open2Tab = () => setTab(false)

  return (
    <div className='w-full absolute top-[81.6%] left-0'>
      <div className='h-full w-[90%] lg:w-1/2 m-auto rounded-md'>
        <div className='flex justify-between h-16'>
          <button onClick={open1Tab} className={`text-center px-10 cursor-pointer flex items-center w-full rounded-tl-md ${tab ? 'text-[#A82323] bg-[#E7E7E7] font-bold' : 'text-[#E7E7E7] bg-dark'}`}>Illinois, Ill</button>
          <button disabled={true} onClick={open2Tab} className={`text-center px-10 flex items-center w-full rounded-tr-md ${!tab ? 'text-[#A82323] bg-[#E7E7E7] font-bold' : 'text-[#E7E7E7] bg-dark'}`}></button>
        </div>

        {
          !tab ? <>
            <div className='bg-[#E7E7E7] w-full h-full flex lg:flex-nowrap flex-wrap gap-2 p-10'>
              <ul className='flex flex-col gap-3  lg:basis-[40%] w-full'>
                <li className='text-sm text-[#000]'>Address</li>
                <li className='text-[#838383] text-sm'>2685 Celanese Rd, Rock Hill, SC 29732, USA celanese Rd, Rock</li>
                <li className='flex gap-4 mt-2'>
                  <img className='w-6 h-6' src={instagram} alt="/"/>
                  <img className='w-6 h-6' src={facebook} alt="/"/>
                  <img className='w-6 h-6' src={telegram} alt="/"/>
                </li>
              </ul>
              <div className='flex lg:basis-[30%] w-full address-tab-line'>
                <ul className='flex w-full flex-col gap-3'>
                  <hr className='w-full h-0.5 bg-[#CECECE] mt-3 lg:hidden'/>
                  <li className='text-sm text-[#000]'>Email</li>
                  <li className='text-[#838383] text-sm'>hello@example@gmail.com</li>
                </ul>
              </div>
              <div className='flex lg:basis-[30%] w-full address-tab-line'>
                <ul className='flex w-full flex-col gap-3'>
                  <hr className='w-full h-0.5 bg-[#CECECE] mt-3 lg:hidden'/>
                  <li className='text-sm text-[#000]'>Phone Number</li>
                  <li className='text-[#838383] text-sm'>+1 400 122 345 555</li>
                </ul>
              </div>
            </div>
          </> : <>
            <div className='bg-[#E7E7E7] w-full h-full flex lg:flex-nowrap flex-wrap p-10'>
              <ul className='flex flex-col gap-3  lg:basis-[40%] w-full'>
                <li className='text-sm text-[#000]'>Address</li>
                <li className='text-[#838383] text-sm'>210 Flannigan Rd, Hampshire, IL 60140</li>
                <li className='flex gap-4 mt-2'>
                  <img className='w-6 h-6' src={instagram} alt="/"/>
                  <img className='w-6 h-6' src={facebook} alt="/"/>
                  <img className='w-6 h-6' src={telegram} alt="/"/>
                </li>
              </ul>
              <div className='flex lg:basis-[30%] w-full address-tab-line'>
                <ul className='flex flex-col gap-3 w-full'>
                  <hr className='w-full h-0.5 bg-[#CECECE] mt-3 lg:hidden'/>
                  <li className='text-sm text-[#000]'>Email</li>
                  <li className='text-[#838383] text-sm'>service@pridetruckcenter.com</li>
                </ul>
              </div>
              <div className='flex  lg:basis-[30%] w-full address-tab-line'>
                <ul className='flex w-full flex-col gap-3'>
                  <hr className='w-full h-0.5 bg-[#CECECE] mt-3 lg:hidden'/>
                  <li className='text-sm text-[#000]'>Phone Number</li>
                  <li className='text-[#838383] text-sm'>(847) 696-6666</li>
                </ul>
              </div>
            </div>
          </>
        }

      </div>
    </div>
  );
};

export default AddressTab;