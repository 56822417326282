import React, { Fragment, useState } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

function Icon({ id, open}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        id === open ? "rotate-180" : ""
      } h-4 w-4 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

export default function MyAccordion({part}) {
  const [open, setOpen] = useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  return (
    <Fragment>
      <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
        <AccordionHeader className='border-b-[#626262] text-base font-normal text-white hover:text-white' onClick={() => handleOpen(1)}>
          General
        </AccordionHeader>
        <AccordionBody className='pb-0'>
          <div className='flex flex-col gap-2.5 text-sm text-white'>
            <div className='flex justify-between'>
              <p className='text-[#9E9E9E]'>Category</p>
              <p>{part.category?.title}</p>
            </div>
            <div  className='flex justify-between'>
              <p className='text-[#9E9E9E]'>Subcategory</p>
              <p>{part.subcategory?.title}</p>
            </div>
            <div  className='flex justify-between'>
              <p className='text-[#9E9E9E]'>Brand</p>
              <p>{part.brand?.title}</p>
            </div>
            <div  className='flex justify-between'>
              <p className='text-[#9E9E9E]'>Year</p>
              <p>1980</p>
            </div>
            <div  className='flex justify-between'>
              <p className='text-[#9E9E9E]'>Condition</p>
              <p>{part.condition}</p>
            </div>
            <div  className='flex justify-between'>
              <p className='text-[#9E9E9E]'>Height</p>
              <p>{part.height}</p>
            </div>
            <div  className='flex justify-between'>
              <p className='text-[#9E9E9E]'>Width</p>
              <p>{part.width}</p>
            </div>
            <div  className='flex justify-between'>
              <p className='text-[#9E9E9E]'>Length</p>
              <p>{part.length}</p>
            </div>
            <div  className='flex justify-between'>
              <p className='text-[#9E9E9E]'>Weight</p>
              <p>{part.weight}</p>
            </div>
          </div>
        </AccordionBody>
      </Accordion>
      <Accordion open={open === 2} icon={<Icon id={2} open={open} />}>
        <AccordionHeader className='border-b-[#626262] text-base font-normal text-white hover:text-white' onClick={() => handleOpen(2)}>
          Cross Reference Numbers
        </AccordionHeader>
        <AccordionBody className='pb-0'>
          <div className='flex flex-col gap-2.5 text-sm text-white'>
            {
              part?.cross_reference_number?.map(el => (
                <div className='flex justify-between'>
                  <p className='text-[#9E9E9E]'>Tile</p>
                  <p>{el.title}</p>
                </div>
              ))
            }
          </div>
        </AccordionBody>
      </Accordion>
      <Accordion open={open === 3} icon={<Icon id={3} open={open} />}>
        <AccordionHeader className='border-b-[#626262] text-base font-normal text-white hover:text-white' onClick={() => handleOpen(3)}>
          Description
        </AccordionHeader>
        <AccordionBody className='text-white text-base pb-0'>
          {part.description}
        </AccordionBody>
      </Accordion>
    </Fragment>
  );
}