import React, {Fragment, useState} from 'react';
import Button from "../components/Button";
import {XMarkIcon} from "@heroicons/react/20/solid";
import {Dialog} from "@material-tailwind/react";
import {useFormik} from "formik";
import {employeeSchema} from "../utils/schema";
import Input from "../components/Input";
import SuccessModal from "./SuccessModal";
import ErrorModal from "./ErrorModal";
import {useDispatch} from "react-redux";
import SelectInput from "../components/SelectInput";
import {inviteUserAction} from "../redux/users/UsersActions";

const InviteEmployeeModal = ({handleOpen, open}) => {

  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const handleSuccessModal = () => setSuccessModal(!successModal)
  const handleErrorModal = () => setErrorModal(!errorModal)

  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      full_name: "",
      email: "",
      role: 'admin',
    },
    validationSchema: employeeSchema,
    onSubmit: (data) => {
      // console.log(data)
      dispatch(inviteUserAction({data, handleSuccessModal, handleErrorModal}))
    }
  });

  const fields = [
    {id: 1, title: 'Full name', name: 'full_name'},
    {id: 2, title: 'E-mail', name: 'email'},
  ]

  const selectField = [
    {id: 1, title: 'Role', name: 'role', arr: [
        {id: 'admin'},
        {id: 'editor'}]},
  ]

  return (
    <>
      <Fragment>
        <Button onClick={handleOpen} variant="gradient">
          Open Dialog
        </Button>
        <Dialog className='p-4' open={open} handler={handleOpen}>
          <button onClick={handleOpen}
                  type="button"
                  className="absolute w-7 top-4 right-4 text-base text-[#C5C5C5] bg-transparent rounded-lg ml-auto inline-flex items-center"
                  data-modal-toggle="defaultModal"
          >
            <XMarkIcon className="h-6 w-6 text-[#C5C5C5]" />
            <span className="sr-only">Close modal</span>
          </button>
          <div>
            <form onSubmit={formik.handleSubmit}>
              <p className='pb-4 pl-1 text-lg text-dark'>Invite employee</p>
              <div className='flex flex-col gap-2.5 relative'>
                {
                  fields.map(field => (
                    <Input key={field.id} field={field} formik={formik}/>
                  ))
                }
                {
                  selectField.map(field => (
                    <SelectInput key={field.id} field={field} formik={formik} arr={field.arr}/>
                  ))
                }
              </div>
              <div className='justify-center flex mt-8'>
                <Button padding='16' type='submit' text='Invite employee' visibility={true}/>
              </div>
            </form>
          </div>
        </Dialog>
      </Fragment>

      {
        successModal && <SuccessModal title='Employee successfully invited' handleOpen={handleSuccessModal} open={successModal}/>
      }

      {
        errorModal && <ErrorModal handleOpen={handleErrorModal} open={errorModal}/>
      }

    </>
  );
};

export default InviteEmployeeModal;