import React, { Component } from "react";
import Slider from "react-slick";
import {ArrowLongLeftIcon, ArrowLongRightIcon} from "@heroicons/react/24/outline";
import img1 from '../../assets/feedback_imgs/img1.jpg'
import img2 from '../../assets/feedback_imgs/img2.jpg'
import img3 from '../../assets/feedback_imgs/img3.jpg'
import img4 from '../../assets/feedback_imgs/img4.jpg'
import img5 from '../../assets/feedback_imgs/img5.png'
import img6 from '../../assets/feedback_imgs/img6.jpg'

function SampleNextArrow({onClick}) {
    return (
        <ArrowLongRightIcon onClick={onClick} className="h-9 w-9 text-[#A80301] lg:top-2/4 -bottom-8 absolute lg:-right-14 right-12 z-20 cursor-pointer" />
    );
}

function SamplePrevArrow({onClick}) {
    return (
        <ArrowLongLeftIcon onClick={onClick} className="h-9 w-9 text-[#A80301] lg:top-2/4 -bottom-8 absolute lg:-left-14 left-12 z-20 cursor-pointer" />
    );
}
const data = [
    {id: 1, img: img2, title: 'Sarah Thompson', comment: '“Pride Truck Center is my go-to place for all my truck maintenance needs. The staff is knowledgeable, friendly, and always goes the extra mile to ensure my satisfaction. I trust them with my truck, and they never disappoint!”'},
    {id: 2, img: img1, title: 'Michael Anderson', comment: '“I couldn\'t be happier with the quality of service I received from Pride Truck Center. Their attention to detail and expertise in truck repairs are unmatched. They exceeded my expectations and delivered exceptional results. Thank you, Pride Truck Center!”'},
    {id: 3, img: img3, title: 'Emily Collins', comment: '“Choosing Pride Truck Center for my truck\'s maintenance was a wise decision. Their professionalism, promptness, and fair pricing make them stand out. I appreciate their commitment.”'},
    {id: 4, img: img4, title: 'Christopher Baker', comment: '“Pride Truck Center saved the day when my truck broke down unexpectedly. Their team quickly diagnosed the issue and had me back on the road in no time. I am grateful for their swift and efficient service. Pride Truck Center is my trusted partner for all my trucking needs.”'},
    {id: 5, img: img5, title: 'Mid Logistics', comment: 'Great experience with Pride Truck Center. Took a truck right away, reasonable price, they fixed everything in less than 2 hours. Communication was also great. Thank you Tracy and guys'},
    {id: 5, img: img6, title: 'Firdavs Yuldashev', comment: 'Brough 2022 thermo king. Engine was not starting. Gary fixed it very fast. Also, got my trailer PM done. Changed fuel filter, air filter, and oil change. Very fast and not expensive. No appointment was required. Walk-ins are welcome'},
]
export default class MySlider extends Component {


    render() {
        const settings = {
            dots: true,
            infinite: true,
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />
        };
        return (
            <div className='lg:w-[37%] h-screen md:w-1/2 w-11/12 m-auto rounded-md sl'>
                <Slider {...settings}>
                    {
                        data.map(el => (
                          <div key={el.id} className='h-80 flex flex-col justify-center items-center p-10 bg-dark m-auto rounded-md'>
                              <img
                                src={el.img}
                                alt="1"
                                className="w-20 h-20 rounded-full object-cover m-auto"
                              />
                              <p className='text-base font-medium text-[#F5F5F5] mb-1.5 mt-3'>{el.title}</p>
                              <p className='text-sm font-normal text-[#C6C6C6]'>{el.comment}</p>
                          </div>
                        ))
                    }

                </Slider>
            </div>
        );
    }
}