import React, {useState} from 'react';
import logo from '../assets/logo.svg'
import Input from "../components/Input";
import {useFormik} from "formik";
import Button from "../components/Button";
import {authSchema} from "../utils/schema";
import {authUser} from "../redux/authAction";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import ErrorModal from "../widgets/ErrorModal";

const Auth = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {error_message} = useSelector(state => state.admin_users)

  const [errorModal, setErrorModal] = useState(false);
  const handleErrorModal = () => setErrorModal(!errorModal)

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: authSchema,
    onSubmit: (values) => {
      dispatch(authUser({values, navigate,handleErrorModal}))
    }})

  const fields = [
    {id: 1, title: 'Email', name: 'email', type: 'text'},
    {id: 2, title: 'Password', name: 'password', type: 'password', password: 'true'},
  ]
  return (
    <>
      <div className='h-screen w-screen bg-dark flex flex-col justify-center'>
        <div className='m-auto lg:w-1/3 md:w-1/2 w-full md:p-0 px-4'>
          <img className='w-50 m-auto mb-14' src={logo} alt="/"/>
          <form onSubmit={formik.handleSubmit} className='bg-white rounded-lg p-4 w-full'>
            <p className='text-xl text-dark mb-7 text-center'>Sign in</p>
            <div className='flex flex-col gap-2.5'>
              {
                fields.map(field => (
                  <Input key={field.id} field={field} formik={formik}/>
                ))
              }
              <div className='m-auto mt-3'>
                <Button padding='8' type='submit' text='Sign In'/>
              </div>

            </div>
          </form>
        </div>
      </div>

      {
        errorModal && <ErrorModal text={error_message} handleOpen={handleErrorModal} open={errorModal}/>
      }
    </>

  );
};

export default Auth;