import React, {Fragment, useState} from 'react';
import Button from "../components/Button";
import {XMarkIcon} from "@heroicons/react/20/solid";
import {Dialog} from "@material-tailwind/react";
import {useFormik} from "formik";
import {serviceSchema} from "../utils/schema";
import Input from "../components/Input";
import {createServiceAction, updateServiceAction} from "../redux/services/ServicesActions";
import SuccessModal from "./SuccessModal";
import ErrorModal from "./ErrorModal";
import {useDispatch} from "react-redux";
import SelectInput from "../components/SelectInput";
import Compressor from "compressorjs";

const UpdateServiceModal = ({handleOpen, open, serviceById}) => {

  const dispatch = useDispatch()

  const [img, setImg] = useState();
  const [logo, setLogo] = useState();

  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const handleSuccessModal = () => setSuccessModal(!successModal)
  const handleErrorModal = () => setErrorModal(!errorModal)

  const selectedImg = (event) => {
    setImg(event.target.files[0]);
    if (event.target.files && event.target.files[0]) {
      formik.setFieldValue('current_img', URL.createObjectURL(event.target.files[0]))
    }
  };

  const selectedLogo = (event) => {
    setLogo(event.target.files[0]);
    if (event.target.files && event.target.files[0]) {
      formik.setFieldValue('current_logo', URL.createObjectURL(event.target.files[0]))
    }
  };

  const formik = useFormik({
    initialValues: {
      name: serviceById.name,
      description: serviceById.description,
      current_img: serviceById.image,
      current_logo: serviceById.logo,
      status: serviceById.status,
      type: serviceById.type,
    },
    enableReinitialize: true,
    validationSchema: serviceSchema,
    onSubmit: (data) => {
      if (img || logo) {
        const form_data = new FormData()
        form_data.append("name", data.name);
        form_data.append("description", data.description);
        form_data.append("type", data.type);

        img && new Compressor(img, {
          quality: 0.2,
          success(result) {
            form_data.append("image", img);
            dispatch(updateServiceAction({id: serviceById.id, data: form_data, withFile: true, handleSuccessModal, handleErrorModal, handleOpen}))
          },
          error(err) {
            console.log(err.message);
          },
        });
        logo && new Compressor(logo, {
          quality: 0.2,
          success(result) {
            form_data.append("logo", logo);
            dispatch(updateServiceAction({id: serviceById.id, data: form_data, withFile: true, handleSuccessModal, handleErrorModal, handleOpen}))
          },
          error(err) {
            console.log(err.message);
          },
        })} else {
        dispatch(updateServiceAction({id: serviceById.id, data: data, withFile: false, handleSuccessModal, handleErrorModal, handleOpen}))
      }
    },
  });

  const fields = [
    {id: 1, title: 'Name', value: formik.values.name, name: 'name'},
    {id: 2, title: 'Definition', value: formik.values.description, name: 'description'},
  ]

   const selectFields = [
     {id: 1, title: 'Status on main page', value: formik.values.status, name: 'status', arr: [{id: 'false', title: 'No'}, {id: 'true', title: 'Yes'}]},
     {id: 2, title: 'Type', value: formik.values.type, name: 'type', arr: [{id: 'Truck Repair', title: 'Truck Repair'}, {id: 'Trailer Repair', title: 'Trailer Repair'}, {id: 'Mobile & Towing', title: 'Mobile & Towing'}, {id: '', title: 'All services'}]},
   ]

  return (
    <>
      <Fragment>
        <Button onClick={handleOpen} variant="gradient">
          Open Dialog
        </Button>
        <Dialog className='p-4' open={open} handler={handleOpen}>
          <button onClick={handleOpen}
                  type="button"
                  className="absolute w-7 top-4 right-4 text-base text-[#C5C5C5] bg-transparent rounded-lg ml-auto inline-flex items-center"
                  data-modal-toggle="defaultModal"
          >
            <XMarkIcon className="h-6 w-6 text-[#C5C5C5]" />
            <span className="sr-only">Close modal</span>
          </button>
          <div>
            <form onSubmit={formik.handleSubmit}>
              <p className='pb-4 pl-1 text-lg text-dark'>Update service</p>
              <div className='flex flex-col gap-2.5 relative'>
                <div className='flex gap-2'>
                  <div className='w-1/3'>
                    <label className='text-[#7B7B7B] text-xs px-2 py-1' htmlFor="">Logo</label>
                    <div className='border border-[#E3E3E3] rounded-md text-[#7B7B7B] text-center h-40 text-sm relative'>
                      {
                        formik.values.current_logo ? <img className='object-cover w-full h-full rounded-md' src={formik.values.current_logo} alt="wrong"/> :
                          <div className='m-auto flex flex-col items-center justify-center h-full cursor-pointer'>
                            <p>SVG, JPG, PNG</p>
                            <p>You can upload logo by</p>
                            <label className='text-[#3989B3] px-3 py-1 underline cursor-pointer z-50' htmlFor='logo'>Clicking here</label>
                          </div>
                      }
                      <label className='absolute w-full h-full top-0 left-0 cursor-pointer' htmlFor="logo"></label>
                      <input accept='.svg, .png, .jpg, .jpeg' onChange={selectedLogo} className='hidden' type="file" id='logo'/>
                    </div>
                  </div>
                  <div className='w-1/3'>
                    <label className='text-[#7B7B7B] text-xs px-2 py-1' htmlFor="">Image</label>
                    <div className='border border-[#E3E3E3] rounded-md text-[#7B7B7B] text-center h-40 text-sm relative'>
                      {
                        formik.values.current_img ? <img className='object-cover w-full h-full rounded-md' src={formik.values.current_img} alt="wrong"/> :
                          <div className='m-auto flex flex-col items-center justify-center h-full cursor-pointer'>
                            <p>JPG, PNG</p>
                            <p>You can upload image by</p>
                            <label className='text-[#3989B3] px-3 py-1 underline cursor-pointer z-50' htmlFor="file">Clicking here</label>
                          </div>
                      }
                      <label className='absolute w-full h-full top-0 left-0 cursor-pointer' htmlFor="file"></label>
                      <input accept='.png, .jpg, .jpeg' onChange={selectedImg} className='hidden' type="file" id='file'/>
                    </div>
                  </div>
                </div>


                {
                  fields.map(field => (
                    <Input key={field.id} field={field} formik={formik}/>
                  ))
                }
                {
                  selectFields.map(field => (
                    <SelectInput arr={field.arr} key={field.id} field={field} formik={formik} />
                  ))
                }
              </div>

              <div className='float-right flex mt-8'>
                <Button padding='16' type='submit' text='Update service' visibility={true}/>
              </div>
            </form>
          </div>
        </Dialog>
      </Fragment>

      {
        successModal && <SuccessModal title='Service updated successfully' text='' handleOpen={handleSuccessModal} open={successModal}/>
      }

      {
        errorModal && <ErrorModal text='Application error' handleOpen={handleErrorModal} open={errorModal}/>
      }

    </>
  );
};

export default UpdateServiceModal;