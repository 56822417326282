import React, {useEffect, useRef, useState} from 'react';
import {ChevronDownIcon, MagnifyingGlassIcon, XMarkIcon} from "@heroicons/react/20/solid";
import {Checkbox, Typography} from "@material-tailwind/react";
import {useDispatch, useSelector} from "react-redux";
import {
  getFilteredPartsAction,
  getPartBrandsAction,
  getPartCategoriesAction, getPartsAction, getLandingSearchedPartsAction,
} from "../redux/parts/partsReducer";
import Button from "../../admin/components/Button";

const Filter = ({count}) => {

  const [filterList, setFilterList] = useState({brand: [], category: [], condition: [], discount: null})
  const [filters, setFilters] = useState([])
  const [chosenCategory, setChosenCategory] = useState([])
  const [chosenBrand, setChosenBrand] = useState([])
  const [chosenCondition, setChosenCondition] = useState([])
  const [chosenDiscount, setChosenDiscount] = useState([])
  const chosenFilters = [...chosenCondition, ...chosenCategory, ...chosenBrand]
  const [searchValue, setSearchValue] = useState('')
  const [priceFilter, setPriceFilter] = useState({min_price: null, max_price: null})

  console.log('filters', filters)
  const onChoosePrice = (e, field) => {
    if(field === 'From ($)'){
      setPriceFilter({...priceFilter, min_price: e.target.value})
    }else{
      setPriceFilter({...priceFilter, max_price: e.target.value})
    }
  }

  const handleFilterByPrice = () => {
    if(priceFilter.max_price && priceFilter.min_price){
      dispatch(getFilteredPartsAction({params: priceFilter, setDropdown5, setPriceFilter}))
      setTimeout(() => {
        setDropdown5(false)
        setPriceFilter({min_price: '', max_price: ''})
      }, 100)
      setFilters([...filters, {option: 'price', name: `${priceFilter.min_price}$ - ${priceFilter.max_price}$`}])
    }
  }

  const handleCLearPriceFilterValues = () => {
    setPriceFilter({min_price: '', max_price: ''})
  }

  const discount_options = [{number: 10, title: 'Up to 10 %'}, {number: 30, title: 'Up to 30 %'}, {number: 50, title: 'Up to 50 %'}, {number: 70, title: 'Up to 70 %'}]

  useEffect(() => {
    searchValue && dispatch(getLandingSearchedPartsAction({searchValue}))
  }, [searchValue])

  const removeFilter = (option, setArr, arr) => {
    setFilterList({...filterList, [option.option]: filterList[option.option].filter(el => el !== option.id)})
    setFilters(filters.filter(filter => filter.name !== option.name))
    setArr(arr.filter(filter => filter !== option.id))
  }

  const removeRadioFilter = (option, setArr) => {
    setFilterList({...filterList, [option.option]: ''})
    setFilters(filters.filter(filter => filter.option !== option.option))
    setArr([])
  }

  const handleRemoveFilter = (option) => {
    switch (option.option) {
      case "brand":
        removeFilter(option, setChosenBrand, chosenBrand)
        break;
      case "condition":
        removeRadioFilter(option, setChosenCondition)
        break;
      case "category":
        removeFilter(option, setChosenCategory, chosenCategory)
        break;
      case "discount":
        removeRadioFilter(option, setChosenDiscount)
        break;
      default:
        dispatch(getFilteredPartsAction({params: priceFilter}))
        setFilters(filters.filter(filter => filter.option !== option.option))
    }
  }
  console.log('filterList: ', filterList)

  const addFiltersFromRadio = (option, name, id, arr, setArr) => {
    const updatedData = [...filters].filter(filter => filter.option !== option)
    setFilters([...updatedData, {option, name: id}])
    setFilterList({...filterList, [option]: name})
    setArr([id])
  }

  const addFilters = (option, name, id, arr, setArr) => {
    const exist_name = filterList[option]?.find(el => el === id)
    if(exist_name){
      setFilterList({...filterList, [option]: filterList[option].filter(el => el !== id)})
      setFilters(filters.filter(filter => filter.name !== name))
      setArr(arr.filter(filter => filter !== id))
    }else{
      setFilterList({...filterList, [option]: [...filterList[option], id]})
      setFilters([...filters, {option: option, name, id}])
      setArr([...arr, id])
    }
  }

  const handleChooseOption = (option, id, name) => {
    switch (option) {
      case "brand":
        addFilters(option, id, name, chosenBrand, setChosenBrand)
        break;
      case "condition":
        addFiltersFromRadio(option, name, name, chosenCondition, setChosenCondition)
        break;
      case "category":
        addFilters(option, id, name, chosenCategory, setChosenCategory)
        break;
      case "discount":
        addFiltersFromRadio(option, id, name, chosenDiscount, setChosenDiscount)
        break;
      default:
        console.log('filter')
    }
  }

  useEffect(() => {
    const filter_stringifies_arr = {}
    Object.entries(filterList).forEach(([key, value]) => {
      filter_stringifies_arr[key] = value?.toString()
    });
    console.log('filter_stringifies_arr:', filter_stringifies_arr)
    dispatch(getFilteredPartsAction({params: filter_stringifies_arr}))
  }, [filterList])

  const dispatch = useDispatch()
  const {categories, brands} = useSelector(state => state.parts)

  console.log('categories: ', categories)
  console.log("brands: ", brands)

  useEffect(() => {
    dispatch(getPartCategoriesAction())
    dispatch(getPartBrandsAction())
  }, [])

  const handleCLearFilters = () => {
    dispatch(getPartsAction())
    setFilters([])
  }

  const [dropdown1, setDropdown1] = useState(false);
  const [dropdown2, setDropdown2] = useState(false);
  const [dropdown3, setDropdown3] = useState(false);
  const [dropdown4, setDropdown4] = useState(false);
  const [dropdown5, setDropdown5] = useState(false);
  const dropdownContainerRef = useRef(null);
  const handleDropdown1 = () => {
    setDropdown1(!dropdown1)
    setDropdown2(false)
    setDropdown3(false)
    setDropdown4(false)
    setDropdown5(false);
  }
  const handleDropdown2 = () => {
    setDropdown2(!dropdown2)
    setDropdown1(false)
    setDropdown3(false)
    setDropdown4(false)
    setDropdown5(false);
  }
  const handleDropdown3 = () => {
    setDropdown3(!dropdown3)
    setDropdown1(false)
    setDropdown2(false)
    setDropdown4(false)
    setDropdown5(false);
  }

  const handleDropdown4 = () => {
    setDropdown4(!dropdown4)
    setDropdown3(false)
    setDropdown1(false)
    setDropdown2(false)
    setDropdown5(false);
  }

  const handleDropdown5 = () => {
    setDropdown5(!dropdown5)
    setDropdown3(false)
    setDropdown1(false)
    setDropdown2(false)
    setDropdown4(false);
  }

  const handleOutsideClick = (event) => {
    if (dropdownContainerRef.current && !dropdownContainerRef.current.contains(event.target)) {
      setDropdown1(false);
      setDropdown2(false);
      setDropdown3(false);
      setDropdown4(false);
      setDropdown5(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const price_fields = ['From ($)','To ($)']

  return (
    <div className='my-4'>
      <div className='mb-2.5 flex justify-between items-baseline'>
        <div className='text-white flex flex-wrap gap-3'>

          <div className='relative lg:flex-initial'>
            <div onClick={handleDropdown1} className='flex gap-1 cursor-pointer justify-center rounded-md border border-[#717171] pr-5 pl-6 py-1.5 w-full md:w-fit relative'>
              <p className='text-sm'>All categories</p>
              <ChevronDownIcon className="h-5 w-5" />
            </div>

            { dropdown1 &&
              <div className='absolute top-10 left-0 z-50 pr-4 bg-[#ECECEC] w-auto overflow-auto flex flex-col rounded-md'>
                {
                  categories.map(category => (
                    <Checkbox checked={chosenCategory.includes(category.id)} onChange={() => handleChooseOption('category', category.title, category.id)} key={category.id} label={
                      <Typography color="blue-gray" className="flex font-medium">
                        {category.title}
                      </Typography>
                    }
                    />
                  ))
                }
              </div>
            }
          </div>

          <div className='relative lg:flex-initial flex-1'>
            <div onClick={handleDropdown2} className='flex gap-1 justify-center cursor-pointer rounded-md border border-[#717171] pr-5 pl-6 py-1.5 w-full sm:w-fit relative'>
              <p className='text-sm'>Brand</p>
              <ChevronDownIcon className="h-5 w-5" />
            </div>

            { dropdown2 &&
              <div className='absolute top-10 left-0 pr-4 z-50 bg-[#ECECEC] w-auto overflow-auto flex flex-col rounded-md'>
                {
                  brands.map(brand => (
                    <Checkbox checked={chosenBrand.includes(brand.id)} onChange={() => handleChooseOption('brand', brand.title, brand.id)} key={brand.id} label={
                      <Typography color="blue-gray" className="flex font-medium">
                        {brand.title}
                      </Typography>
                    }
                    />
                  ))
                }
              </div>
            }
          </div>

          <div className='relative lg:flex-initial lg:flex-1'>
            <div onClick={handleDropdown5} className='flex gap-1 justify-center cursor-pointer rounded-md border border-[#717171] pr-5 pl-6 py-1.5 w-full sm:w-fit relative'>
              <p className='text-sm'>Price</p>
              <ChevronDownIcon className="h-5 w-5" />
            </div>

            { dropdown5 &&
              <div className='absolute top-8 p-4 left-0 w-60 pr-4 z-50 mt-2 bg-[#E0E0E0] overflow-auto flex flex-col rounded-md'>
                <div className='flex w-full gap-2 mb-2'>
                  {
                    price_fields.map(field => (
                      <div className='flex flex-col w-full text-start'>
                        <div key={field.id} className={`flex flex-col bg-[#F9F9F9] border-[#E3E3E3] border-b rounded-t-md`}>
                          <label className='text-[#7B7B7B] text-xs px-3 py-1' htmlFor="">{field}</label>
                          <div className='flex justify-between'>
                            <input onChange={(e) => onChoosePrice(e, field)} placeholder='0' className='border-none w-full text-dark px-3 pb-2 text-base outline-none bg-transparent focus:ring-0'/>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
                <div className='flex gap-2 h-fit w-full'>
                  <li onClick={handleCLearPriceFilterValues} className='w-fit flex items-center gap-1.5 py-1 px-2 cursor-pointer'>
                    <XMarkIcon className="h-5 w-5 text-[#9B9B9B]" />
                    <p className='text-sm text-[#C5C5C5]'>Clear All</p>
                  </li>
                  <Button onClick={handleFilterByPrice} padding='2' text='Done'/>
                </div>
              </div>
            }
          </div>

          <div className='relative lg:flex-initial flex-1'>
            <div onClick={handleDropdown3} className='flex gap-1 justify-center cursor-pointer rounded-md border border-[#717171] pr-5 pl-6 py-1.5 w-full sm:w-fit relative'>
              <p className='text-sm'>Condition</p>
              <ChevronDownIcon className="h-5 w-5" />
            </div>

            { dropdown3 &&
              <div className='absolute top-8 left-0 z-50 mt-2 p-4 bg-[#ECECEC] w-full overflow-auto flex gap-2 flex-col rounded-md'>

                <div className="flex items-center">
                  <input checked={chosenCondition.includes('Used')} onChange={() => handleChooseOption('condition', 'Used', 'Used')} type="radio" id="option1" name="radioGroup"
                         className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"/>
                  <label htmlFor="option1" className="ml-2 text-dark text-base">Used</label>
                </div>

                <div className="flex items-center">
                  <input checked={chosenCondition.includes('New')} onChange={() => handleChooseOption('condition', 'New', 'New')} type="radio" id="option2" name="radioGroup"
                         className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"/>
                  <label htmlFor="option2" className="ml-2 text-dark text-base">New</label>
                </div>
              </div>
            }
          </div>

          <div className='relative lg:flex-initial flex-1'>
            <div onClick={handleDropdown4} className='flex gap-1 justify-center cursor-pointer rounded-md border border-[#717171] pr-5 pl-6 py-1.5 w-full sm:w-fit relative'>
              <p className='text-sm'>Discount</p>
              <ChevronDownIcon className="h-5 w-5" />
            </div>

            { dropdown4 &&
              <div className="space-y-4 absolute top-10 w-40 left-0 bg-[#ECECEC] z-50 p-4 rounded-md">
                {
                  discount_options.map(el => (
                    <div className="flex items-center">
                      <input onChange={() => handleChooseOption('discount', el.number, el.title)} type="radio" id={el.title} name="radioGroup"
                             className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"/>
                      <label htmlFor={el.title} className="ml-2 text-dark text-base">{el.title}</label>
                    </div>
                  ))}
              </div>
            }
          </div>


          <div className='md:flex md:block hidden items-center w-80 gap-2 rounded-md border border-[#717171] px-5 py-1'>
            <input onChange={(e) => setSearchValue(e.target.value)} className='w-full bg-transparent' type="text" placeholder='Search'/>
            <MagnifyingGlassIcon className="h-6 w-6 text-gray-500" />
          </div>

        </div>

        <p className='text-sm text-[#9B9B9B] hidden md:block'>Total: {count}</p>

      </div>

      <ul className='flex gap-2 w-full flex-wrap'>
        {filters?.map(option => (
          <li className='rounded-md bg-[#C5C5C5] w-fit flex items-center gap-1.5 py-1 px-2'>
            <XMarkIcon onClick={() => handleRemoveFilter(option)} className="h-5 w-5 text-[#9B9B9B] cursor-pointer" />
            <p className='text-sm text-dark'>{option.name}</p>
          </li>
        ))}
        <li onClick={handleCLearFilters} className='w-fit flex items-center gap-1.5 py-1 px-2 cursor-pointer'>
          <XMarkIcon className="h-5 w-5 text-[#9B9B9B]" />
          <p className='text-sm text-[#C5C5C5]'>Clear All</p>
        </li>
      </ul>
    </div>

  );
};

export default Filter;