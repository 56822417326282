import React, {useEffect, useState} from 'react';
import Navbar from "../../components/Sidebar";
import PartsList from "./PartsList";
import Button from "../../components/Button";
import {ArrowDownTrayIcon, MagnifyingGlassIcon} from "@heroicons/react/24/outline";
import {Link} from "react-router-dom";
import ExportToExcel from "./ExportToExcel";
import {useDispatch, useSelector} from "react-redux";
import {
  deleteMultiplePartsAction,
  getSearchedPartsAction,
  ImportDataToPartsAction
} from "../../redux/parts/PartsActions";
import delete_icon from "../../assets/delete.svg";
import Filter from "./Filter";
import ErrorModal from "../../widgets/ErrorModal";

const AdminParts = () => {
  const dispatch = useDispatch()

  const [exportData, setExportData] = useState([])
  const [deleteMultipleData, setDeleteMultipleData] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [importedData, setImportedData] = useState()
  const {parts, error} = useSelector(state => state.admin_parts)
  const [errorModal, setErrorModal] = useState(false);

  const handleErrorModal = () => setErrorModal(!errorModal)

  useEffect(() => {
    searchValue && dispatch(getSearchedPartsAction({searchValue}))
  }, [searchValue])

  const ImportData = () => {
    if(importedData) {
      const fData = new FormData()
      fData.append('file', importedData)
      dispatch(ImportDataToPartsAction({fData, handleErrorModal, setImportedData}))
    }
  }

  const handleDeleteMultipleParts = () => {
    if(deleteMultipleData) {
      const data = JSON.stringify(deleteMultipleData)
      dispatch(deleteMultiplePartsAction({data}))
    }
  }

  const onChooseImportedData = (e) => {
    setImportedData(e.target.files[0])
  }

  const onChooseExportData = (data) => {
    if(exportData.includes(data)){
      const updatedData = [...exportData];
      const updatedDeleteData = [...deleteMultipleData];
      exportData.forEach((el, index) => el === data && updatedData.splice(index, 1))
      deleteMultipleData.forEach((el, index) => el === data.id && updatedDeleteData.splice(index, 1))
      setExportData(updatedData);
      setDeleteMultipleData(updatedDeleteData);
    }else {
      setExportData([...exportData, data])
      setDeleteMultipleData([...deleteMultipleData, data.id])
    }
  }


  return (
    <>
      <div className='h-screen md:flex'>
      <Navbar/>
      <div className='w-full h-screen bg-background'>
        <div className='xl:container xl:m-auto pt-7 px-6'>
          <div className='flex flex-wrap justify-between w-full mb-4'>
            <p className='text-xl font-medium'>
              Parts
            </p>
            <div className='flex items-center gap-4 mt-2 md:mt-0'>
              <div className=''>
                <label className={`border rounded-lg min-w-60 text-sm md:text-base border-[#A3A3A3] p-2 cursor-pointer ${importedData ? 'bg-blue-50 text-dark' : 'bg-transparent text-[#A3A3A3]'}`} htmlFor="importFile">
                  {
                    importedData ? importedData.name : 'Browse Import Templates'
                  }
                </label>
                <input  onChange={onChooseImportedData} className='hidden' accept=".xlsx" type="file" id='importFile'/>
              </div>
              <Link to='/admin/parts/create_part'><Button padding='4' type='button' text='Create part'/></Link>
            </div>
          </div>

          <div className='flex flex-wrap justify-between items-center mb-5'>
            <div className='flex w-full justify-between items-center md:w-2/5 gap-2 rounded-md border border-[#C5C5C5] px-5 py-1.5'>
              <input onChange={(e) => setSearchValue(e.target.value)} className='md:w-full w-fit bg-transparent outline-none' type="text" placeholder='Search'/>
              <MagnifyingGlassIcon className="h-6 w-6 text-[#C5C5C5]" />
            </div>
            <div className='flex flex-wrap mt-2 lg:mt-0 gap-2 text-[#585858]'>
              <button onClick={handleDeleteMultipleParts} className='flex gap-2 bg-[#F2F2F2] items-center rounded-3xl px-4 py-1.5'>
                <p className='font-medium text-sm'>Delete chosen parts</p>
                <img className="h-6 w-6 text-dark cursor-pointer" src={delete_icon} alt="/"/>
              </button>
              <ExportToExcel apiData={exportData} fileName='myExcelFile'/>
              <button className={`flex gap-2 items-center rounded-3xl px-4 py-1.5 ${importedData ? 'bg-blue-50' : 'bg-[#F2F2F2]'}`} onClick={ImportData}>
                <p className='font-medium text-sm'>import</p>
                <ArrowDownTrayIcon className="h-4 w-4" />
              </button>
            </div>
          </div>

          <Filter count={parts?.count}/>

          <PartsList onChooseExportData={onChooseExportData}/>
        </div>
      </div>
      </div>
      {
        errorModal && <ErrorModal text={typeof error === 'object' ? Object.values(error)[0] : error} handleOpen={handleErrorModal} open={errorModal}/>
      }
    </>
  );
};

export default AdminParts;