import React, {useEffect, useRef, useState} from 'react';
import logo from '../assets/logo.svg'
import x from '../assets/icons/x.svg'
import instagram from '../assets/icons/instagram.svg'
import facebook from '../assets/icons/facebook.svg'
import telegram from '../assets/icons/telegram.svg'
import {Bars3Icon, ChevronRightIcon} from "@heroicons/react/20/solid";
import Button from "./Button";
import {Link, useParams} from "react-router-dom";
import ModalForGetAConsultation from "../widgets/ModalForGetAConsultation";
import {useDispatch, useSelector} from "react-redux";
import {
  getServiceByIdAction,
  getServiceByTruckTypeAction,
} from "../redux/services/servicesReducer";

const Header = () => {
  const dispatch = useDispatch()
  const {truckRepairServices, trailerRepairServices, mobileTowingServices} = useSelector(state => state.services)

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getServiceByTruckTypeAction())
  }, [dispatch])

  const pathName = window.location.pathname

  const [open, setOpen] = useState(false)

  const handleModal = () => setOpen(!open)
  const {id} = useParams()

  const handleGetServiceById = (serviceId) => {
    handleCloseNavbar()
    id !== serviceId && dispatch(getServiceByIdAction({id: serviceId}))
  }

  const [openNavbar, setOpenNavbar] = useState(false);
  const [dropdown1, setDropdown1] = useState(false);
  const [dropdown2, setDropdown2] = useState(false);
  const [dropdown3, setDropdown3] = useState(false);
  const [dropdown4, setDropdown4] = useState(false);
  const dropdownContainerRef = useRef(null);

  const handleOpenNavbar = () => {
    setOpenNavbar(true)
    document.body.style.overflowY = "hidden"
  }

  const handleCloseNavbar = () => {
    setOpenNavbar(false)
    document.body.style.overflowY = "scroll"
  }

  const handleDropdown1 = () => {
    setDropdown1(!dropdown1)
    setDropdown2(false)
    setDropdown3(false)
    setDropdown4(false)
  }
  const handleDropdown2 = () => {
    setDropdown2(!dropdown2)
    setDropdown1(false)
    setDropdown3(false)
    setDropdown4(false)

  }
  const handleDropdown3 = () => {
    setDropdown3(!dropdown3)
    setDropdown1(false)
    setDropdown2(false)
    setDropdown4(false)
  }

  const handleDropdown4 = () => {
    setDropdown4(!dropdown4)
    setDropdown3(false)
    setDropdown1(false)
    setDropdown2(false)
  }

  const handleOutsideClick = (event) => {
    if (dropdownContainerRef.current && !dropdownContainerRef.current.contains(event.target)) {
      setDropdown1(false);
      setDropdown2(false);
      setDropdown3(false);
      setDropdown4(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);


    const [scroll, setScroll] = useState(false);

    useEffect(() => {
      const handleScroll = () => {
        const isScrolled = window.scrollY > 0;
        if (isScrolled !== scroll) {
          setScroll(isScrolled);
        }
      };

      document.addEventListener('scroll', handleScroll);
      return () => {
        document.removeEventListener('scroll', handleScroll);
      };
    }, [scroll]);


    return (
    <header className={`${pathName === '/' ? 'fixed' : 'static'} z-50 w-full transition duration-1000 ${scroll && pathName !== '/home' ? 'bg-white fixed' : 'bg-transparent'} ${scroll && pathName === '/home' ? 'bg-white' : 'bg-transparent'}`}>
        <nav className='xl:container z-20 py-2 lg:py-1 lg:px-0 mx-auto flex flex-wrap items-center lg:justify-between relative'>

          <img className='inline-flex pl-4 xl:pl-0' src={logo} alt="/"/>
          <button className='xl:hidden ml-auto absolute top-3 right-4' onClick={handleOpenNavbar}>
            <Bars3Icon className={`h-7 w-7 ${pathName !== '/' ? 'text-[#313131]' : 'text-white' } ${scroll && 'text-[#313131]'} `} />
          </button>
          <div className={!openNavbar ? 'xl:inline-flex xl:w-auto w-8 h-screen xl:h-full xl:mt-0 xl:opacity-100 opacity-0 xl:static absolute top-0 transition-all ease-in duration-500' : 'w-full overflow-y-auto h-screen px-4 bg-white absolute right-0 top-0 transition-all ease-in duration-500'}>
            <img className='flex justify-center items-center m-auto w-40 mb-6 mt-14 xl:hidden visible' src={logo} alt="/"/>
            <button className='xl:hidden ml-auto absolute top-4 right-4' onClick={handleCloseNavbar}>
              <img className='w-5' src={x} alt="/"/>
            </button>
            <ul className={`flex flex-col transition duration-1000 ${scroll || pathName !== '/' ? 'xl:text-dark' : 'xl:text-[#F7F7F7]'} text-dark xl:flex-row xl:items-center mb-7 xl:mb-0`} ref={dropdownContainerRef}>
              {
                pathName !== '/' && <>
                  <Link onClick={() => document.body.style.overflowY = "scroll"} to='/'><li className='xl:font-normal font-medium xl:px-4 text-base leading-5 flex items-center border-b xl:border-transparent py-4 cursor-pointer hover:text-red'>Home</li></Link>
                </>
              }
              <li className='relative'>
                <button onClick={handleDropdown1} className={dropdown1 ? "w-full xl:h-10 xl:px-3 text-md flex justify-between items-center border-b py-4 xl:justify-center xl:border-white text-red xl:border-b-red" : 'xl:font-normal font-medium w-full xl:h-10 xl:px-3 text-ьв flex justify-between items-center border-b py-4 xl:justify-center xl:border-transparent hover:text-red'}>
                  <p>Truck Repair</p>
                  <ChevronRightIcon className={!dropdown1 ? "h-4 w-7 mt-0.5 xl:rotate-90" : "h-4 w-7 mt-0.5 rotate-90"} />
                </button>
                {
                  dropdown1 && <div className={`xl:absolute border-b text-dark xl:border-b-white bg-white left-0 transition duration-1000  ${scroll && pathName === '/' ? 'xl:rounded-b-xl' : 'xl:rounded-xl'} py-4 xl:mt-2 xl:pl-4 xl:pr-3`}>
                    <ul className='xl:w-40 flex flex-col gap-4'>
                      {
                        truckRepairServices?.map(service => (
                          <Link to={`/services/${service.id}`}><li onClick={() => handleGetServiceById(service.id)} key={service.id} className='hover:text-[#D4110E] hover:font-medium cursor-pointer text-sm leading-4 xl:text-base'>{service.name}</li></Link>
                        ))
                      }</ul>
                  </div>
                }
              </li>
              <li className='relative'>
                <button onClick={handleDropdown2} className={dropdown2 ? "w-full xl:h-10 xl:px-3 text-base flex justify-between items-center border-b py-4 xl:justify-center xl:border-white text-red xl:border-b-red" : 'xl:font-normal font-medium w-full xl:h-10 xl:px-3 text-base flex justify-between items-center border-b py-4 xl:justify-center xl:border-transparent hover:text-red'}>
                  <p>Trailer Repair</p>
                  <ChevronRightIcon className={!dropdown2 ? "h-4 w-7 mt-0.5 xl:rotate-90" : "h-4 w-7 mt-0.5 rotate-90"} />
                </button>
                {
                  dropdown2 && <div className={`xl:absolute border-b text-dark xl:border-b-white bg-white left-0 transition duration-1000  ${scroll && pathName === '/' ? 'xl:rounded-b-xl' : 'xl:rounded-xl'} py-4 xl:mt-2 xl:pl-4 xl:pr-3`}>
                    <ul className='xl:w-44 flex flex-col gap-4'>
                      {
                        trailerRepairServices?.map(service => (
                          <Link to={`/services/${service.id}`}><li onClick={() => handleGetServiceById(service.id)} key={service.id} className='hover:text-[#D4110E] hover:font-medium cursor-pointer text-sm leading-4 xl:text-base'>{service.name}</li></Link>
                        ))
                      }</ul>
                  </div>
                }

              </li>
              <li className='relative'>
                <button onClick={handleDropdown3} className={dropdown3 ? "w-full xl:h-10 xl:px-3 text-base flex justify-between items-center border-b py-4 xl:justify-center xl:border-white text-red xl:border-b-red" : 'xl:font-normal font-medium w-full xl:h-10 xl:px-3 text-base flex justify-between items-center border-b py-4 xl:justify-center xl:border-transparent hover:text-red'}>
                  <p>Mobile & Towing</p>
                  <ChevronRightIcon className={!dropdown3 ? "h-4 w-7 mt-0.5 xl:rotate-90" : "h-4 w-7 mt-0.5 rotate-90"} />
                </button>
                {
                  dropdown3 && <div className={`xl:absolute border-b text-dark xl:border-b-white bg-white left-0 transition duration-1000  ${scroll && pathName === '/' ? 'xl:rounded-b-xl' : 'xl:rounded-xl'} py-4 xl:mt-2 xl:pl-4 xl:pr-3`}>
                    <ul className='xl:w-36 flex flex-col gap-4'>
                      {
                        mobileTowingServices?.map(service => (
                          <Link to={`/services/${service.id}`}><li onClick={() => handleGetServiceById(service.id)} key={service.id} className='hover:text-[#D4110E] hover:font-medium cursor-pointer text-sm leading-4 xl:text-base'>{service.name}</li></Link>
                        ))
                      }</ul>
                  </div>
                }
              </li>
              <li onClick={() => document.body.style.overflowY = "scroll"} className='xl:font-normal font-medium xl:px-4 text-base leading-5 flex items-center border-b xl:border-transparent py-4 cursor-pointer hover:text-red'><Link to='/parts'>Parts</Link></li>
              {
                pathName === '/' && <>
                  <li className='xl:font-normal font-medium xl:px-4 text-base leading-5 flex items-center border-b xl:border-transparent py-4 cursor-pointer hover:text-red'><a href='#our_partners'>Partners</a></li>
                  <li className='xl:font-normal font-medium xl:px-4 text-base leading-5 flex items-center border-b xl:border-transparent py-4 cursor-pointer hover:text-red'><a href='#testimonials'>Testimonials</a></li>
                </>
              }

              <li className='relative'>
                <button onClick={handleDropdown4} className={dropdown4 ? "w-full xl:h-10 xl:px-3 text-base flex justify-between items-center border-b py-4 xl:justify-center xl:border-white text-red xl:border-b-red" : 'xl:font-normal font-medium w-full xl:h-10 xl:px-3 text-base flex justify-between items-center border-b py-4 xl:justify-center xl:border-transparent hover:text-red'}>
                  <p>Service Centers</p>
                  <ChevronRightIcon className={!dropdown4 ? "h-4 w-7 mt-0.5 xl:rotate-90" : "h-4 w-7 mt-0.5 rotate-90"} />
                </button>
                {
                  dropdown4 && <div className={`xl:absolute w-full xl:w-fit border-b xl:border-b-white bg-white -left-32 transition duration-1000  ${scroll && pathName === '/' ? 'xl:rounded-b-xl' : 'xl:rounded-xl'} py-4 xl:mt-2 xl:pl-4`}>
                    <ul className='flex flex-col gap-4 xl:w-72'>
                      <li className='hover:text-red cursor-pointer group'>
                        <p className='font-medium text-sm leading-4 mb-0.5 xl:text-base text-dark group-hover:text-[#D4110E]'>Diesel Engine Repair</p>
                        <p className='text-sm leading-4 text-[#AAAAAA] group-hover:text-dark'>210 Flannigan Rd, Hampshire, IL 60140</p>
                      </li>
                      <li className='hover:text-red cursor-pointer group'>
                        <p className='font-medium text-sm leading-4 mb-0.5 xl:text-base text-dark group-hover:text-[#D4110E]'>DOT Inspection</p>
                        <p className='text-sm leading-4 text-[#AAAAAA] group-hover:text-dark'>210 Flannigan Rd, Hampshire, IL 60140</p>
                      </li>
                    </ul>
                  </div>
                }
              </li>
              <li className='xl:pl-4 lg:block hidden'>
                {
                  pathName !== '/' && <Button onClick={handleModal} visibility={true} text='Schedule for service'/>
                }
              </li>
            </ul>
            <Button onClick={handleModal} visibility={false} text='Schedule for service'/>
            <div className='xl:hidden flex justify-center gap-6 mt-28 mb-10'>
              <img src={instagram} alt="/"/>
              <img src={facebook} alt="/"/>
              <img src={telegram} alt="/"/>
            </div>
          </div>
        </nav>
      {
        open && <ModalForGetAConsultation open={open} handleOpen={handleModal}/>
      }
   </header>
  );
};

export default Header;
