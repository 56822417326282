import React, {useState} from 'react';
import Navbar from "../../components/Sidebar";
import {ServicesList} from "./ServicesList";
import Button from "../../components/Button";
import CreateServiceModal from "../../widgets/CreateServiceModal";

const AdminServices = () => {

  const [openModal, setOpenModal] = useState(false)

  const toggleOpenModal = () => setOpenModal(!openModal)

  return (
    <>
      <div className='md:flex h-screen'>
        <Navbar/>
        <div className='w-full h-screen bg-background'>
          <div className='container m-auto pt-7 px-6'>
            <div className='lg:flex flex-col lg:flex-row justify-between w-full mb-4'>
              <p className='text-xl font-medium mb-2 lg:mb-0'>
                Services
              </p>
              <Button onClick={toggleOpenModal} padding='4' type='button' text='Create service'/>
            </div>
            <ServicesList/>
          </div>
        </div>
      </div>

      {
        openModal && <CreateServiceModal open={openModal} handleOpen={toggleOpenModal}/>
      }
    </>
  );
};

export default AdminServices;