import React, {useState} from 'react';
import {ExclamationCircleIcon, EyeIcon, EyeSlashIcon} from "@heroicons/react/20/solid";

const Input = ({formik, field}) => {

  const [showPassword, setShowPassword] = useState(false)
  const handleShowPassword = () => setShowPassword(!showPassword)

  return (
    <div className='flex flex-col w-full text-start'>
      <div onChange={formik.handleChange} key={field.id} className={`flex flex-col ${formik.errors[field.name] && formik.touched[field.name] ? 'bg-[#FCF5F4] border-[#A80301]' : 'bg-[#F9F9F9] border-[#E3E3E3]'} border-b rounded-t-md`}>
        <label className='text-[#7B7B7B] text-xs px-3 py-1' htmlFor="">{field.title}</label>
        <div className='flex justify-between'>
          <input value={field.value} type={showPassword ? 'text' : field.type} name={field.name} className='border-none w-full text-dark px-3 pb-2 text-base outline-none bg-transparent focus:ring-0'/>
          {
            field.password && <>
              {
                showPassword ? <EyeIcon onClick={handleShowPassword} className="h-6 w-6 text-[#C5C5C5] mr-3 cursor-pointer" /> : <EyeSlashIcon onClick={handleShowPassword} className="h-6 w-6 text-[#C5C5C5] mr-3 cursor-pointer" />
              }
            </>
          }
        </div>
      </div>
      {formik.errors[field.name] && formik.touched[field.name] ? <div className='text-[#A80301] text-xs flex gap-1 mt-1 ml-3'>
        <ExclamationCircleIcon className="h-4 w-4" />{formik.errors[field.name]}
      </div> : null}
    </div>
  );
};

export default Input;