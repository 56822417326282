import {Card, Typography} from "@material-tailwind/react";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {PencilIcon} from "@heroicons/react/20/solid";
import {blockUserAction, deleteUserAction, getUsersAction} from "../../redux/users/UsersActions";
import EditEmployeeModal from "../../widgets/EditEmployeeModal";
import delete_icon from "../../assets/delete.svg";
import lock_icon from "../../assets/lock.svg";
import active_lock_icon from "../../assets/active_lock.svg";
import useWindowDimensions from "../../utils/GetWindowDimantions";

const TABLE_HEAD = ["Name", "Role", "Phone", "E-mail", "Actions"];

export function EmployeesList() {

  const {windowWidth} = useWindowDimensions()

  const [openDeleteModal, setOpenDeleteModel] = useState({id: null, opened: false})
  const [openLockModal, setOpenLockModel] = useState({id: null, opened: false})
  const [openEditModal, setOpenEditModel] = useState(false)
  const [id, setId] = useState()

  const toggleOpenEditModal = (id) => {
    setId(id)
    setOpenEditModel(!openEditModal)
  }

  const handleOpenDeleteModal = (id) => {
    setOpenDeleteModel({id, opened: !openDeleteModal.opened})
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModel({id: openDeleteModal.id, opened: !openDeleteModal.opened})
  }

  const handleDeleteEmployee = (id) => {
    dispatch(deleteUserAction({id, handleCloseDeleteModal}))
  }

  //  lock
  const handleOpenLockModal = (id) => {
    setOpenLockModel({id, opened: !openLockModal.opened})
  }

  const handleCloseLockModal = () => {
    setOpenLockModel({id: openLockModal.id, opened: !openLockModal.opened})
  }

  const handleLockEmployee = (id) => {
    dispatch(blockUserAction({id, handleCloseLockModal}))
  }


  const dispatch = useDispatch()
  const {services} = useSelector(state => state.admin_services)
  const {users, loading} = useSelector(state => state.admin_users)

  useEffect(() => {
    !users.length && dispatch(getUsersAction())
  }, [dispatch, users.length])

  return (
    <>
      <Card className="w-full md:h-[85vh] h-[73vh] overflow-scroll">
        <table className="w-full min-w-max table-auto text-left">
          {
            windowWidth > 1000 &&
            <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th key={head} className="border-b border-blue-gray-100 p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-medium text-start leading-none text-dark"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
            </thead>
          }
          <tbody>
          {users.map(({full_name, role, email, id, is_active }, index) => {
            const isLast = index === services.length - 1;
            const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

            return (
              <>
                <tr className='relative' key={email}>
                  <td className={classes} style={windowWidth < 1000 ? {display: 'flex', justifyContent: 'space-between', border: 'none', paddingTop: '16px', paddingBottom: '2px'} : null}>
                  {
                    windowWidth < 1000 &&
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-medium text-start leading-none text-dark"
                    >
                      Name
                    </Typography>
                  }
                    <Typography variant="small" color="blue-gray" className="font-normal">
                      {full_name}
                    </Typography>
                  </td>
                  <td className={classes} style={windowWidth < 1000 ? {display: 'flex', justifyContent: 'space-between', border: 'none', paddingTop: '2px', paddingBottom: '2px'} : null}>
                  {
                    windowWidth < 1000 &&
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-medium text-start leading-none text-dark"
                    >
                      Role
                    </Typography>
                  }
                    <Typography variant="small" color="blue-gray" className="font-normal">
                      {role}
                    </Typography>
                  </td>
                  <td className={classes} style={windowWidth < 1000 ? {display: 'flex', justifyContent: 'space-between', border: 'none', paddingTop: '2px', paddingBottom: '2px'} : null}>
                  {
                    windowWidth < 1000 &&
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-medium text-start leading-none text-dark"
                    >
                      Phone number
                    </Typography>
                  }
                    <Typography variant="small" color="blue-gray" className="font-normal">
                      (603) 555-0123
                    </Typography>
                  </td>
                  <td className={classes} style={windowWidth < 1000 ? {display: 'flex', justifyContent: 'space-between', border: 'none', paddingTop: '2px', paddingBottom: '2px'} : null}>
                  {
                    windowWidth < 1000 &&
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-medium text-start leading-none text-dark"
                    >
                      E-mail
                    </Typography>
                  }
                    <Typography variant="small" color="blue-gray" className="font-normal">
                      {email}
                    </Typography>
                  </td>
                  <td className={classes} style={windowWidth < 1000 ? {display: 'flex', justifyContent: 'space-between', border: 'none', paddingTop: '2px', paddingBottom: '2px'} : null}>
                    <div className="font-medium flex gap-2">
                      <div className='relative'>
                        {
                          is_active ? <img onClick={() => handleOpenLockModal(id)} className="h-6 w-6 text-gray-400 cursor-pointer" src={lock_icon} alt="/"/> :
                            <img className="h-6 w-6 text-gray-400" src={active_lock_icon} alt="/"/>
                        }
                        {
                          openLockModal.id === id && openLockModal.opened && <div className='p-2 w-96 rounded-lg bg-white z-10 border border-[#C5C5C5] absolute top-0 right-6'>
                            <p className='w-fit text-base'>
                              Are you sure you want to block this user?
                            </p>
                            <div className='flex text-white gap-1.5 mt-2 float-right'>
                              <button onClick={() => handleLockEmployee(id)} className='bg-[#C5C5C5] px-5 py-1.5 rounded-md'>Yes</button>
                              <button onClick={handleCloseLockModal} className='bg-[#C5C5C5] px-5 py-1.5 rounded-md'>No</button>
                            </div>
                          </div>
                        }
                      </div>
                      <PencilIcon onClick={() => toggleOpenEditModal(id)} className="h-6 w-6 text-[#C5C5C5] cursor-pointer"/>
                      <div className='relative'>
                        <img onClick={() => handleOpenDeleteModal(id)} className="h-6 w-6 text-gray-400 cursor-pointer" src={delete_icon} alt="/"/>
                        {
                          openDeleteModal.id === id && openDeleteModal.opened && <div className='p-2 w-96 rounded-lg bg-white z-10 border border-[#C5C5C5] absolute top-0 right-6'>
                            <p className='w-fit text-base'>
                              Are you sure you want to delete this message?
                            </p>
                            <div className='flex text-white gap-1.5 mt-2 float-right'>
                              <button onClick={() => handleDeleteEmployee(id)} className='bg-[#C5C5C5] px-5 py-1.5 rounded-md'>Yes</button>
                              <button onClick={handleCloseDeleteModal} className='bg-[#C5C5C5] px-5 py-1.5 rounded-md'>No</button>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </td>
                </tr>
                {
                  windowWidth < 1000 && <hr className='mt-2'/>
                }
              </>
            );
          })}
          </tbody>
        </table>
      </Card>
      {
        openEditModal && <EditEmployeeModal id={id} open={openEditModal} handleOpen={toggleOpenEditModal}/>
      }
    </>

  );
}