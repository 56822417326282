import React, {Fragment} from 'react';
import error from "../assets/error.svg";
import Button from "../components/Button";
import {XMarkIcon} from "@heroicons/react/20/solid";
import {Dialog} from "@material-tailwind/react";

const ErrorModal = ({handleOpen, open, text}) => {
  return (
    <Fragment>
      <Button onClick={handleOpen} variant="gradient">
        Open Dialog
      </Button>
      <Dialog className='p-4' open={open} handler={handleOpen}>
        <button onClick={handleOpen}
                type="button"
                className="absolute w-7 top-4 right-4 text-base text-[#C5C5C5] bg-transparent rounded-lg ml-auto inline-flex items-center"
                data-modal-toggle="defaultModal"
        >
          <XMarkIcon className="h-6 w-6 text-[#C5C5C5]" />
          <span className="sr-only">Close modal</span>
        </button>
    <div className='flex flex-col gap-4 justify-center items-center text-center py-16'>
      <img className='w-[70px] mb-1' src={error} alt="/"/>
      <p className='text-lg font-medium text-dark'>{text?.charAt(0).toUpperCase() + text?.slice(1)}</p>
      <p className='text-sm text-[#AAA]'>Please try again.</p>
    </div>
      </Dialog>
    </Fragment>
  );
};

export default ErrorModal;