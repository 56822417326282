import React from "react";
import {
  Routes,
  Route, BrowserRouter, Navigate, useLocation
} from "react-router-dom";
import Main from "./landing_page/pages/Main";
import Services from "./landing_page/pages/Services";
import Parts from "./landing_page/pages/parts";
import PartDetails from "./landing_page/pages/partdetails/PartDetails";
import ServiceDetails from "./landing_page/pages/ServiceDetails";
import Auth from "./admin/pages/Auth";
import CreatePart from "./admin/pages/parts/CreatePart";
import UpdatePart from "./admin/pages/parts/UpdatePart";
import Employees from "./admin/pages/employees";
import ResetPassword from "./admin/pages/ResetPassword";
import AdminServices from "./admin/pages/services";
import AdminParts from "./admin/pages/parts";
import HealthCheck from "./admin/pages/HealthCheck";

export const App = () => {

  const private_routes = [
    {link: '/admin/services', element: <AdminServices/>},
    {link: '/admin/parts', element: <AdminParts/>},
    {link: '/admin/parts/create_part', element: <CreatePart/>},
    {link: '/admin/parts/:id', element: <UpdatePart/>},
    {link: '/admin/employees', element: <Employees/>},
  ]

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/healthcheck" element={<HealthCheck />} />
        <Route path="/services" element={<Services />} />
        <Route path="/services/:id" element={<ServiceDetails />} />
        <Route path="/parts" element={<Parts />} />
        <Route path="/parts/:id" element={<PartDetails />} />

      {/*  admin*/}
        <Route path="/admin" element={<Auth />} />
        <Route path="/password/reset/confirm/*" element={<ResetPassword />} />
        {
          private_routes.map(route => (
            <Route
              path={route.link}
              element={
                <PrivateRoute>
                  {route.element}
                </PrivateRoute>
              }
            />
          ))
        }
      </Routes>
    </BrowserRouter>
  );
};


const PrivateRoute = ({ children }) => {
  const authed = localStorage.getItem('access')
  return authed ? children : <Navigate to="/admin" />;
}

