import React, {Fragment} from 'react';
import {XMarkIcon} from "@heroicons/react/20/solid";
import {Dialog} from "@material-tailwind/react";

const PageLinkCopyModal = ({handleOpen, open}) => {

  const url = window.location.href;

  console.log("ff: ", url)

  return (
    <div>
      <Fragment>
        <Dialog size='md' dismiss={{outsidePress: false}} className='p-5' open={open} handler={handleOpen}>
          <button onClick={handleOpen}
                  type="button"
                  className="absolute w-7 top-1 right-1 md:top-4 md:right-4 text-base text-red bg-transparent rounded-lg ml-auto inline-flex items-center"
                  data-modal-toggle="defaultModal"
          >
            <XMarkIcon className="h-6 w-6 text-[#C5C5C5]" />
            <span className="sr-only">Close modal</span>
          </button>
          <div className='m-0 mr-4 md:my-8 md:mx-8 flex justify-between items-center md:gap-4 gap-2'>
            <p className='border rounded font-bold text-[9px] md:text-md p-2 bg-blue-50 w-full'>{url}</p>
            <button className='bg-white h-full border border-gray-300 font-medium rounded text-[9px] md:text-md px-2 md:px-4 py-1.5 text-blue-500 hover:bg-gray-200' onClick={() =>  navigator.clipboard.writeText(url)}>Copy</button>
          </div>
        </Dialog>
      </Fragment>
    </div>
  );
};

export default PageLinkCopyModal;