import React from 'react';
import {Link, useLocation} from "react-router-dom";
import {ChevronRightIcon} from "@heroicons/react/20/solid";
import {useDispatch} from "react-redux";

const Breadcrumbs = ({name}) => {

  const location = useLocation()
  let currentLink = ''
  const linkList = location.pathname.split('/').filter(crumb => crumb !== '')

  const crumbs = location.pathname.split('/')
    .slice(2)
    .filter(crumb => crumb !== '')
    .map(crumb => {
      currentLink += `/${crumb}`

      return (
          <div className="flex items-center" key={crumb}>
            <Link className={`text-base font-medium ${linkList.indexOf(crumb) + 1 !== linkList.length ? "text-dark" : "text-[#949494] cursor-default"} hover:text-[#949494] md:mr-2`} to={`/admin${currentLink}`}>{!isNaN(+crumb) ? (name?.charAt(0).toUpperCase() + name?.slice(1)) : crumb?.charAt(0).toUpperCase() + crumb?.slice(1)}</Link>
            <ChevronRightIcon className={linkList.indexOf(crumb) + 1 !== linkList.length ? "h-5 w-5 text-dark mr-2" : 'hidden'} />
          </div>
      )
    })

  return (
    <div className='inline-flex'>
      {crumbs}
    </div>
  )
};

export default Breadcrumbs;