import {createSlice} from "@reduxjs/toolkit";
import {
  getServiceByIdAction, getServiceByMobileTypeAction, getServiceByTrailerTypeAction,
  getServiceByTruckTypeAction,
  getServicesAction
} from "./servicesReducer";

const initialState = {
  loading: false,
  services: [],
  service: {},
  truckRepairServices: [],
  trailerRepairServices: [],
  mobileTowingServices: [],
}

const serviceSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {

  },
  extraReducers: {
    [getServicesAction.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getServicesAction.fulfilled]: (state, action) => {
      state.loading = false
      state.error = false
      state.services = action.payload
    },
    [getServicesAction.rejected]: (state) => {
      state.loading = false
      state.error = true
    },


    [getServiceByIdAction.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getServiceByIdAction.fulfilled]: (state, action) => {
      state.loading = false
      state.error = false
      state.service = action.payload
    },
    [getServiceByIdAction.rejected]: (state) => {
      state.loading = false
      state.error = true
    },


    [getServiceByTruckTypeAction.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getServiceByTruckTypeAction.fulfilled]: (state, action) => {
      state.loading = false
      state.error = false
      state.truckRepairServices = action.payload.truck
      state.trailerRepairServices = action.payload.trailer
      state.mobileTowingServices = action.payload.mobile
    },
    [getServiceByTruckTypeAction.rejected]: (state) => {
      state.loading = false
      state.error = true
    },

  },
})

export const servicesSlice = serviceSlice.reducer;


