import React from 'react'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {ArrowUpTrayIcon} from "@heroicons/react/20/solid";

const ExportToExcel = ({ apiData, fileName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    window.location.reload();
  };

  const handleExport = () => {
    apiData.length && exportToCSV(apiData, fileName)
  }

  return (
    <button className='flex gap-2 bg-[#F2F2F2] items-center rounded-3xl px-4 py-1.5' onClick={handleExport}>
      <p className='font-medium text-sm'>export</p>
      <ArrowUpTrayIcon className="h-4 w-4" />
    </button>
  );
};

export default ExportToExcel;
