import React, {Fragment, useEffect, useState} from 'react';
import Button from "../components/Button";
import {XMarkIcon} from "@heroicons/react/20/solid";
import {Dialog} from "@material-tailwind/react";
import {useFormik} from "formik";
import {employeeSchema} from "../utils/schema";
import Input from "../components/Input";
import SuccessModal from "./SuccessModal";
import ErrorModal from "./ErrorModal";
import {useDispatch, useSelector} from "react-redux";
import SelectInput from "../components/SelectInput";
import {getUserByIdAction, inviteUserAction, updateUserAction} from "../redux/users/UsersActions";

const EditEmployeeModal = ({id, handleOpen, open}) => {

  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const handleSuccessModal = () => setSuccessModal(!successModal)
  const handleErrorModal = () => setErrorModal(!errorModal)

  const dispatch = useDispatch()
  const {userById} = useSelector(state => state.admin_users)

  useEffect(() => {
    dispatch(getUserByIdAction({id}))
  }, [])

  const formik = useFormik({
    initialValues: {
      full_name: userById?.full_name,
      email: userById?.email,
      role: userById?.role,
    },
    enableReinitialize: true,
    validationSchema: employeeSchema,
    onSubmit: (data) => {
      // console.log(data)
      dispatch(updateUserAction({id, data, handleSuccessModal, handleErrorModal}))
    }
  });

  const fields = [
    {id: 1, title: 'Full name', value: formik.values.full_name, name: 'full_name'},
    {id: 2, title: 'E-mail', value: formik.values.email, name: 'email'},
  ]

  const selectField = [
    {id: 1, title: 'Role', value: formik.values.role, name: 'role', arr: [
        {id: 'admin'},
        {id: 'editor'}]},
  ]

  return (
    <>
      <Fragment>
        <Button onClick={handleOpen} variant="gradient">
          Open Dialog
        </Button>
        <Dialog className='p-4' open={open} handler={handleOpen}>
          <button onClick={handleOpen}
                  type="button"
                  className="absolute w-7 top-4 right-4 text-base text-[#C5C5C5] bg-transparent rounded-lg ml-auto inline-flex items-center"
                  data-modal-toggle="defaultModal"
          >
            <XMarkIcon className="h-6 w-6 text-[#C5C5C5]" />
            <span className="sr-only">Close modal</span>
          </button>
          <div>
            <form onSubmit={formik.handleSubmit}>
              <p className='pb-4 pl-1 text-lg text-dark'>Edit employee details</p>
              <div className='flex flex-col gap-2.5 relative'>
                {
                  fields.map(field => (
                    <Input key={field.id} field={field} formik={formik}/>
                  ))
                }
                {
                  selectField.map(field => (
                    <SelectInput key={field.id} field={field} formik={formik} arr={field.arr}/>
                  ))
                }
              </div>
              <div className='justify-center flex mt-8'>
                <Button padding='16' type='submit' text='Send' visibility={true}/>
              </div>
            </form>
          </div>
        </Dialog>
      </Fragment>

      {
        successModal && <SuccessModal title='Employee updated successfully' handleOpen={handleSuccessModal} open={successModal}/>
      }

      {
        errorModal && <ErrorModal text='Application error' handleOpen={handleErrorModal} open={errorModal}/>
      }

    </>
  );
};

export default EditEmployeeModal;