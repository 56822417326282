import React, {useState} from 'react';
import sidebar_logo from '../assets/sidebar_logo.svg'
import logo from '../assets/logo.svg'
import service from '../assets/sidebar_icons/service.svg'
import active_service from '../assets/sidebar_icons/active_service.svg'
import part from '../assets/sidebar_icons/part.svg'
import active_part from '../assets/sidebar_icons/active_part.svg'
import employee from '../assets/sidebar_icons/employee.svg'
import active_employee from '../assets/sidebar_icons/active_employee.svg'
import logout from '../assets/sidebar_icons/logout.svg'
import {Bars3Icon, XMarkIcon} from "@heroicons/react/20/solid";
import {Link, useLocation, useNavigate} from "react-router-dom";

const Sidebar = () => {

  const [openMenu, setOpenMenu] = useState(false)
  const location = useLocation()

  const toggleOpenMenu = () => setOpenMenu(!openMenu)
  const navigate = useNavigate()

  const handleLogout = () => {
    localStorage.clear()
    navigate('/admin')
  }

  return (
    <div className={`bg-dark w-screen ${openMenu ? 'h-screen' : 'h-fit'} md:h-screen md:w-[6%] p-4`}>
      <div className='flex justify-between'>
        <img className='md:block hidden' src={sidebar_logo} alt="/"/>
        {
          openMenu ? <div/> : <img className='md:hidden' src={sidebar_logo} alt="/"/>
        }
        {
          openMenu ? <XMarkIcon onClick={toggleOpenMenu} className="h-8 w-8 text-white md:hidden" /> : <Bars3Icon onClick={toggleOpenMenu} className="h-8 w-8 text-white md:hidden" />
        }
      </div>
      <div className='md:flex justify-center md:pt-11 p-0'>
        <div className='md:block hidden flex'>
          <Link to='/admin/services'>
            {
              location.pathname === '/admin/services' ? <img className="h-7 w-7 mb-7" src={active_service} alt="/"/> : <img className="h-6 w-6 mb-7" src={service} alt="/"/>
            }

          </Link>
          <Link to='/admin/parts'>
            {
              location.pathname === '/admin/parts' || location.pathname === '/admin/parts/create_part' ? <img className="h-6 w-6 mb-7" src={active_part} alt="/"/> : <img className="h-6 w-6 mb-7" src={part} alt="/"/>
            }
          </Link>
          <Link to='/admin/employees'>
            {
              location.pathname === '/admin/employees' ? <img className="h-6 w-6 mb-7" src={active_employee} alt="/"/> : <img className="h-6 w-6 mb-7" src={employee} alt="/"/>
            }
          </Link>
          <img onClick={handleLogout} className="h-6 w-6 mb-7 cursor-pointer" src={logout} alt="/"/>
        </div>
        {
          openMenu && <div className='md:hidden font-medium text-base text-white text-center flex flex-col justify-center items-center h-full pb-28'>
            <div className='w-full'>
              <img className='md:hidden m-auto mb-12' src={logo} alt="/"/>
              <Link to='/admin/services'><p className='p-4 border-b-[1px] border-[#3B3B3B]'>Services</p></Link>
              <Link to='/admin/parts'><p className='p-4 border-b-[1px] border-[#3B3B3B]'>Parts</p></Link>
              <Link to='/admin/employees'><p className='p-4 border-b-[1px] border-[#3B3B3B]'>Employees</p></Link>
              <p onClick={handleLogout} className='p-4 border-b-[1px] border-[#3B3B3B] cursor-pointer'>Log Out</p>
            </div>
          </div>
        }
      </div>

    </div>
  );
};

export default Sidebar;