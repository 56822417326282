import React, {useState} from 'react';
import Categories from "./Categories";
import Brands from "./Brands";
import {useSelector} from "react-redux";

const CreateCategoriesBrandsInfoForm = () => {

  const [opendTab, setOpenTab] = useState(true)
  const {categories, brands} = useSelector(state => state.admin_parts)

  const openBrandsTab = () => setOpenTab(false)
  const openCategoriesTab = () => setOpenTab(true)

  return (
    <div className='md:flex flex-col md:flex-row md:h-[77vh] h-[75vh] overflow-y-auto bg-white rounded-lg py-3'>
      <div className='md:w-1/5 w-full border-b border-gray-300 md:border-b-0 mb-6 flex md:inline md:border-r-2 lg:h-[75vh]'>
        <p onClick={openCategoriesTab} className={`pb-3 ${opendTab ? 'bg-[#F5F5F5]' : 'bg-transparent'}  px-5 py-2 cursor-pointer w-full`}>Categories ({categories.length})</p>
        <p onClick={openBrandsTab} className={`pb-3 ${!opendTab ? 'bg-[#F5F5F5]' : 'bg-transparent'}  px-5 py-2 cursor-pointer w-full`}>Brands ({brands.length})</p>
      </div>
      <div className='px-5 w-full '>

        {
          opendTab ? <Categories/> : <Brands/>
        }
      </div>
    </div>
  );
};

export default CreateCategoriesBrandsInfoForm;