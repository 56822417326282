import React from 'react';
import {ExclamationCircleIcon} from "@heroicons/react/20/solid";

const Notification = ({text, openErrorAlertModal}) => {
  const error_arr = text.split('. ')
  return (
    <div className={`flex z-50 absolute top-12 md:right-6 ld:right-10 right-2 ${!openErrorAlertModal ? 'ease-in duration-300 opacity-0' : 'ease-in duration-300 opacity-100'}`}>
      <div className="m-auto">
        <div className="bg-white rounded-lg border-gray-300 border p-3 shadow-lg">
          <div className="flex flex-row">
            <div className="px-2">
              <ExclamationCircleIcon className="h-6 w-6 text-red" />
            </div>
            <div className="ml-2 mr-6">
              <span className="font-semibold text-red text-sm md:text-md">Error!</span>
              <ul className="block text-gray-500 text-xs md:text-sm list-inside list-disc">
                {
                  error_arr.map(err => <li>{err}</li>)
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;