import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  createBrandAction,
  createCategoryAction,
  deleteBrandAction,
  getPartBrandsAction,
  getPartCategoriesAction, updateBrandAction, updateCategoryAction
} from "../../redux/parts/PartsActions";
import {PaperAirplaneIcon, PencilIcon, TrashIcon} from "@heroicons/react/20/solid";
import Button from "../../components/Button";
import delete_icon from "../../assets/delete.svg";

function Icon({ id, open }) {
  return (
      id !== open ? <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M10 5.757v8.486M5.757 10h8.486M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
    </svg> : <svg className="w-[21px] h-[21px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M1 1h16"/>
    </svg>
  );
}

export default function Brands() {
  const [open, setOpen] = React.useState(0);
  const handleOpen = (value) => setOpen(open === value ? 0 : value);
  const [openDeleteModal, setOpenDeleteModel] = useState({id: null, opened: false})
  const [addBrand, setAddBrand] = React.useState(false);
  const [brandName, setBrandName] = useState('')
  const [brandUpdatedName, setBrandUpdatedName] = useState({id: null, title: ''})
  const [editBrand, setEditBrand] = useState(false)

  const handleOpenEditBrandInput = (brand) => {
    setBrandUpdatedName({id: brand.id, title: brand.title})
    setEditBrand(!editBrand)
  }

  const handleEditBrand = () => {
    dispatch(updateBrandAction(brandUpdatedName))
    setEditBrand(false)
  }

  const onChangeBrandName = (e) => {
    setBrandUpdatedName({...brandUpdatedName, title : e.target.value})
  }

  const dispatch = useDispatch()
  const {brands} = useSelector(state => state.admin_parts)

  useEffect(() => {
    dispatch(getPartBrandsAction())
  }, [])

  const handleCreateBrand = () => {
    dispatch(createBrandAction({name: brandName}))
    toggleAddCategory()
  }
  const toggleAddCategory = () => setAddBrand(!addBrand)

  const handleDeleteService = (id) => {
    dispatch(deleteBrandAction({id, handleCloseDeleteModal}))
  }

  const handleOpenDeleteModal = (id) => {
    setOpenDeleteModel({id, opened: !openDeleteModal.opened})
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModel({id: openDeleteModal.id, opened: !openDeleteModal.opened})
  }

  return (
    <>
      <ul className='flex flex-col gap-5 mt-2'>
        {
          brands.map(brand => (
            <li className='px-3 border-l-2 border-gray-300 flex justify-between'>
              {
                editBrand && brandUpdatedName.id === brand.id ? <input className='w-11/12 border pl-2' onChange={onChangeBrandName} value={brandUpdatedName.title} type="text"/> : <p>{brand.title}</p>
              }
              {
                editBrand && brandUpdatedName.id === brand.id ? <PaperAirplaneIcon onClick={handleEditBrand} className="h-6 w-6 text-[#C5C5C5] cursor-pointer" /> :
                <div className='flex gap-2'>
                  <PencilIcon onClick={() => handleOpenEditBrandInput(brand)} className="h-6 w-6 text-[#C5C5C5] cursor-pointer"/>
                  <div className='relative'>
                    <img onClick={() => handleOpenDeleteModal(brand.id)} className="h-6 w-6 text-gray-400 cursor-pointer"
                         src={delete_icon} alt="/"/>
                    {
                      openDeleteModal.id === brand.id && openDeleteModal.opened &&
                      <div className='p-2 w-96 rounded-lg bg-white z-10 border border-[#C5C5C5] absolute top-0 right-6'>
                        <p className='w-fit text-base'>
                          Are you sure you want to delete this message?
                        </p>
                        <div className='flex text-white gap-1.5 mt-2 float-right'>
                          <button onClick={() => handleDeleteService(brand.id)}
                                  className='bg-[#C5C5C5] px-5 py-1.5 rounded-md'>Yes
                          </button>
                          <button onClick={handleCloseDeleteModal} className='bg-[#C5C5C5] px-5 py-1.5 rounded-md'>No
                          </button>
                        </div>
                      </div>
                    }
                  </div>
                </div>
                }
            </li>
          ))
        }
      </ul>
      { addBrand ?
        <>
          <div className='flex flex-col w-full text-start mt-5 mb-3'>
            <div className='bg-[#F9F9F9] border-[#E3E3E3] border-b rounded-t-md'>
              <label className='text-[#7B7B7B] text-xs px-3 py-1' htmlFor="">Name</label>
              <div className='flex justify-between'>
                <input onChange={(e) => setBrandName(e.target.value)} className='border-none w-full text-dark px-3 pb-2 text-base outline-none bg-transparent focus:ring-0'/>
              </div>
            </div>
          </div>
          <div className='flex gap-2'>
            <Button onClick={handleCreateBrand} type="button" padding='2' text='Create'/>
            <button type='button' onClick={toggleAddCategory} className='bg-gray-400 py-2 px-5 text-white md:w-fit w-full rounded-lg font-medium flex justify-center'>
              Cancel
            </button>
          </div>
        </> : <button onClick={toggleAddCategory} className='text-[#1594F0] mt-5'>+ Create brand</button>
      }
    </>

  );
}