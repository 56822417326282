import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/20/solid";

export function Pagination({count, dispatchFunction}) {
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(dispatchFunction({number: currentPage}))
    window.scrollTo({
      top: 0,
      behavior: 'instant'
    });
  }, [currentPage]);

  function decrement() {
    setCurrentPage(currentPage - 1);
  }
  function increment() {
    setCurrentPage(currentPage + 1);
  }

  const countArr = []

  for(let i = 0; i < Math.ceil(count/36); i++){
    countArr.push(i)
  }

  const handlePaginate = (i) => {
    if(i>1) setCurrentPage(0)
    else setCurrentPage(i)
    window.scrollTo(0, 0);
  }

  return (
    <>
      {
        count > 10 && <div className='flex gap-4 mt-4 w-full justify-center items-center'>
          <button className='text-black flex text-sm disabled:text-gray-400' style={currentPage ? {cursor: 'pointer'} : null} disabled={currentPage === 1} onClick={decrement}>
            <ChevronLeftIcon className="h-5 w-5" />
            <p>Prev</p>

          </button>
          {
            countArr.map((i, ind) => {
              return <button disabled onClick={() => handlePaginate(ind+1)} style={currentPage === ind+1 ? {color: "white", background: '#A80301', padding: '3px 10px'} : null} className='text-black px-3 py-1 rounded-md cursor-pointer text-center text-sm'>{ind+1}</button>
            })
          }
          <button className='text-black text-sm disabled:text-gray-400 flex' style={currentPage < count-4 ? {cursor: 'pointer'} : null} disabled={currentPage > (count / 36)} onClick={increment}>
            <p>Next</p>
            <ChevronRightIcon className="h-5 w-5" />
          </button>
        </div>
      }
    </>

  );
}