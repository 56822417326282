import React, {useState} from 'react';
import logo from "../assets/logo.svg";
import Button from "../components/Button";
import {useLocation, useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import {resetPasswordSchema} from "../utils/schema";
import {useDispatch, useSelector} from "react-redux";
import {resetUserPasswordAction} from "../redux/users/UsersActions";
import SuccessModal from "../widgets/SuccessModal";
import ErrorModal from "../widgets/ErrorModal";
import Input from "../components/Input";
import PasswordTooltip from "../components/PasswordTooltip";
import Notification from "../components/Notification";

const ResetPassword = () => {

  const navigate = useNavigate()
  const location = useLocation()
  const token = location.pathname.split('/').pop()
  const uid = location.pathname.split('/')[4]
  const dispatch= useDispatch()
  const {error_message} = useSelector(state => state.admin_users)


  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [openErrorAlertModal, setOpenErrorAlertModal] = useState(false);
  const handleSuccessModal = () => setSuccessModal(!successModal)
  const handleErrorModal = () => setErrorModal(!errorModal)

  const formik = useFormik({
    initialValues: {
      uid: uid,
      new_password: "",
      repeat_password: "",
      token: token,
    },
    validationSchema: resetPasswordSchema,
    onSubmit: (data) => {
      dispatch(resetUserPasswordAction({data, handleErrorModal, handleSuccessModal, navigate, setOpenErrorAlertModal}))
    }})

  const fields = [
    {id: 1, title: 'Password', type:'password', name: 'new_password', password: true},
    {id: 2, title: 'Confirm password', type:'password', name: 'repeat_password', password: true},
  ]

  return (
    <>
      <div className='h-screen w-screen bg-dark flex flex-col justify-center'>
        <div className='m-auto lg:w-1/3 md:w-1/2 w-full md:p-0 px-4'>
          <img className='w-50 m-auto mb-14' src={logo} alt="/"/>
          <form onSubmit={formik.handleSubmit} className='bg-white rounded-lg p-4 w-full'>
            <p className='text-xl text-dark mb-7 text-center'>Reset password</p>
              <div className='flex flex-col gap-2.5 mb-2.5 justify-center items-center'>
                {
                  fields.map(field => (
                    field.title === 'Password' ?
                      <PasswordTooltip><Input field={field} formik={formik}/></PasswordTooltip> :
                      <Input field={field} formik={formik}/>
                  ) )
                }
                <Button padding='5' type='submit' text='Send'/>
              </div>
          </form>
        </div>
      </div>
      <Notification openErrorAlertModal={openErrorAlertModal} text={error_message}/>
      {
        successModal && <SuccessModal text='You reset password successfully' handleOpen={handleSuccessModal} open={successModal}/>
      }

      {
        errorModal && <ErrorModal text='Application error' handleOpen={handleErrorModal} open={errorModal}/>
      }
    </>

  );
};

export default ResetPassword;