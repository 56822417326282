import {GoogleMap, LoadScript, MarkerF} from "@react-google-maps/api";
import React, { useMemo } from "react";
import AddressTab from "../AddressTab";
import '../../../index.css'

const Map = () => {
  const containerStyle = {
    width: '100%',
    height: '100%',
  };


  const center = useMemo(() => ({ lat: 40.63312, lng: -89.39853 }), []);

  const options = useMemo(
    () => ({
      mapId: "7b77f4b16547fbfb",
      disableDefaultUI: true,
      clickableIcons: false,
      suppressMarkers: true,
      preserveViewport: true,
      fullscreenControl: false,
      mapTypeControl: false
    }),
      []
  );

  const pathName = window.location.pathname

  return (
    <div>
      <h5 className={`lg:text-[26px] ${pathName !== '/' && 'bg-dark text-[#F5F5F5]'} text-[22px] pb-8 text-center pt-16`}>Service Centers</h5>
      <div className="w-screen h-[50vh] relative">
        <LoadScript googleMapsApiKey="AIzaSyCu0MRX1Mget_k2v0z_foDbfAvip9XCE_M">
          <GoogleMap
            options={options}
            mapContainerStyle={containerStyle}
            center={center}
            zoom={10}
          >
            <MarkerF position={center} />
          </GoogleMap>
        </LoadScript>
      <AddressTab/>
    </div>
    </div>

  );
};

export default Map;