import {request} from "../api";

export const getConsultation = async ({data, handleSuccessModal, handleErrorModal}) => {
  try {
    const response = await request.getConsultationApi(data)
    console.log(response)
    handleSuccessModal()
    setInterval(() => handleSuccessModal, 3000)
  } catch (error){
    handleErrorModal()
    console.log('Error: ', error.message)
  }
}
