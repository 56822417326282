import React from 'react'
import '../../index.css'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

function AutoComplete({services, formik}) {

  const handleOnSelect = (item) => {
    formik.setFieldValue('service', item.id)
  }
  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
      </>
    )
  }

  return (
    <div className='bg-white mb-2'>
      <header>
        <div>
          <p className='text-[#7B7B7B] text-xs px-3 py-1'>Service</p>
          <ReactSearchAutocomplete className='!rounded-0 h-80'
            items={services}
            onSelect={handleOnSelect}
            autoFocus
            formatResult={formatResult}
          />
        </div>
      </header>
    </div>
  )
}

export default AutoComplete