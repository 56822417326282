import {createSlice} from "@reduxjs/toolkit";
import {getUserByIdAction, getUsersAction, resetUserPasswordAction} from "./UsersActions";
import {authUser} from "../authAction";

const initialState = {
  loading: false,
  error_message: '',
  users: [],
  // userById: {},
}

const userSlice = createSlice({
  name: 'admin_users',
  initialState,
  reducers: {

  },
  extraReducers: {
    [getUsersAction.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getUsersAction.fulfilled]: (state, action) => {
      state.loading = false
      state.error = false
      state.users = action.payload
    },
    [getUsersAction.rejected]: (state) => {
      state.loading = false
      state.error = true
    },

    [getUserByIdAction.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getUserByIdAction.fulfilled]: (state, action) => {
      state.loading = false
      state.error = false
      state.userById = action.payload
    },
    [getUserByIdAction.rejected]: (state) => {
      state.loading = false
      state.error = true
    },

    [resetUserPasswordAction.pending]: (state) => {
      state.loading = true;
    },
    [resetUserPasswordAction.fulfilled]: (state, action) => {
      state.loading = false
    },
    [resetUserPasswordAction.rejected]: (state, action) => {
      state.loading = false
      state.error_message = action.payload
    },

    [authUser.pending]: (state) => {
      state.loading = true;
    },
    [authUser.fulfilled]: (state, action) => {
      state.loading = false
    },
    [authUser.rejected]: (state, action) => {
      state.loading = false
      state.error_message = action.payload
    },
  },
})

export const adminUsersSlice = userSlice.reducer;


