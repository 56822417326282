import React, {useEffect, useMemo, useState} from 'react';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getServicesAction} from "../../redux/services/servicesReducer";
import {truncateString} from "../../utils/truncate";

const Services = () => {

    const dispatch = useDispatch()
    const {services} = useSelector(state => state.services)
    const main_services = useMemo(() => services?.filter(service => service.status), [services])
    // const main_services = services?.filter(service => service.status)

    useEffect(() => {
        !services.length && dispatch(getServicesAction())
    }, [])

    const [open, setOpen] = useState(false);
    const [requestModal, setRequestModal] = useState(false);
    const [service, setService] = useState(null);
    const handleRequestModal = (service) => {
        setRequestModal(!requestModal)
        setService(service)
    };

    return (
        <section className='bg-dark p-4'>
            <div className='container m-auto text-center pt-28'>
                <h5 className='text-[#ECECEC] lg:text-[26px] text-[22px] mb-10'>What can we do for you?</h5>
                <ul className='flex flex-wrap justify-start mb-7 lg:mb-12'>
                    {
                        main_services?.map(service => (
                            <li key={service.id} className="lg:p-4 p-3 sm:w-1/2 lg:w-[33%] w-full h-48 my-class">
                                <div className='flex flex-col gap-1 h-full text-start bg-[#333436] overflow-hidden rounded-md pt-5 pb-7 px-6 group hover:bg-[#3c3d3f]'>
                                    <div className='flex justify-between items-center mb-1'>
                                        <Link to={`/services/${service.id}`}><img className='w-11' src={service.logo} alt="/"/></Link>
                                        <a href="https://app.fullbay.com/GLJHIU" target='_blank' rel="noreferrer"><button onClick={() => handleRequestModal(service)} className='rounded-3xl h-full text-white text-sm bg-[#559f23] px-7 py-1 hidden group-hover:block'>Send a request</button></a>
                                    </div>
                                    <Link to={`/services/${service.id}`}>
                                        <p className='text-lg text-white font-medium'>{service.name}</p>
                                        <p className='text-base leading-[130%] text-[#949494] truncate-line-2'>{truncateString(service.description, 75)}</p>
                                    </Link>
                                </div>
                            </li>
                        ))
                    }
                    {/*<li className='p-4 sm:w-1/2 lg:w-[33%] hidden lg:block my-class'>*/}
                    {/*    <div className='flex flex-col gap-2 text-start bg-[#292A2C] rounded-md p-5 h-full'>*/}
                    {/*    </div>*/}
                    {/*</li>*/}
                </ul>
                <Link to='/services'><p className='inline-block border-b text-white font-medium text-lg py-1.5 px-0.5 mt-0 lg:mt-5'>See all services</p></Link>
            </div>
        </section>
    );
};

export default Services;