import {GoogleMap, MarkerF, useLoadScript} from "@react-google-maps/api";
import React, {Fragment, useEffect, useMemo, useState} from "react";
import '../../index.css'
import {Dialog} from "@material-tailwind/react";
import SearchLocationInput from "./SearchLocationInput";

const MapLocationModal = ({handleOpen, open, formik, address, setAddress}) => {

  const [coordinates, setCoordinates] = useState({latitude: 53.350551, longitude: 9.929400});
  const [coor, setCoor] = useState({lat: 53.350551, lng: 9.929400})

  useEffect(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCoor({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.error('Error getting current location:', error.message);
        }
      );
    } else {
      console.error('Geolocation is not available in this browser.');
    }
  }, []);

  useEffect(() => {
    setCoor({lat: coordinates?.latitude, lng: coordinates?.longitude})
    formik.values.long = coordinates?.longitude
    formik.values.lat = coordinates?.latitude
  }, [coordinates])

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyA4nI8XcSiyCtazwTzQcuvg0RLc515IDAE',
  });

  const options = useMemo(
    () => ({
      mapId: "76120b50c8e99c11",
      disableDefaultUI: true,
      clickableIcons: false,
      suppressMarkers: true,
      preserveViewport: true,
      scrollwheel: false,
      fullscreenControl: false,
      mapTypeControl: false
    }),
    []
  );

  console.log('coor: ', coor)

  return (
    <div className='relative'>
      <Fragment>
        <Dialog size='xl' open={open} handler={handleOpen}>
          <div className='relative'>
            <SearchLocationInput setAddress={setAddress} address={address} handleCloseMap={handleOpen} setCoor={setCoor} setCoordinates={setCoordinates}/>
            <div className="w-full h-[90vh]">
              {!isLoaded ? (
                <h1>Loading...</h1>
              ) : (
                <GoogleMap
                  mapContainerClassName="w-full h-full"
                  center={coor}
                  zoom={10}
                  options={options}
                >
                  <MarkerF position={coor} />
                </GoogleMap>
              )}
            </div>
          </div>
        </Dialog>
      </Fragment>
    </div>
  );
};

export default MapLocationModal;


