import React from 'react';
import logo from "../assets/logo.svg";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {getServiceByIdAction} from "../redux/services/servicesReducer";

const Footer = () => {

  const {truckRepairServices, trailerRepairServices, mobileTowingServices} = useSelector(state => state.services)
  const dispatch = useDispatch()
  const handleGetServiceById = (id) => dispatch(getServiceByIdAction(id))

  return (
    <div className='container m-auto mt-[450px] md:mt-80'>
        <img className='flex justify-center m-auto mb-10 w-56' src={logo} alt="/"/>
        <div className='lg:w-1/2 lg:p-0 m-auto w-full p-4'>
          <div className='flex justify-between flex-wrap'>
            <ul className='flex flex-col gap-4 lg:w-fit w-1/2 mb-4'>
              <li className='text-[#D4110E] text-base font-medium mt-1'>Truck Repair</li>
              {
                truckRepairServices?.map(truck =>
                  <Link key={truck.id} to={`/services/${truck.id}`}>
                    <li onClick={() => handleGetServiceById(truck.id)} key={truck.id} className='hover:text-[#D4110E] cursor-pointer text-sm text-dark'>{truck.name}</li>
                  </Link>
                )
              }
            </ul>

            <ul className='flex flex-col gap-5 lg:w-fit w-1/2 mb-4'>
              <li className='text-[#D4110E] text-base font-medium mt-1'>Trailer Repair</li>
              {
                trailerRepairServices?.map(trailer => (
                  <Link key={trailer.id} to={`/services/${trailer.id}`}>
                    <li onClick={() => handleGetServiceById(trailer.id)} key={trailer.id} className='hover:text-[#D4110E] cursor-pointer text-sm text-dark'>{trailer.name}</li>
                  </Link>
                ))
              }
            </ul>

            <ul className='flex flex-col gap-5 lg:w-fit w-1/2 mb-4'>
              <li className='text-[#D4110E] text-base font-medium mt-1'>Mobile & Towing</li>
              {
                mobileTowingServices?.map(mobile =>
                  <Link key={mobile.id} to={`/services/${mobile.id}`}>
                    <li onClick={() => handleGetServiceById(mobile.id)} key={mobile.id} className='hover:text-[#D4110E] cursor-pointer text-sm text-dark'>{mobile.name}</li>
                  </Link>
                )
              }
            </ul>

            <ul className='flex flex-col gap-7 mb-12 lg:w-fit w-1/2'>
              <li className='text-[#D4110E] text-base font-medium mt-1'>Parts</li>
              <li className='text-[#D4110E] text-base font-medium mt-1'>Partners</li>
              <li className='text-[#D4110E] text-base font-medium mt-1'>Testimonials</li>
            </ul>
          </div>
        </div>

        <div>
          <hr/>
          <p className='text-sm text-center text-[#A9A9A9] m-auto flex justify-center mt-2.5 pb-4'>Copyright © 2022 Pride Truck Center - All Rights Reserved.</p>
        </div>

    </div>
  );
};

export default Footer;