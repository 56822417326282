import React from 'react';
import {Tooltip, Typography} from "@material-tailwind/react";

const PasswordTooltip = ({children}) => {
  return (
    <Tooltip dismiss ={{referencePressEvent: 'click'}}
             placement="bottom"
             className="border border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/10"
             content={
               <div className="w-fit h-32">
                 <Typography color="blue-gray" className="font-medium">
                   Ensure that these requirements are met:
                 </Typography>
                 <Typography
                   variant="small"
                   color="blue-gray"
                   className="font-normal opacity-80"
                 >
                   <ul className="mt-2 ml-2 list-inside list-disc">
                     <li>At least 8 characters</li>
                     <li>Mustn't be too similar to email</li>
                     <li>Mustn't be too common</li>
                     <li>Can't be entirely numeric</li>
                   </ul>
                 </Typography>
               </div>
             }
    >
      <div className='flex flex-col gap-2.5 w-full'>
        {children}
      </div>
    </Tooltip>
  );
};

export default PasswordTooltip;