import React, {useEffect, useState} from 'react';
import Input from "../../components/Input";
import {useFormik} from "formik";
import {partUpdateInfoSchema} from "../../utils/schema";
import SelectInput from "../../components/SelectInput";
import {useDispatch, useSelector} from "react-redux";
import {
  getPartBrandsAction, getPartByIdAction,
  getPartCategoriesAction,
  getPartSubcategoriesAction, updateCrossReferenceNumbers, updatePartAction
} from "../../redux/parts/PartsActions";
import Button from "../../components/Button";
import {XMarkIcon} from "@heroicons/react/20/solid";
import SuccessModal from "../../widgets/SuccessModal";
import ErrorModal from "../../widgets/ErrorModal";
import {useNavigate, useParams} from "react-router-dom";

const UpdatePartForm = () => {

  const {brands, categories, subcategories, partById} = useSelector(state => state.admin_parts)

  const {id} = useParams()
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const navigate = useNavigate()

  const handleSuccessModal = () => setSuccessModal(!successModal)

  const formik = useFormik({
    initialValues: {
      category: partById.category?.id,
      subcategory: partById.subcategory?.id,
      brand: partById.brand?.id,
      title: partById.title,
      height: partById.height,
      width: partById.width,
      length: partById.length,
      weight: partById.weight,
      price: partById.price,
      discount: partById.discount,
      quantity: partById.quantity,
      location: partById.location,
      link: partById.link,
      description: partById.description,
      part_images: partById.part_image,
      main_image: partById.main_image,
      cross_reference_numbers_fields: partById.cross_reference_number,
      status: partById.status,
      condition: partById.condition,
    },
    enableReinitialize: true,
    validationSchema: partUpdateInfoSchema,
    onSubmit: (data) => {
      if(selectedImages.length || selectedMainImage || selectedRemovingImages){
        const fData = new FormData();
        selectedImages && selectedImages.forEach((file, index) => {
          fData.append(`images`, file);
        });
        selectedMainImage && fData.append("main_image", selectedMainImage);
        selectedRemovingImages && fData.append("removed_images", JSON.stringify(selectedRemovingImages));
        fData.append("category", data.category);
        fData.append("subcategory", data.subcategory);
        fData.append("brand", data.brand);
        if(partById.title !== data.title) fData.append("title", data.title);
        fData.append("height", data.height);
        fData.append("width", data.width);
        fData.append("length", data.length);
        fData.append("weight", data.weight);
        fData.append("price", data.price);
        fData.append("discount", data.discount);
        fData.append("quantity", data.quantity);
        fData.append("location", data.location);
        fData.append("link", data.link);
        fData.append("description", data.description);
        fData.append("condition", data.condition);
        fData.append("status", data.status);
        dispatch(updatePartAction({id, data: fData, withFile: true, handleErrorModal, handleSuccessModal, navigate}))
        if(data.cross_reference_numbers_fields !== []){
          data.cross_reference_numbers_fields.forEach(obj => {
            const {id, ...data} = obj
            dispatch(updateCrossReferenceNumbers({id, data}))
          })
        }
      } else {
        const {main_image, ...rest} = data
        dispatch(updatePartAction({id, data: rest, withFile: false, handleErrorModal, handleSuccessModal, navigate}))
        if(data.cross_reference_numbers_fields !== []){
          data.cross_reference_numbers_fields.forEach(obj => {
            const {id, main_image, ...data} = obj
            dispatch(updateCrossReferenceNumbers({id, data}))
          })
        }
      }
    },
  });

  const handleErrorModal = () => setErrorModal(!errorModal)
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedRemovingImages, setSelectedRemovingImages] = useState([]);
  const [selectedMainImage, setSelectedMainImage] = useState();
  const [selectedMainImageURL, setSelectedMainImageURL] = useState(partById.main_image);

  const [emptyImagesNumber, setEmptyImagesNumber] = useState(8);

  const onSelectImages = (e) => {
    const files = Array.from(e.target.files);
    setSelectedImages([...selectedImages, ...files]);
  };

  const onSelectRemovingImages = (id) => {
    if(selectedRemovingImages.includes(id)){
      const updatedImages = [...selectedRemovingImages];
      selectedRemovingImages.forEach((el, index) => el === id && updatedImages.splice(index, 1))
      setSelectedRemovingImages(updatedImages);
    }else{
      setSelectedRemovingImages([...selectedRemovingImages, id]);
    }
  };

  const onSelectMainImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedMainImage(event.target.files[0]);
      setSelectedMainImageURL(URL.createObjectURL(event.target.files[0]));
    }
  };

  const handleRemoveFileInput = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
  };
  const handleRemoveMainImage = () => {
    setSelectedMainImageURL(partById.main_image)
    setSelectedMainImage(null)
  };
  const dispatch = useDispatch()

  useEffect(() => {
    setEmptyImagesNumber(8 - formik.values.part_images?.length);
  }, [selectedImages]);

  useEffect(() => {
    dispatch(getPartBrandsAction())
    dispatch(getPartCategoriesAction())
    dispatch(getPartSubcategoriesAction())
    dispatch(getPartByIdAction({id}))
  }, [])



  const fields = [
    {id: 1, title: 'Category', value: formik.values.category, name: 'category', arr: categories, placeholder: "Select one"},
    {id: 2, title: 'Subcategory', value: formik.values.subcategory, name: 'subcategory', arr: subcategories, placeholder: "Select one"},
    {id: 3, title: 'Brand', value: formik.values.brand, name: 'brand', arr: brands, placeholder: "Select one"},
    {id: 4, title: 'Status on main page', value: formik.values.status, name: 'status', arr: [{id: 'false', title: 'No'}, {id: 'true', title: 'Yes'}]},
    {id: 5, title: 'Condition', value: formik.values.condition, name: 'condition', arr: [{id: 'Used', title: 'Used'}, {id: 'New', title: 'New'}]},
  ]

  const nameField = [
    {id: 4, title: 'Name', value: formik.values.title, name: 'title'}
  ]

  const heightFields = [
    {id: 1, title: 'Height', value: formik.values.height, name: 'height'},
    {id: 2, title: 'Width', value: formik.values.width, name: 'width'},
    {id: 3, title: 'Length', value: formik.values.length, name: 'length'},
    {id: 4, title: 'Weight', value: formik.values.weight, name: 'weight'},
  ]

  const priceFields = [
    {id: 1, title: 'Price ($)', value: formik.values.price, name: 'price'},
    {id: 2, title: 'Discount', value: formik.values.discount, name: 'discount'},
    {id: 3, title: 'Quantity', value: formik.values.quantity, name: 'quantity'},
  ]

  const priceFields2 = [
    {id: 1, title: 'Location', value: formik.values.location, name: 'location'},
    {id: 2, title: 'Link', value: formik.values.link, name: 'link'},
  ]

  const DescriptionField = [
    {id: 1, title: 'Description', value: formik.values.description, name: 'description'}
  ]

  const handleAddCrossReferenceNumbers = (e, id, name) => {
    if(name === 'title') {
      const itemIndex = formik.values.cross_reference_numbers_fields.findIndex(item => item.id === id);
      const updatedItems = [...formik.values.cross_reference_numbers_fields];
      updatedItems[itemIndex] = {
        ...updatedItems[itemIndex],
        title: e.target.value,
      };
      formik.setFieldValue('cross_reference_numbers_fields', updatedItems);
    }else{
      const itemIndex = formik.values.cross_reference_numbers_fields.findIndex(item => item.id === id);
      const updatedItems = [...formik.values.cross_reference_numbers_fields];
      updatedItems[itemIndex] = {
        ...updatedItems[itemIndex],
        value: e.target.value,
      };
      formik.setFieldValue('cross_reference_numbers_fields', updatedItems);
    }
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit} className='overflow-y-auto h-[77vh]'>
        <div className='p-4 bg-white rounded-lg flex gap-4 md:flex-nowrap flex-wrap'>
          <div className='flex flex-col gap-7 md:w-2/5'>
            <div className='flex flex-col gap-2.5'>
              <p className='text-base font-medium'>
                General
              </p>
              {
                fields.map(field => (
                  <SelectInput field={field} formik={formik} arr={field.arr}/>
                ))
              }
              {
                nameField.map(field => (
                  <Input field={field} formik={formik}/>
                ))
              }
              <div className='flex gap-2'>
                {
                  heightFields.map(field => (
                    <Input field={field} formik={formik}/>
                  ))
                }
              </div>

            </div>
            <div className='flex flex-col gap-2.5'>
              <p className='text-base font-medium'>
                Price
              </p>
              <div className='flex gap-2'>
                {
                  priceFields.map(field => (
                    <Input field={field} formik={formik}/>
                  ))
                }
              </div>
              <div className='flex gap-2'>
                {
                  priceFields2.map(field => (
                    <Input field={field} formik={formik}/>
                  ))
                }
              </div>
            </div>

            <div className='flex flex-col gap-2.5 items-start'>
              <p className='text-base font-medium'>
                Cross Reference Numbers
              </p>
              <div className='flex flex-col gap-2 w-full'>
                {
                  formik.values.cross_reference_numbers_fields?.map((field, index) => (
                    <div className='flex gap-2 w-full'>
                      <div className='flex flex-col w-full text-start'>
                        <div className='bg-[#F9F9F9] border-[#E3E3E3] border-b rounded-t-md'>
                          <label className='text-[#7B7B7B] text-xs px-3 py-1' htmlFor="">Title</label>
                          <div className='flex justify-between'>
                            <input value={field.title} onChange={(e) => handleAddCrossReferenceNumbers(e, field.id, 'title')} className='border-none w-full text-dark px-3 pb-2 text-base outline-none bg-transparent focus:ring-0'/>
                          </div>
                        </div>
                      </div>
                      <div className='flex flex-col w-full text-start'>
                        <div className='bg-[#F9F9F9] border-[#E3E3E3] border-b rounded-t-md'>
                          <label className='text-[#7B7B7B] text-xs px-3 py-1' htmlFor="">Value</label>
                          <div className='flex justify-between'>
                          <input value={field.value} onChange={(e) => handleAddCrossReferenceNumbers(e, field.id, 'value')} className='border-none w-full text-dark px-3 pb-2 text-base outline-none bg-transparent focus:ring-0'/>
                        </div>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>

            <div className='flex flex-col gap-2.5 items-start'>
              <p className='text-base font-medium'>
                Description
              </p>
              {
                DescriptionField.map(field => <Input field={field} formik={formik}/>)
              }
            </div>
          </div>

          <div className='md:w-3/5'>
            <p className='text-[#7B7B7B] text-xs mt-4'>
              Photo
            </p>
            <div className='flex flex-wrap gap-1.5 py-2.5 pt-1 rounded-md'>
              {
                formik.values.part_images?.map((img, index) => (
                  <div className={`relative md:w-[32.7%] w-[49%] ${selectedRemovingImages.includes(img.id) ? 'scale-75 transition delay-75 duration-300 ease-in-out opacity-50' : 'scale-100'}`}>
                    <img className='h-44 object-contain bg-gray-400 rounded-md w-full' src={img.image} alt="/"/>
                    <XMarkIcon onClick={() => onSelectRemovingImages(img.id)} className="h-6 w-6 text-red cursor-pointer absolute top-2 right-2 z-50" />
                  </div>
                ))
              }

              {
                selectedImages?.map((img, index) => (
                  <div className='relative md:w-[32.7%] w-[49%]'>
                    <img className='h-44 object-contain bg-gray-400 rounded-md w-full' src={URL.createObjectURL(img)} alt="/"/>
                    <XMarkIcon onClick={() => handleRemoveFileInput(index)} className="h-6 w-6 text-red cursor-pointer absolute top-2 right-2 z-50" />
                  </div>
                ))
              }
              <div className='flex flex-col gap-2.5 relative md:w-[32.7%] w-[49%]'>
                <div className='p-11 border border-[#E3E3E3] rounded-md text-[#7B7B7B] text-center text-sm relative'>
                  <div>
                    <p>JPG, PNG</p>
                    <p>You can also upload files by</p>
                    <label className='text-[#3989B3] px-3 py-1 underline cursor-pointer z-50' htmlFor="images">Clicking here</label>
                  </div>
                  <input multiple onChange={onSelectImages} className='hidden' type="file" id='images'/>
                </div>
              </div>
            </div>
            <p className='text-[#7B7B7B] text-xs my-2'>
              Main photo
            </p>
            <div>
              <div className='flex flex-col gap-2.5 relative md:w-[32.7%] w-[49%] mb-4'>
                  <div className='border w-full border-[#E3E3E3] rounded-md text-[#7B7B7B] text-center text-sm relative'>
                    <img className='h-44 object-contain bg-gray-400 rounded-md w-full' src={(selectedMainImage && URL.createObjectURL(selectedMainImage)) || formik.values.main_image} alt="/"/>
                    {
                      selectedMainImageURL !== null && selectedMainImageURL !== partById.main_image && <XMarkIcon onClick={handleRemoveMainImage} className="h-6 w-6 text-red cursor-pointer absolute top-2 right-2 z-50" />
                    }
                    </div>
                </div>
                <div>
                  <label className='w-full h-full border p-2 rounded text-blue-500 cursor-pointer' htmlFor="main_image">Change main image</label>
                  <input onChange={onSelectMainImage} className='hidden' type="file" id='main_image'/>
                </div>
            </div>

          </div>
        </div>
        <div className='float-right m-3 z-50'>
          <Button type='submit' text='Update part' padding='4'/>
        </div>
      </form>

      {
        successModal && <SuccessModal title='Part updated successfully' handleOpen={handleSuccessModal} open={successModal}/>
      }

      {
        errorModal && <ErrorModal text='Application error' handleOpen={handleErrorModal} open={errorModal}/>
      }
    </>
  );
};

export default UpdatePartForm;