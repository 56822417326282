import {createSlice} from "@reduxjs/toolkit";
import {
  createPartAction,
  getAdminPaginatedPartsAction,
  getFilteredPartsAction,
  getPartBrandsAction,
  getPartByIdAction,
  getPartCategoriesAction,
  getPartsAction,
  getPartSubcategoriesAction, getSearchedPartsAction, ImportDataToPartsAction,
} from "./PartsActions";

const initialState = {
  loading: false,
  error: '',
  parts: [],
  partById: {},
  categories: [],
  brands: [],
  subcategories: [],
}

const partSlice = createSlice({
  name: 'admin_parts',
  initialState,
  reducers: {

  },
  extraReducers: {
    [getPartsAction.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getPartsAction.fulfilled]: (state, action) => {
      state.loading = false
      state.error = false
      state.parts = action.payload
    },
    [getPartsAction.rejected]: (state) => {
      state.loading = false
      state.error = true
    },

    [getAdminPaginatedPartsAction.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getAdminPaginatedPartsAction.fulfilled]: (state, action) => {
      state.loading = false
      state.error = false
      state.parts = action.payload
    },
    [getAdminPaginatedPartsAction.rejected]: (state) => {
      state.loading = false
      state.error = true
    },

    [getSearchedPartsAction.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getSearchedPartsAction.fulfilled]: (state, action) => {
      state.loading = false
      state.error = false
      state.parts = action.payload
    },
    [getSearchedPartsAction.rejected]: (state) => {
      state.loading = false
      state.error = true
    },

    [getPartByIdAction.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getPartByIdAction.fulfilled]: (state, action) => {
      state.loading = false
      state.error = false
      state.partById = action.payload
    },
    [getPartByIdAction.rejected]: (state) => {
      state.loading = false
      state.error = true
    },

    [getPartCategoriesAction.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getPartCategoriesAction.fulfilled]: (state, action) => {
      state.loading = false
      state.error = false
      state.categories = action.payload
    },
    [getPartCategoriesAction.rejected]: (state) => {
      state.loading = false
      state.error = true
    },

    [getPartBrandsAction.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getPartBrandsAction.fulfilled]: (state, action) => {
      state.loading = false
      state.error = false
      state.brands = action.payload
    },
    [getPartBrandsAction.rejected]: (state) => {
      state.loading = false
      state.error = true
    },

    [getPartSubcategoriesAction.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getPartSubcategoriesAction.fulfilled]: (state, action) => {
      state.loading = false
      state.error = false
      state.subcategories = action.payload
    },
    [getPartSubcategoriesAction.rejected]: (state) => {
      state.loading = false
      state.error = true
    },

    //   filter
    [getFilteredPartsAction.pending]: (state) => {
      state.loading = true;
      state.error = false;
    },
    [getFilteredPartsAction.fulfilled]: (state, action) => {
      state.loading = false
      state.error = false
      state.parts = action.payload
    },
    [getFilteredPartsAction.rejected]: (state) => {
      state.loading = false
      state.error = true
    },

    //   import data
    [ImportDataToPartsAction().pending]: (state) => {
      state.loading = true;
      state.error = '';
    },
    [ImportDataToPartsAction.fulfilled]: (state, action) => {
      state.loading = false
      state.error = ''
    },
    [ImportDataToPartsAction.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },

    [createPartAction.pending]: (state) => {
      state.loading = true;
      state.error = '';
    },
    [createPartAction.fulfilled]: (state, action) => {
      state.loading = false
      state.error = ''
    },
    [createPartAction.rejected]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const adminPartsSlice = partSlice.reducer;


