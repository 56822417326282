import React, {useState} from 'react';
import {PencilSquareIcon, TrashIcon} from "@heroicons/react/24/outline";
import {
  createCategoryAction,
  createSubcategoryAction,
  deleteSubcategoryAction,
  updateCategoryAction, updateSubcategoryAction
} from "../../redux/parts/PartsActions";
import {useDispatch} from "react-redux";
import Button from "../../components/Button";
import delete_icon from '../../assets/delete.svg'
import active_delete_icon from '../../assets/active_delete.svg'
import {PaperAirplaneIcon} from "@heroicons/react/20/solid";

const Subcategory = ({subcategories, categoryId}) => {
  const dispatch = useDispatch()

  const [openSubDeleteModal, setOpenSubDeleteModal] = useState({id: null, opened: false})
  const [addSubategory, setAddSubcategory] = React.useState(false);
  const [subcategoryName, setSubcategoryName] = useState('')
  const [subcategoryUpdatedName, setSubcategoryUpdatedName] = useState({id: null, title: ''})
  const [editSubcategory, setEditSubcategory] = useState(false)
  const handleOpenEditSubcategoryInput = (subc) => {
    setSubcategoryUpdatedName({id: subc.id, title: subc.title})
    setEditSubcategory(!editSubcategory)
  }

  const handleEditSubcategory = () => {
    dispatch(updateSubcategoryAction(subcategoryUpdatedName))
    setEditSubcategory(false)
  }

  const onChangeSubcategoryName = (e) => {
    setSubcategoryUpdatedName({...subcategoryUpdatedName, title : e.target.value})
  }


  const toggleAddSubcategory = () => setAddSubcategory(!addSubategory)

  const handleCreateCategory = () => {
    dispatch(createSubcategoryAction({title: subcategoryName, category: categoryId}))
    toggleAddSubcategory()
  }

  const handleOpenSubDeleteModal = (id) => {
    setOpenSubDeleteModal({id, opened: !openSubDeleteModal.opened})
  }

  const handleCloseSubDeleteModal = () => {
    setOpenSubDeleteModal({id: openSubDeleteModal.id, opened: !openSubDeleteModal.opened})
  }

  const handleDeleteSubCategory = (id) => {
    dispatch(deleteSubcategoryAction({id, handleCloseSubDeleteModal}))
  }

  return (
    <>
      <ul className='flex flex-col gap-2'>
        {
          subcategories.filter(sub => sub.category === categoryId).map(subc => (
            <li className='px-4 border-l border-[#0BA8E7] flex justify-between'>
              {
                editSubcategory && subcategoryUpdatedName.id === subc.id ? <input className='w-11/12 border pl-2' onChange={onChangeSubcategoryName} value={subcategoryUpdatedName.title} type="text"/> : <p>{subc.title}</p>
              }
              {
                editSubcategory && subcategoryUpdatedName.id === subc.id ? <PaperAirplaneIcon onClick={handleEditSubcategory} className="h-6 w-6 text-[#C5C5C5] cursor-pointer" /> :
                  <div className='flex gap-2'>
                <div className='relative'>
                  <img onClick={() => handleOpenSubDeleteModal(subc.id)}
                       className="h-6 w-6 text-gray-400 cursor-pointer" src={delete_icon} alt="/"/>
                  {
                    openSubDeleteModal.id === subc.id && openSubDeleteModal.opened &&
                    <div className='p-2 w-96 rounded-lg bg-white z-10 border border-[#C5C5C5] absolute top-0 right-6'>
                      <p className='w-fit text-base'>
                        Are you sure you want to delete this message?
                      </p>
                      <div className='flex text-white gap-1.5 mt-2 float-right'>
                        <button onClick={() => handleDeleteSubCategory(subc.id)}
                                className='bg-[#C5C5C5] px-5 py-1.5 rounded-md'>Yes
                        </button>
                        <button onClick={handleCloseSubDeleteModal} className='bg-[#C5C5C5] px-5 py-1.5 rounded-md'>No
                        </button>
                      </div>
                    </div>
                  }
                </div>
                <PencilSquareIcon onClick={() => handleOpenEditSubcategoryInput(subc)} className="h-6 w-6 text-[#C5C5C5] cursor-pointer"/>
              </div>}
            </li>
          ))
        }
      </ul>
      { addSubategory ?
        <>
          <div className='flex flex-col w-full text-start mt-5 mb-3'>
            <div className='bg-[#F9F9F9] border-[#E3E3E3] border-b rounded-t-md'>
              <label className='text-[#7B7B7B] text-xs px-3 py-1' htmlFor="">Name</label>
              <div className='flex justify-between'>
                <input onChange={(e) => setSubcategoryName(e.target.value)} className='border-none w-full text-dark px-3 pb-2 text-base outline-none bg-transparent focus:ring-0'/>
              </div>
            </div>
          </div>
          <div className='flex gap-2'>
            <Button onClick={handleCreateCategory} type="button" padding='2' text='Create'/>
            <button type='button' onClick={toggleAddSubcategory} className='bg-gray-400 py-2 px-5 text-white rounded-lg font-medium flex justify-center'>
              Cancel
            </button>
          </div>
        </> : <button onClick={toggleAddSubcategory} className='text-[#1594F0] mt-5'>+ Create subcategory</button>
      }
    </>
  );
};

export default Subcategory;