import React, {useEffect, useState} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import Map from "../components/main_page/Map";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getServicesAction} from "../redux/services/servicesReducer";
import Breadcrumbs from "../components/Breadcrumbs";
import {truncateString} from "../utils/truncate";
import CardsSkeleton from "../components/CardsSkeleton";

const Services = () => {

  const dispatch = useDispatch()
  const {services, loading} = useSelector(state => state.services)

  useEffect(() => {
    window.scrollTo(0, 0);
    !services.length && dispatch(getServicesAction())
  }, [dispatch])

  const [requestModal, setRequestModal] = useState(false);
  const [service, setService] = useState(null);
  const handleRequestModal = (service) => {
    setRequestModal(!requestModal)
    setService(service)
  };

  return (
    <>
      <Header/>
      <div className='bg-dark'>
        <div className='container m-auto px-2'>

          <Breadcrumbs/>

          <div className='flex justify-between mb-4'>
            <p className='text-lg text-[#FFF]'>All services</p>
            <p className='text-sm text-[#9B9B9B] hidden md:block'>Total: {services.length}</p>
          </div>
          {
            loading ? <CardsSkeleton/> :
              <ul className='flex flex-wrap justify-center pb-7 lg:pb-12'>
              {
                services?.map(service => (
                  <li key={service.id} className="lg:p-4 p-3 lg:w-1/2 w-[100%] md:min-h-48 my-class">
                    <div className='flex md:flex-row flex-col gap-4 text-start h-full bg-[#ECECEC] rounded-md p-3 pb-5 group'>
                      <img loading='lazy' className='h-full md:w-1/5 w-full object-cover' src={service.image} alt="/"/>
                      <div className='flex flex-col justify-between gap-3 text-dark'>
                        <div>
                          <p className='font-medium text-lg'>{service.name}</p>
                          <p className='text-sm'>{truncateString(service.description, 160)}</p>
                        </div>
                        <div className='flex gap-7 items-center text-sm '>
                          <Link to={`/services/${service.id}`} className='text-[#559F23] underline'>learn more</Link>
                          <a href="https://app.fullbay.com/GLJHIU" target='_blank' rel="noreferrer"><button className='bg-[#559F23] rounded-full px-7 py-2 text-white'>Send a request</button></a>
                        </div>
                      </div>
                    </div>
                  </li>
                ))
              }
            </ul>
          }

        </div>
      </div>
      <Map/>
      <Footer/>
    </>

  );
};

export default Services;