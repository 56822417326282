import React, { Fragment, useState } from "react";
import {Dialog} from "@material-tailwind/react";
import Button from "../components/Button";
import SuccessModal from "./SuccessModal";
import {ExclamationCircleIcon, XMarkIcon} from "@heroicons/react/20/solid";
import {useFormik} from "formik";
import {getConsultation} from "../redux/getConsultation/ConsultationAction";
import ErrorModal from "./ErrorModal";
import {getConsultationSchema} from "../utils/schema";
import Input from "../components/Input";
import {useSelector} from "react-redux";
import AutoComplete from "./AutoComplete";

export default function ModalForGetAConsultation({handleOpen, open}) {

  const {services} = useSelector(state => state.services)

  const formik = useFormik({
    initialValues: {
      name: "",
      phone_number: "",
      company_name: "",
      service: "",
      time: '',
      date: '',
    },
    validationSchema: getConsultationSchema,
    onSubmit: (values) => {
      console.log(values)
      const data = {...values, date_time: `${values.date}T${values.time}:00Z`}
      getConsultation({data, handleSuccessModal, handleErrorModal})
    },
  });

  const [addDateTime, setAddDateTime] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const handleSetAddDateTime = () => setAddDateTime(true)
  const handleSuccessModal = () => setSuccessModal(!successModal)
  const handleErrorModal = () => setErrorModal(!errorModal)

  const fields = [
    {id: 1, title: 'Name', name: 'name'},
    {id: 2, title: 'Phone number', name: 'phone_number'},
    {id: 3, title: 'Company name', name: 'company_name'},
  ]

  const date_time_fields = [
    {id: 1, title: 'Date', name: 'date', type: 'date'},
    {id: 2, title: 'Time', name: 'time', type: 'time'},
  ]

    return (
      <>
        <Fragment>
          <Dialog className='p-4' open={open} handler={handleOpen}>
            <button onClick={handleOpen}
                    type="button"
                    className="absolute w-7 top-4 right-4 text-base text-[#C5C5C5] bg-transparent rounded-lg ml-auto inline-flex items-center"
                    data-modal-toggle="defaultModal">
              <XMarkIcon className="h-6 w-6 text-[#C5C5C5]" />
              <span className="sr-only">Close modal</span>
            </button>
            <div>
              <form onSubmit={formik.handleSubmit}>
                <p className='pb-4 text-lg text-dark'>Get a consultation</p>
                <div className='flex flex-col gap-2.5 relative'>
                  {
                    fields.map(field => (
                      <Input key={field.id} formik={formik} field={field}/>
                    ))
                  }
                  <AutoComplete services={services} formik={formik}/>
                  <div className='flex gap-2.5 w-full'>
                      {
                        date_time_fields.map(field => (
                          <div className='flex flex-col w-1/2'>
                            <div key={field.id} className={`flex flex-col ${formik.errors[field.name] && formik.touched[field.name] ? 'bg-[#FCF5F4] border-[#A80301]' : 'bg-[#F9F9F9] border-[#E3E3E3]'} border-b rounded-t-md`}>
                              <label className='text-[#7B7B7B] text-xs px-3 py-1'>{field.title}</label>
                              <input onChange={formik.handleChange} name={field.name} className='border-none px-3 pb-2 text-base outline-none bg-transparent focus:ring-0 text-dark' type={field.type}/>
                            </div>
                            {formik.errors[field.name] && formik.touched[field.name] ? <div className='text-[#A80301] text-xs flex gap-1 mt-1 ml-3'>
                              <ExclamationCircleIcon className="h-4 w-4" />{formik.errors[field.name]}
                            </div> : null}
                          </div>

                        ))
                      }
                    </div>
                </div>
                <div className='float-right flex mt-4'>
                  <Button type='submit' text='Send' visibility={true}/>
                </div>
              </form>
            </div>
          </Dialog>
        </Fragment>

        {
          successModal && <SuccessModal handleOpen={handleSuccessModal} open={successModal}/>
        }

        {
          errorModal && <ErrorModal handleOpen={handleErrorModal} open={errorModal}/>
        }

      </>
    );
}