import React, {useEffect, useState} from 'react';
import Header from "../components/Header";
import Map from "../components/main_page/Map";
import Footer from "../components/Footer";
import { ShareIcon} from "@heroicons/react/20/solid";
import print_icon from "../assets/icons/print.svg";
import { useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getServiceByIdAction} from "../redux/services/servicesReducer";
import MapLocationModal from "../widgets/MapLocationModal";
import Breadcrumbs from "../components/Breadcrumbs";
import PageLinkCopyModal from "../widgets/PageLinkCopyModal";

const ServiceDetails = () => {

  const dispatch = useDispatch()
  const [openCopyModal, setOpenCopyModal] = useState(false)
  const {service, loading} = useSelector(state => state.services)
  const {id} = useParams()

  useEffect(() => {
    id && dispatch(getServiceByIdAction({id}))
  }, [dispatch, id])

  const [requestModal, setRequestModal] = useState(false);

  const handleRequestModal = () => setRequestModal(!requestModal)
  const handleCopyModal = () => setOpenCopyModal(!openCopyModal)

  const [locationModal, setLocationModal] = useState(false)
  const handleOpenLocationModal = () => setLocationModal(!locationModal)

  return (
    <div>
      <Header/>
      <div className='bg-dark'>
        <div className='container md:m-auto p-2'>

          <Breadcrumbs name={service?.name || ''}/>

          <div className='flex gap-16 lg:flex-nowrap flex-wrap justify-between'>
            <div className='lg:p-4 p-3 my-class md:w-1/2 w-full h-full'>
              <div className='flex group bg-dark justify-between flex-col gap-1.5 text-dark cursor-pointer w-full h-full'>
                {
                  loading ?
                    <div className="flex opacity-95 items-center justify-center h-96 w-full mb-4 bg-gray-600 rounded">
                      <svg className="w-10 h-10 text-gray-700" aria-hidden="true"
                           xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 20">
                        <path
                          d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z"/>
                        <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z"/>
                      </svg>
                    </div>: <img loading='lazy' className='rounded-md w-full h-96 object-cover' src={service?.image} alt="/"/>}
              </div>
            </div>

            <div className='lg:w-1/2 w-full mt-8 lg:m-0 m-4'>
              <div>
                <div className='flex justify-between'>
                  <div className='border-b-[#626262] text-base font-normal text-white hover:text-white'>
                    Description
                  </div>
                  <div className='flex gap-5'>
                    <img onClick={() => window.print()} className='h-5 w-5 cursor-pointer' src={print_icon} alt="/"/>
                    <ShareIcon onClick={handleCopyModal} className="h-5 w-5 text-[#B4B4B4] cursor-pointer" />
                  </div>
                </div>

                <hr className='mt-5 mb-2.5 bg-[#626262] text-[#626262]'/>
                <div className='text-white text-base pb-0'>
                  {service?.description}
                </div>
              </div>

              <a href="https://app.fullbay.com/GLJHIU" target='_blank' rel="noreferrer"><button onClick={handleRequestModal} className='bg-[#A80301] py-2 mb-8 mt-6 px-5 lg:w-fit w-full text-center text-white rounded-lg'>
              Send a request
              </button></a>
            </div>
          </div>
        </div>
      </div>
      <Map/>
      <Footer/>

      {
        locationModal && <MapLocationModal open={locationModal} handleOpen={handleOpenLocationModal}/>
      }

      {
        openCopyModal && <PageLinkCopyModal handleOpen={handleCopyModal} open={openCopyModal}/>
      }

    </div>
  );
};

export default ServiceDetails;