import React, { Fragment, useState } from "react";
import { Dialog } from "@material-tailwind/react";
import Button from "../components/Button";
import SuccessModal from "./SuccessModal";
import {XMarkIcon} from "@heroicons/react/20/solid";
import ErrorModal from "./ErrorModal";
import Input from "../components/Input";
import {useFormik} from "formik";
import {partRequestSchema} from "../utils/schema";
import {partRequestAction} from "../redux/parts/partsReducer";

export default function PartRequestModal({handleOpen, open, part}) {
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const handleSuccessModal = () => setSuccessModal(!successModal)
  const handleErrorModal = () => setErrorModal(!errorModal)

  const fields = [
    {id: 1, title: 'Name', name: 'name'},
    {id: 2, title: 'Phone number', name: 'phone_number'},
    {id: 3, title: 'Message', name: 'message', count: true},
  ]

  const formik = useFormik({
    initialValues: {
      name: "",
      phone_number: "",
      message: "",
      part: part.id,
    },
    validationSchema: partRequestSchema,
    onSubmit: (data) => {
      console.log(data)
      partRequestAction({data, handleSuccessModal, handleErrorModal})
    },
  });

    return (
      <>
        <Fragment>
          <Button onClick={handleOpen} variant="gradient">
            Open Dialog
          </Button>
          <Dialog className='p-4' open={open} handler={handleOpen}>
            <button onClick={handleOpen}
                    type="button"
                    className="absolute w-7 top-4 right-4 text-base text-[#C5C5C5] bg-transparent rounded-lg ml-auto inline-flex items-center"
                    data-modal-toggle="defaultModal"
            >
              <XMarkIcon className="h-6 w-6 text-[#C5C5C5]" />
              <span className="sr-only">Close modal</span>
            </button>
            <div>
              <form onSubmit={formik.handleSubmit}>
                <p className='pb-4 pl-1 text-lg text-dark'>Send a request</p>
                <div className='flex items-center gap-3.5 mb-4 ml-1'>
                  <img className='h-28 w-32 object-contain rounded' src={part.main_image} alt="/"/>
                  <div className='text-dark'>
                    <p className='text-sm lg:text-base mt-0.5'>{part.title}</p>
                    <p className='text-sm lg:text-base font-semibold'>$ {part.price}</p>
                  </div>
                </div>
                <div className='flex flex-col gap-2.5 relative'>
                  {
                    fields.map(field => (
                      <Input key={field.id} field={field} formik={formik}/>
                    ))
                  }
                </div>

                <div className='float-right flex mt-8'>
                  <Button type='submit' text='Send' visibility={true}/>
                </div>
              </form>
            </div>
          </Dialog>
        </Fragment>
        {
          successModal && <SuccessModal handleOpen={handleSuccessModal} open={successModal}/>
        }

        {
          errorModal && <ErrorModal handleOpen={handleErrorModal} open={errorModal}/>
        }
      </>

    );
}