import {request} from "./api";
import {createAsyncThunk} from "@reduxjs/toolkit";

export const authUser = createAsyncThunk(
  "users/authUser",
  async function({values, navigate, handleErrorModal},{ rejectWithValue, dispatch}){
    try {
      const response = await request.authApi(values)
      localStorage.setItem('access', response.data.access)
      localStorage.setItem('refresh_token', response.data.refresh)
      navigate('/admin/services')
    } catch (error) {
      handleErrorModal()
      return rejectWithValue(error.response.data.detail)
    }
  })

export const refreshTokenAction = async (accessToken) => {
  try {
    const response = await request.registerApi(accessToken)
    console.log(response)
  } catch (error){
    console.log('Error: ', error.message)
  }
}
