import {createAsyncThunk} from "@reduxjs/toolkit";
import {request} from "../api";

export const getServicesAction = createAsyncThunk(
  'services/getServices',
  async (userId) => {
    const response = await request.getServices()
    return response.data
  }
)

export const getServiceByIdAction = createAsyncThunk(
  'services/getServiceById',
  async ({id}) => {
    if(id) {
      const response = await request.getServiceById(id)
      window.scrollTo(0, 0)
      return response.data
    }
  }
)

export const serviceRequestAction = async ({fData, handleSuccessModal, handleErrorModal}) => {
  try {
    const response = await request.serviceRequestApi(fData)
    console.log(response)
    handleSuccessModal()
    setTimeout(() => handleSuccessModal(), 3000)
  } catch (error){
    handleErrorModal()
    console.log('Error: ', error.message)
  }
}

export const getServiceByTruckTypeAction = createAsyncThunk(
  'services/getServiceByTypeAction',
  async () => {
    const response = await request.getServiceByType('Truck Repair')
    const response2 = await request.getServiceByType('Trailer Repair')
    const response3 = await request.getServiceByType('Mobile & Towing')
    return {truck: response.data, trailer: response2.data, mobile: response3.data}
  }
)