import {request} from "../api";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {getServicesAction} from "../services/ServicesActions";

export const getUsersAction = createAsyncThunk(
  'admin_users/getUsersAction',
  async () => {
    const response = await request.getUserApi()
    return response.data
  }
)

export const getUserByIdAction = createAsyncThunk(
  'admin_users/getUserByIdAction',
  async ({id}, rejectWithValue) => {
    try {
      const response = await request.getUserByIdApi(id)
      // console.log(response)
      return  response.data
    } catch (error) {
      console.log('Error: ', error.message)
      return rejectWithValue(error.message)
    }
  }
)

export const inviteUserAction = createAsyncThunk(
  "admin_users/inviteUserAction",
  async function({data, handleSuccessModal, handleErrorModal},{ rejectWithValue, dispatch}){
    try {
      const response = await request.inviteUserApi(data)
      // console.log(response)
      handleSuccessModal()
      setTimeout(() => handleSuccessModal, 3000)
      dispatch(getUsersAction())
    } catch (error) {
      handleErrorModal()
      console.log('Error: ', error.message)
      return rejectWithValue(error.message)
    }
  })

export const resetUserPasswordAction = createAsyncThunk(
  "admin_users/resetUserPasswordAction",
  async function({data, handleSuccessModal, handleErrorModal, navigate, setOpenErrorAlertModal},{ rejectWithValue}){
    try {
      await request.resetUserPasswordApi(data)
      handleSuccessModal()
      setTimeout(() => navigate('/admin'), 3000)
    } catch (error) {
      // handleErrorModal()
      setOpenErrorAlertModal(true)
      setTimeout(() => setOpenErrorAlertModal(false), 4000)
      console.log('Error: ', error.response.data.new_password[0])
      return rejectWithValue(error.response.data.new_password[0])
    }
  })

export const updateUserAction = createAsyncThunk(
  "admin_users/updateUserAction",
  async function({id, data, handleSuccessModal, handleErrorModal},{ rejectWithValue, dispatch}){
    try {
      const response = await request.updateUserApi({id, data})
      // console.log(response)
      handleSuccessModal()
      setTimeout(() => handleSuccessModal, 3000)
      dispatch(getUsersAction())
    } catch (error) {
      handleErrorModal()
      console.log('Error: ', error.message)
      return rejectWithValue(error.message)
    }
  })

export const deleteUserAction = createAsyncThunk(
  "admin_users/deleteUserAction",
  async function({id, handleCloseDeleteModal},{ rejectWithValue, dispatch}){
    try {
      const response = await request.deleteUserApi(id)
      // console.log(response)
      handleCloseDeleteModal()
      dispatch(getUsersAction())
    } catch (error) {
      console.log('Error: ', error.message)
      return rejectWithValue(error.message)
    }
  })

export const blockUserAction = createAsyncThunk(
  "admin_users/blockUserAction",
  async function({id, handleCloseLockModal},{ rejectWithValue, dispatch}){
    try {
      const response = await request.blockUserApi(id)
      // console.log(response)
      handleCloseLockModal()
      dispatch(getUsersAction())
    } catch (error) {
      console.log('Error: ', error.message)
      return rejectWithValue(error.message)
    }
  })