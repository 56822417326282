import React from 'react';

const Button = ({visibility, onClick, text, type}) => {
  return (
    <button type={type} onClick={onClick} className={visibility ? 'lg:visible bg-[#A80301] py-2 px-5 text-white rounded-lg flex justify-center mx-auto' : 'lg:hidden bg-[#A80301] py-2 w-72 text-white rounded-lg flex justify-center mx-auto'}>
      {text}
    </button>
  );
};

export default Button;
