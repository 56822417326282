import React, {useEffect} from 'react';
import Navbar from "../../components/Sidebar";
import UpdatePartForm from "./UpdatePartForm";
import Breadcrumbs from "../../components/Breadcrumbs";
import {
  getPartByIdAction,
} from "../../redux/parts/PartsActions";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";

const UpdatePart = () => {

  const {partById} = useSelector(state => state.admin_parts)
  const {id} = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPartByIdAction({id}))
  }, [])

  return (
    <div className='h-screen md:flex'>
      <Navbar/>
      <div className='w-full h-screen bg-background'>
        <div className='container m-auto pt-7 px-6'>
          <Breadcrumbs name={partById?.title}/>
          <div className='flex justify-between w-full my-4'>
            <p className='text-xl font-medium'>
              Update part
            </p>
          </div>
          <UpdatePartForm />
        </div>
      </div>
    </div>
  );
};

export default UpdatePart;