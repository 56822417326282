import React from 'react';
import tireHalf from "../../assets/halfTireBg.svg";
import tire_bg from "../../assets/bg-tire.svg";
import MySlider from "./Slider";
import CompareImageSlider from "./CompareImageSlider";

const OurAdvantages = () => {

  const advantages = [
    {id: 1, count: '5', text: 'Years of experience'},
    {id: 2, count: '1000+', text: 'Happy clients'},
    {id: 3, count: '2000+', text: 'Vehicle repairs'},
    {id: 4, count: '50+', text: 'Professionals'},
  ]

  return (
    <section className='bg-contain bg-no-repeat lg:bg-cover relative'>
      <div className='relative bg-dark w-full flex flex-start'>
        <img className='absolute bg-dark top-0 left-0 h-50% w-full' src={tireHalf} alt="/"/>
      </div>
      <div className='relative container m-auto text-center pt-24 md:pt-32 xl:pt-52 pb-10 sm:w-2/3 lg:w-1/2 w-full'>
        <h5 className='lg:text-[26px] text-[22px] mb-10'>Our advantages</h5>
        <p className='xl:text-lg md:text-md text-sm text-dark font-medium leading-[140%] mb-4 mx-4 lg:mb-6'>Whether you're a truck driver, a fleet manager, or an individual in need of our services, Pride Truck Center is your trusted partner.</p>
        <hr className='text-red-500 bg-red-400 m-auto w-5/6'/>
        <p className='sm:w-[77%] sm:m-auto mt-2 text-[#858585] xl:text-base text-sm mx-4 leading-[130%]'>We prioritize our customer's satisfaction and safety. Contact us today to learn more about our services and how we can assist you on the road during our business hours.</p>
      </div>
      <div className='container m-auto flex flex-wrap justify-center lg:gap-20 gap-11'>
        {
          advantages.map(advantage => (
            <div key={advantage.id} className='relative'>
              <img className='xl:w-full w-32 animate-spin-slow' src={tire_bg} alt="/"/>
              <div className='absolute w-full h-full flex flex-col justify-center top-0 right-0 text-white font-medium text-center'>
                <h4 className='xl:text-4xl lg:text-3xl sm:text-2xl text-xl'>{advantage.count}</h4>
                <p className='px-5 md:px-6 text-[10px] xl:text-base sm:text-[12px]'>{advantage.text}</p>
              </div>
            </div>
          ))
        }

      </div>

      {/*    Slider   */}
      <div className='relative container m-auto text-center lg:pt-36 pt-28'>
        <h5 className='text-dark lg:text-[26px] text-[22px] mb-10'>What clients say</h5>
      </div>
      <div className='relative container m-auto'>
        <CompareImageSlider />
        <div id="testimonials" className='absolute left-1/10 h-[500px] lg:top-[60%] md:top-24 top-10 w-full m-auto text-center pt-36'>
          <MySlider/>
        </div>
      </div>

    </section>
  );
};

export default OurAdvantages;